import React, { useEffect, useState } from 'react';
// apis
import useOrderAPI, { GetOrderRes } from 'api/store/useOrderAPI';
// components
import OrderInfo from './OrderInfo';
import OrderBookInfo from './OrderBookInfo';
import OrderHistory from './OrderHistory';
import OrderBookHistory from './OrderBookHistory';
import DeliveryHistory from './DeliveryHistory';
import ReturnHistory from './ReturnHistory';
import AlimtalkHistory from './AlimtalkHistory';
// types
import { Order } from 'api/types';

interface Props {
  selectedOrderStatusCode: any;
  deleteIncludeYn: number;
  selectedOrder: Order | null;
}
export default function OrderDetail({
  selectedOrderStatusCode,
  deleteIncludeYn,
  selectedOrder,
}: Props) {
  const [order, setOrder] = useState<GetOrderRes | null>(null);

  const { getOrderAPI } = useOrderAPI();

  async function handleGetOrder() {
    try {
      const newOrderDetail = await getOrderAPI({
        orderId: selectedOrder?.orderId || 0,
      });
      setOrder(newOrderDetail);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (selectedOrder) {
      handleGetOrder();
    }
  }, [selectedOrder]);

  useEffect(() => {
    setOrder(null);
  }, [deleteIncludeYn]);

  useEffect(() => {
    if (selectedOrderStatusCode) {
      setOrder(null);
    }
  }, [selectedOrderStatusCode]);

  if (!selectedOrder) return null;
  if (!order) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <OrderInfo orderInfo={order?.orderInfo} />
      <OrderBookInfo orderBookInfo={order?.orderBookInfo || []} />
      <OrderHistory orderHistory={order?.orderHistory || []} />
      <OrderBookHistory orderBookHistory={order?.orderBookHistory || []} />
      <DeliveryHistory deliveryHistory={order?.deliveryHistory || []} />
      <ReturnHistory returnHistory={order?.returnHistory || []} />
      <AlimtalkHistory alimtalkHistory={order?.alimtalkHistory || []} />
    </div>
  );
}
