import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_DARK } from '../../../../styles/colors';
import { useHistory } from 'react-router';

interface Props {
  authorId: number;
  bookshelfId: number;
  influencerId: number;
  influencerImage?: string;
  influencerName: string;
  introduce: string;
  job: string;
  userId: number;
}

const BlibraryInfluencerInfo: FunctionComponent<Props> = ({
  influencerId,
  influencerImage,
  influencerName,
  job,
}) => {
  const history = useHistory();

  const influencerClickHandle = () => {
    history.push({ pathname: `/blibrary/influencer/${influencerId}` });
  };

  return (
    <BannerWrapper onClick={() => influencerClickHandle()}>
      <BannerImage src={influencerImage} />
      <InfluencerIdWrapper>{influencerId}</InfluencerIdWrapper>
      <InfoContainer>
        <Title>{influencerName}</Title>
        <Job color={GREY_DARK}>{job}</Job>
      </InfoContainer>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const BannerImage = styled.img`
  width: 200px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
`;

const InfluencerIdWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 10px;
  z-index: 1;
  top: -10px;
  left: -10px;
  background-color: #2f3c4e;
  color: white;
  font-weight: 700;
  font-family: 'jua';
`;

const InfoContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
`;

const Title = styled.li`
  font-size: 20px;
  font-weight: 700;
`;

interface JopProps {
  color: string;
}

const Job = styled.li<JopProps>`
  font-size: 17px;
  font-weight: 700;
  margin-left: 15px;
  color: ${(props) => props.color};
`;

export default BlibraryInfluencerInfo;
