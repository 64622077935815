import React, { Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { VERSION } from 'api/useAxios';
// redux
import useAuthRedux from 'reducers/auth/useAuthRedux';

const UserPanel = () => {
  const {
    state: { user },
  } = useAuthRedux();

  function getGrade() {
    if (user === 'maintainer') {
      return '관리 등급';
    }
    return '일반 등급';
  }

  return (
    <Fragment>
      <Container
        className="sidebar-user text-center"
        style={{ boxShadow: 'none' }}
      >
        <h4 className="mt-3 f-14">비블리 관리자 앱</h4>
        <h5>{getGrade()}</h5>
        <p>ver {VERSION}</p>
        <p>{moment().format('YYYY-MM-DD HH:mm:ss')}</p>
      </Container>
    </Fragment>
  );
};

export default UserPanel;

const Container = styled.div`
  h4 {
    color: white;
    font-size: 15px !important;
  }
  h5 {
    color: white;
    font-size: 14px !important;
  }
  p {
    font-size: 12px !important;
  }
`;
