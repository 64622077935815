import React, { Fragment, useEffect, useState } from 'react';
// api
import {
  getBookPreparationList,
  getBookStatusList,
} from '../../../api/store/preparation';
// modals
import FailedDeliveryModal from '../../common/modals/FailedDeliveryModal';
// common
import Breadcrumb from '../../common/breadcrumb';
// components
import PreparationBookList from './PreparationBookList';
import WholesaleOrderList from './WholesaleOrderList';
import AladinOrderList from './AladinOrderList';
import StockUpList from './StockUpList';
// types
import { Book, BookStatus } from '../../../api/store/preparation';
import { StockBook } from 'api/types';

export default function Preparation() {
  const [bookList, setBookList] = useState<Book[]>([]);
  const [stockBookList, setStockBookList] = useState<StockBook[]>([]);
  const [bookStatusList, setBookStatusList] = useState<BookStatus[]>([]);
  const [failedDelivery, setFailedDelivery] = useState({
    isShow: false,
    errorList: [],
  });

  useEffect(() => {
    _getBookStatusList();
    _getBookPreparationList();
  }, []);

  // 책 상태 리스트 호출
  const _getBookStatusList = async () => {
    try {
      const result = await getBookStatusList();
      setBookStatusList(result);
    } catch (error) {
      console.error('_getBookStatusList :: ', error);
    }
  };

  // 책 리스트 호출
  const _getBookPreparationList = async () => {
    try {
      const res = await getBookPreparationList();
      if (res?.list && res.list.length > 0) {
        setBookList(res.list);
      }
      if (res?.stockBookInfo && res.stockBookInfo.length > 0) {
        setStockBookList(res.stockBookInfo);
      }
    } catch (error) {
      console.error('_getBookStatusList :: ', error);
    }
  };

  return (
    <Fragment>
      <FailedDeliveryModal
        isVisible={failedDelivery.isShow}
        failedDelivery={failedDelivery}
        getBookPreparationList={_getBookPreparationList}
        close={() =>
          setFailedDelivery({
            isShow: false,
            errorList: [],
          })
        }
      />
      <Breadcrumb title="책 준비" parent="찾아가는 서점" />
      <div className="container-fluid">
        <PreparationBookList
          bookList={bookList}
          bookStatusList={bookStatusList}
          onGetBookPreparationList={_getBookPreparationList}
          setFailedDelivery={setFailedDelivery}
        />
        <WholesaleOrderList bookList={bookList} />
        <AladinOrderList
          bookList={bookList}
          getBookPreparationList={_getBookPreparationList}
        />
        <StockUpList stockBookList={stockBookList} />
      </div>
    </Fragment>
  );
}
