import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
// apis
import useTrafficAPI, {
  DayTraffic,
  DayTrafficDetail,
} from 'api/dashboard/useTrafficAPI';
// components
import Breadcrumb from 'components/common/breadcrumb';
import Chart from './Chart';
import TrafficList from './TrafficList';
import TrafficDetailList from './TrafficDetailList';

export default function TrafficBoard() {
  const initDate = new Date(moment().format('YYYY-MM-DD'));
  const [date, setDate]: any = useState(initDate);
  const [pageCode, setPageCode] = useState('[Total]');
  const [detailTitle, setDetailTitle] = useState('[Total] - 전체');
  const [dayTraffics, setDayTraffics] = useState<DayTraffic[]>([]);
  const [dayTrafficDetails, setDayTrafficDetails] = useState<
    DayTrafficDetail[]
  >([]);

  const { getDayTrafficAPI, getDayTrafficDetailAPI } = useTrafficAPI();

  async function handleGetDayTraffic(date) {
    try {
      const res = await getDayTrafficAPI({
        dashboardDate: moment(date).format('YYYY-MM-DD'),
      });
      // console.log(res);
      const newDayTraffics: any = [];
      for (let i = 0; i < res.length; i++) {
        const r = res[i];
        newDayTraffics.push({
          page: `${r.pageCode} - ${r.pageName}`,
          ...r,
        });
      }
      setDayTraffics(newDayTraffics);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGetDayTrafficDetail(item) {
    try {
      const newDayTrafficDetails = await getDayTrafficDetailAPI({
        dashboardDate: moment(date).format('YYYY-MM-DD'),
        item: item.pageCode,
      });

      setPageCode(item.pageCode);
      setDetailTitle(item.page);
      setDayTrafficDetails(newDayTrafficDetails);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetDayTraffic(date);
    handleGetDayTrafficDetail({ pageCode: '[Total]', page: '[Total] - 전체' });
  }, [date]);

  return (
    <Fragment>
      <Breadcrumb title="트래픽 현황" parent="대시보드" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 20,
          marginBottom: 24,
        }}
      >
        <div
          style={{
            fontSize: 36,
            fontWeight: 700,
            marginRight: 16,
          }}
        >
          {moment(date).format('YYYY.M.D')} 트래픽 통계
        </div>
        <KeyboardDatePicker
          value={date}
          onChange={setDate}
          format="YYYY-MM-DD"
          invalidDateMessage="날짜 형식을 맞춰주세요.(예: 2020-11-09)"
          maxDate={initDate}
        />
      </div>
      <div style={{ flex: 1, paddingRight: 20 }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Chart dayTrafficDetails={dayTrafficDetails} />
          <div style={{ display: 'flex', paddingLeft: 40 }}>
            <div style={{ width: '100%' }}>
              <TrafficList
                pageCode={pageCode}
                dayTraffics={dayTraffics}
                handleGetDayTrafficDetail={handleGetDayTrafficDetail}
              />
            </div>
            <div style={{ width: '100%', marginLeft: 20 }}>
              <h4 style={{ padding: 5 }}>{detailTitle}</h4>
              <TrafficDetailList dayTrafficDetails={dayTrafficDetails} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
