import styled, { css } from 'styled-components';
import React, { FunctionComponent, useState } from 'react';
import ModalComponent from 'components/common/ModalComponent';
import { GREY_LIGHT } from 'styles/colors';
import { IoSearch } from 'react-icons/io5';
import useBookAPI from 'api/operation/useBookAPI';
import { Scrollbars } from 'react-custom-scrollbars';

interface SearchBookProps {
  type: string;
  id: string;
  bookName: string;
  bookNameOnChange: (text: string) => void;
  searchBookHandle: (e: any) => void;
  searchBookList: any;
  selectBookHandle: (book: any) => void;
  searchType?: string;
}

const SearchBookPresenter: FunctionComponent<SearchBookProps> = ({
  type,
  id,
  bookName,
  bookNameOnChange,
  searchBookHandle,
  searchBookList,
  selectBookHandle,
  searchType = null,
}) => {
  return (
    <>
      <InputWrapper>
        <div style={{ position: 'relative', marginTop: 30 }}>
          <SearchInput
            id={id}
            value={bookName}
            onChange={bookNameOnChange}
            placeholder={
              searchType === 'isbn'
                ? '등록하실 isbn 넘버를 입력해주세요'
                : '등록하실 저자의 도서를 검색해주세요.'
            }
            onKeyDown={searchBookHandle}
          />

          <SearchIconWrapper>
            <IoSearch size={26} color={GREY_LIGHT} />
          </SearchIconWrapper>
        </div>
      </InputWrapper>
      <FragementCenterContainer>
        <ScrollerWrapper>
          <Scrollbars
            style={{
              display: 'flex',
              width: 950,
              height: 490,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  backgroundColor: 'black',
                  width: 5,
                  borderRadius: 7,
                }}
              />
            )}
          >
            <BookContainer>
              {searchBookList.map((book) => (
                <BookWrapper
                  key={book.bookId}
                  onClick={() => {
                    selectBookHandle(book);
                  }}
                >
                  <BookImage
                    src={
                      type === '아이디'
                        ? `https://bibly-image-server-korea.s3.ap-northeast-2.amazonaws.com${book.bookImage}`
                        : book.bookImage
                    }
                  />
                  <BookName>{book.bookName}</BookName>
                  <BookAuthorName>{book.authorName}</BookAuthorName>
                </BookWrapper>
              ))}
            </BookContainer>
          </Scrollbars>
        </ScrollerWrapper>
      </FragementCenterContainer>
    </>
  );
};

interface Props {
  visible: boolean;
  type?: string;
  bookClickHandle: (book: any) => void;
  id: string;
}

const ModalForm: FunctionComponent<Props> = ({
  visible,
  bookClickHandle,
  type = null,
  id,
}) => {
  const [inputType, setInputType] = useState('아이디');
  const [idBookName, setIdBookName] = useState('');
  const [idSearchBookList, setIdSearchBookList] = useState<any>([]);

  const [titleBookName, setTitleBookName] = useState('');
  const [titleSearchBookList, setTitleSearchBookList] = useState<any>([]);

  const { getSearchBookAPI, getSearchBookIdAPI } = useBookAPI();

  //아이디로 찾기
  const idBookNameOnChange = (text) => {
    const textValue = text.target.value;
    setIdBookName(textValue);
  };

  const idSearchBookHandle = async (e) => {
    if (e.keyCode === 13) {
      if (idBookName) {
        const result = await getSearchBookIdAPI(
          idBookName,
          type === 'isbn' ? 'isbn' : null,
        );
        setIdSearchBookList(result);
      } else {
        alert('isbn 넘버를 입력해주세요');
      }
    }
  };

  const idSelectBookHandle = (book) => {
    setIdBookName('');
    setIdSearchBookList([]);
    bookClickHandle(book);
  };

  //타이틀로 찾기

  const titleBookNameOnChange = (text) => {
    const textValue = text.target.value;
    setTitleBookName(textValue);
  };

  const titleSearchBookHandle = async (e) => {
    if (e.keyCode === 13) {
      const result = await getSearchBookAPI(titleBookName);
      setTitleSearchBookList(result.bookList);
    }
  };

  const titleSelectBookHandle = (book) => {
    setTitleBookName('');
    setTitleSearchBookList([]);
    bookClickHandle(book);
  };

  return (
    <FormWrapper>
      <ModalComponent
        width={1000}
        height={640}
        visible={visible}
        backgroundColor={'white'}
      >
        {type === 'isbn' ? null : (
          <SearchTypeContainer>
            <SearchTypeWrapper
              onClick={() => setInputType('아이디')}
              inputType={inputType === '아이디' ? true : false}
            >
              아이디로 찾기
            </SearchTypeWrapper>
            <div />
            <SearchTypeWrapper
              onClick={() => setInputType('제목')}
              inputType={inputType === '제목' ? true : false}
            >
              제목으로 찾기
            </SearchTypeWrapper>
          </SearchTypeContainer>
        )}
        {inputType === '아이디' && type !== 'isbn' ? (
          <SearchBookPresenter
            type={inputType}
            id={id}
            bookName={idBookName}
            bookNameOnChange={idBookNameOnChange}
            searchBookHandle={idSearchBookHandle}
            searchBookList={idSearchBookList}
            selectBookHandle={idSelectBookHandle}
          />
        ) : type === 'isbn' ? (
          <SearchBookPresenter
            type={inputType}
            searchType={type}
            id={id}
            bookName={idBookName}
            bookNameOnChange={idBookNameOnChange}
            searchBookHandle={idSearchBookHandle}
            searchBookList={idSearchBookList}
            selectBookHandle={idSelectBookHandle}
          />
        ) : (
          <SearchBookPresenter
            type={inputType}
            id={id}
            bookName={titleBookName}
            bookNameOnChange={titleBookNameOnChange}
            searchBookHandle={titleSearchBookHandle}
            searchBookList={titleSearchBookList}
            selectBookHandle={titleSelectBookHandle}
          />
        )}
      </ModalComponent>
    </FormWrapper>
  );
};

const SearchTypeContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
  div {
    margin-right: 30px;
  }
`;

interface SearchTypeWrapperProps {
  inputType: boolean;
}

const SearchTypeWrapper = styled.li<SearchTypeWrapperProps>`
  display: flex;
  width: 120px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  cursor: pointer;
  ${(props) =>
    props.inputType &&
    css`
      background-color: black;
      color: white;
    `}
`;

const FragementCenterContainer = styled.div`
  display: flex;
  width: 1000px;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

const ScrollerWrapper = styled.div`
  width: 950px;
  margin-top: 20px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const InputWrapper = styled.div`
  display: flex;
  width: 1000px;
  justify-content: center;
`;

const SearchInput = styled.input`
  display: flex;
  width: 30vw;
  height: 40px;
  padding: 0 15px 0 15px;
  border: solid 1px ${GREY_LIGHT};
  border-radius: 5px;
  :focus {
    border: solid 2px black;
  }
`;

const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  height: 40px;
  top: 0;
`;

const BookContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 20px 0 10px;
`;

const BookWrapper = styled.li`
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
`;

const BookImage = styled.img`
  width: 100px;
`;

const BookName = styled.div`
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
`;

const BookAuthorName = styled.div`
  width: 100px;
  padding: 7px 0 10px 0;
  text-align: center;
`;

export default ModalForm;
