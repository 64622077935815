// apis
import useAPI from 'api/useAPI';

export interface DayConversion {
  fromBehavior: string;
  fromBehaviorName: string;
  fromPv: number;
  fromUv: number;
  toBehavior: string;
  toBehaviorName: string;
  toPv: number;
  toUv: number;
  conversionPv: number;
  conversionUv: number;
}

export interface DayConversionDetail {
  dashboardDate: string; // YYYY-MM-DD
  fromUv: number;
  fromPv: number;
  toUv: number;
  toPv: number;
  conversionUv: number;
  conversionPv: number;
}

export interface DayConversionAnalysisItem {
  listName: string;
  listType: string;
  pv: number;
  unique_user_item: number;
  uv: number;
}
export interface DayConversionAnalysis {
  dataList: DayConversionAnalysisItem[];
  pvList: DayConversionAnalysisItem[];
  uvList: DayConversionAnalysisItem[];
}
export interface DayConversionAnalysisRes {
  [key: string]: DayConversionAnalysis; // YYYY-MM-DD
}

export default function useConversionAPI() {
  const { getHandler } = useAPI();

  async function getDayConversionAPI(params: {
    dashboardDate: string; // YYYY-MM-DD
  }): Promise<DayConversion[]> {
    try {
      const res: any = await getHandler(
        '/admin/dashboard/conversion/day',
        params,
      );
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getDayConversionDetailAPI(params: {
    dashboardDate: string; // YYYY-MM-DD
    fromBehavior: string;
    toBehavior: string;
  }): Promise<DayConversionDetail[]> {
    try {
      const res: any = await getHandler(
        '/admin/dashboard/conversion/item',
        params,
      );
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getDayConversionAnalysisAPI(params: {
    dashboardDate: string; // YYYY-MM-DD
  }): Promise<DayConversionAnalysisRes> {
    try {
      const res: any = await getHandler(
        '/admin/dashboard/conversion-detail',
        params,
      );
      return res.data?.result.conversionDetail;
    } catch (error) {
      throw error;
    }
  }

  return {
    getDayConversionAPI,
    getDayConversionDetailAPI,
    getDayConversionAnalysisAPI,
  };
}
