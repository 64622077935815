import styled from 'styled-components';
import React from 'react';

const PushPresenter = () => {
  return <Container>adsf</Container>;
};

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 20px;
`;

export default PushPresenter;
