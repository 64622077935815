// apis
import useAPI from '../useAPI';
// types
import { OrderStatusCode, Order } from '../types';

export interface OrderInfo {
  orderId: number; // 주문ID
  ordererName: string; // 주문자명
  orderStatus: string; // 주문 상태
  orderDate: string; // 주문 일시
  orderUpdateDate: string; // 주문 최근 변경 일시
  userPhone: string; // 주문자 연락처
  mileage: number; // 주문자 마일리지
  addressName: string; // 배송지 이름
  receiverName: string; // 배송지 수취인
  receiverPhone: string; // 배송지 연락처
  address: string; // 주소
  deliveryRequest: string; // 배송 요청 사항
  addressDefaultYn: number; // 기본 배송지 여부
  cardInfo: string; // 결제 카드 정보
  cardDefaultYn: number; // 기본 결제 카드 여부
}
export interface OrderBookInfo {
  orderBookId: number; // 주문 책 정보
  bookId: number; // 책ID
  bookCondition: number; // 책 상태 - 0:새책, 1:중고S, 2:중고A+, 3:중고A
  bookPreparationStatus: string; // 책 준비 상태
  orderCancelNotification: number; // 취소 알림톡 발송 수
  deliveryId: number | null; // 배송ID
  returnYn: number | null; // 리턴ID
  stockInYn: number; // 입고 여부
  deleteYn: number; // 주문 취소 여부
  bookName: string; // 책 제목
  publisherName: string; // 출판사
  isbn13: string; // ISBN
}
export interface OrderHistory {
  historyId: string; // 히스토리ID
  orderId: number; // 주문ID
  userId: number; // 유저ID
  addressId: number; // 배송지ID
  cardId: number; // 결제 카드ID
  ordererName: string; // 주문자명
  orderStatus: number; // 주문 상태 코드
  statusName: string; // 주문 상태명
  orderToken: string; // 주문 토큰
  insertDate: string; // 주문 일시
  updateDate: string; // 주문 상태 최근 변경 일시
  deleteYn: number; // 주문 취소 여부
  deleteReason: string | null; // 주문 취소 사유
}
export interface OrderBookHistory {
  historyId: string; // 히스토리ID
  orderBookId: number; // 주문책ID
  orderId: number; // 주문ID
  userId: number; // 유저ID
  bookId: number; // 책ID
  bookName: string; // 책제목
  bookCondition: number; // 책상태
  ordinalNo: number; // 주문 책 순서
  bookPreparationStatus: number; // 주문 책 상태 코드
  statusName: string; // 주문 책 상태명
  orderCancelNotification: number; // 취소 알림톡 발송수
  deliveryId: null; // 배송ID
  returnYn: null; // 리턴ID
  stockInYn: number; // 입고 여부
  insertDate: string; // 등록 일시
  updateDate: string; // 최근 변경 일시
  deleteYn: number; // 주문 취소 여부
}
export interface DeliveryHistory {
  historyId: string; // 히스토리ID
  deliveryId: number; // 배송ID
  orderId: number; // 주문ID
  userId: number; // 유저ID
  deliveryStatus: number; // 배송 상태 코드
  statusName: string; // 배송 상태명
  invoiceNumber: number | null; // 송장 번호
  invoiceStatus: number | null; // 배송 상태(택배사)
  deliveryStartNoticeYn: number; // 배송 출발 알림톡 발송 여부
  deliveryEndNoticeYn: number; // 배송 도착 알림톡 발송 여부
  insertDate: string; // 등록 일시
  updateDate: string; // 최근 수정 일시
  returnTalkCnt: number; // 구매 결정 알림톡 발송 수
}
export interface ReturnHistory {
  historyId: string; // 히스토리ID
  returnId: number; // 리턴ID
  orderId: number; // 주문ID
  userId: number; // 유저ID
  pickupDate: string | null; // 픽업 일시
  returnStatus: number; // 리턴 상태 코드
  statusName: string; // 리턴 상태명
  insertDate: string; // 등록 일시
  updateDate: string; // 최근 수정 일시
  deleteYn: number; // 삭제 여부
}

export interface AlimtalkHistory {
  historyId: string; // 히스토리ID
  orderId: number; // 주문ID
  userId: number; // 유저ID
  targetPhone: string;
  messageId: string;
  sendingStatus: number;
  messageContent: string;
  templateId: string;
  reservationDate: string;
  reservedYn: number;
}
export interface GetOrdersRes {
  orderList: Order[];
  totalCount: number;
}
export interface GetOrderRes {
  orderInfo: OrderInfo; // 1. 주문 상세 정보
  orderBookInfo: OrderBookInfo[]; // 2. 주문 책 정보
  orderHistory: OrderHistory[]; // 3. 주문 히스토리
  orderBookHistory: OrderBookHistory[]; // 4. 책 준비 히스토리
  deliveryHistory: DeliveryHistory[]; // 5. 배송 히스토리
  returnHistory: ReturnHistory[]; // 6. 리턴 히스토리
  alimtalkHistory: AlimtalkHistory[];
}

export default function useOrderAPI() {
  const { getHandler } = useAPI();

  async function getOrderStatusCodeListAPI(params: {
    deleteIncludeYn: number; // 삭제 포함 여부 - 1:포함, 0:미포함
  }): Promise<OrderStatusCode[]> {
    try {
      const res: any = await getHandler(
        '/admin/store/order-list/status-code',
        params,
      );
      return res?.data?.result?.list;
    } catch (error) {
      throw error;
    }
  }

  async function getOrdersAPI(params: {
    deleteIncludeYn: number;
    orderStatusCode: number;
    page: number;
    search: string;
  }): Promise<GetOrdersRes> {
    try {
      const res: any = await getHandler('/admin/store/order-lists', params);
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getOrderAPI(params: {
    orderId: number;
  }): Promise<GetOrderRes> {
    try {
      const res: any = await getHandler(
        `/admin/store/order/${params.orderId}`,
        {},
      );
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getExportOrderListAPI(params: {
    deleteIncludeYn: number;
    orderStatusCode: number | null;
    search: string;
  }): Promise<GetOrdersRes> {
    try {
      const res: any = await getHandler(
        '/admin/store/export-order-lists',
        params,
      );
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  return {
    getOrderStatusCodeListAPI,
    getOrdersAPI,
    getOrderAPI,
    getExportOrderListAPI,
  };
}
