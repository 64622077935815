import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import ButtonComponent from 'components/common/ButtonComponent';
import ModalComponent from 'components/common/ModalComponent';
import TextForm from 'components/Operation/aComponents/TextInputForm';
import SelectMenuForm from 'components/Operation/aComponents/SelectMenuForm';
import ModalSearchBookForm from 'components/Operation/aComponents/ModalSearchBookForm';
import BookSearchBtnInputForm from 'components/Operation/aComponents/BookSearchBtnInputForm';
import { IoCloseSharp } from 'react-icons/io5';
import useStockAPI from 'api/store/useStockAPI';

interface Props {
  locationList: any;
  getBookListHandle: () => void;
}

const CreateStock: FunctionComponent<Props> = ({
  locationList,
  getBookListHandle,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [location, setLocation] = useState<any>('');
  const [condition, setCondition] = useState(1);
  const [bookId, setBookId] = useState(null);
  const [ibsnNum, setIbsnNum] = useState(null);
  const [searchBookModalVisible, setSearchBookModalVisible] = useState(false);
  const [stockNum, setStockNum] = useState(0);

  const modalWidth = 1000;
  const modalHeight = 600;

  const { createBookStockAPI } = useStockAPI();

  const conditionOnChange = (text) => {
    const textValue = text.target.value;
    setCondition(parseInt(textValue));
  };

  const stockNumOnChange = (num) => {
    const textValue = num.target.value;
    setStockNum(textValue);
  };

  const selectBookHandle = (book) => {
    setBookId(book.bookId);
    setIbsnNum(book.isbnNum);
    setSearchBookModalVisible(!searchBookModalVisible);
  };

  const closeModalHandle = () => {
    setIsVisible(!isVisible);
    setLocation('');
    setCondition(1);
    setBookId(null);
    setStockNum(0);
  };

  const createStockHandle = async () => {
    const conditionList = [0, 1, 2, 3, 5];
    if (!conditionList.includes(condition)) {
      alert('0, 1, 2, 3, 5 중 선택해주세요');
    } else if (location === '' || !bookId) {
      alert('모든 항목을 채워주세요.');
    } else {
      try {
        const result = await createBookStockAPI({
          bookId: bookId,
          bookCondition: condition,
          locationCode: location.value,
          stockQuantity: stockNum,
        });
        if (!result) {
          alert('이미 재고등록이 완료된 도서입니다.');
        }
      } catch (e) {
        console.log(e);
      } finally {
        await getBookListHandle();
        setIsVisible(!isVisible);
      }
    }
  };

  return (
    <div style={{ marginRight: 30 }}>
      <ButtonComponent
        onClick={() => setIsVisible(!isVisible)}
        title={'재고생성'}
        width={200}
        height={40}
      />
      <ModalComponent
        visible={isVisible}
        width={modalWidth}
        height={modalHeight}
        backgroundColor={'white'}
      >
        <div style={{ paddingTop: 90 }} />
        <SelectMenuForm
          title={'위치'}
          data={locationList}
          selectType={location}
          setSelectType={setLocation}
        />
        <BookSearchBtnInputForm
          onClick={() => setSearchBookModalVisible(!searchBookModalVisible)}
          title={'ISBN'}
          value={ibsnNum}
        />
        <ModalSearchBookForm
          id={'bookSearch'}
          type={'isbn'}
          bookClickHandle={selectBookHandle}
          visible={searchBookModalVisible}
        />
        <TextForm
          title={'상태'}
          onChange={conditionOnChange}
          type={'text'}
          value={condition}
          description={`<div
              style={{
                width: modalWidth,
                marginLeft: 350,
                display: 'flex',
              }}
            >
              <strong>0</strong> - '0-새책??' &nbsp; <strong>1</strong> -
              '1-최상' &nbsp; <strong>2</strong> - '2-상' &nbsp;{' '}
              <strong>3</strong> - '3-중' &nbsp; <strong>5</strong> - '5-파손'
            </div>`}
        ></TextForm>

        <TextForm
          title={'재고량'}
          onChange={stockNumOnChange}
          type={'number'}
          value={stockNum}
        />
        <div
          style={{
            width: modalWidth,
            justifyContent: 'center',
            display: 'flex',
            marginTop: 60,
          }}
        >
          <ButtonComponent
            title={'재고생성'}
            onClick={createStockHandle}
            width={200}
            height={50}
          />
        </div>
        <CloseModal onClick={closeModalHandle}>
          <IoCloseSharp color={'black'} size={30} />
        </CloseModal>
      </ModalComponent>
    </div>
  );
};

const CloseModal = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;

export default CreateStock;
