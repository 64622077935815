import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
// common
import Breadcrumb from '../../common/breadcrumb';
// api
import { getLocationList, getStockList } from '../../../api/store/returnStock';
// components
import ListItem from './ListItem';
// types
import { StockInfo, Location } from '../../../api/store/returnStock';

export default function ReturnStock() {
  const [stockList, setStockList] = useState<StockInfo[]>([]);
  const [locationList, setLocationList] = useState<Location[]>([]);

  useEffect(() => {
    _getLocationList();
    _getStockList();
  }, []);

  // 위치 리스트 호출
  const _getLocationList = async () => {
    try {
      const result = await getLocationList();
      setLocationList(result);
    } catch (error) {
      console.error('_getLocationList :: ', error);
    }
  };

  const _getStockList = async () => {
    try {
      const result = await getStockList();
      setStockList(result);
    } catch (error) {
      console.error('_getStockList :: ', error);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="리턴 입고" parent="찾아가는 서점" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>리턴 입고 리스트</h5>
              </div>
              <div className="table-responsive">
                <CustomTable className="table">
                  <thead>
                    <tr>
                      <th scope="col">주문ID</th>
                      <th scope="col">고객명</th>
                      <th scope="col">선물유저ID</th>
                      <th scope="col">주소</th>
                      <th scope="col">책이름</th>
                      <th scope="col">isbn</th>
                      <th scope="col">재고 위치</th>
                      <th scope="col">도서 상태</th>
                      <th scope="col">입고</th>
                      <th scope="col">재고</th>
                      <th scope="col">경과시간</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockList.length > 0 &&
                      stockList.map((item) => (
                        <ListItem
                          key={item.orderBook_id}
                          stockInfo={item}
                          locationList={locationList}
                          onGetStockList={_getStockList}
                        />
                      ))}
                  </tbody>
                </CustomTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const CustomTable = styled.table`
  th {
    white-space: nowrap;
  }
`;
