import useAPI from '../useAPI';
import { API_URL } from 'api/useAxios';
import axios from 'axios';

export default function useCreatorAPI() {
  const { getHandler } = useAPI();

  const getCreatorAPI = async (creatorId) => {
    try {
      const res: any = await getHandler('/admin/creator', { id: creatorId });
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  const postCreatorAPI = async (formData) => {
    try {
      const res = await axios.post(`${API_URL}/admin/creator`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data?.result;
    } catch (e) {
      console.log(e);
    }
  };

  const putCreatorAPI = async (formData) => {
    try {
      const res = await axios.put(`${API_URL}/admin/creator`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data?.result;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    getCreatorAPI,
    postCreatorAPI,
    putCreatorAPI,
  };
}
