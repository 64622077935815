import React, { useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import styled from 'styled-components';
// styles
import * as colors from 'styles/colors';
// type
import { DayTrafficDetail } from 'api/dashboard/useTrafficAPI';

interface Props {
  dayTrafficDetails: DayTrafficDetail[];
}
const Chart = ({ dayTrafficDetails }: Props) => {
  const copiedData = dayTrafficDetails.slice();
  const data = copiedData.sort(
    (a, b) =>
      new Date(a.dashboardDate).getTime() - new Date(b.dashboardDate).getTime(),
  );

  const [showPV, setShowPV] = useState(false);
  const [showUV, setShowUV] = useState(true);
  const [showPvPerUser, setShowPvPerUser] = useState(true);

  function getTooltipName(value, name) {
    switch (name) {
      case 'pv':
        return [value, 'PV'];
      case 'uv':
        return [value, 'UV'];
      case 'pvPerUser':
        return [value, '인당 PV'];
      default:
        return '';
    }
  }

  function getLegendName(value: string) {
    switch (value) {
      case 'pv':
        return 'PV';
      case 'uv':
        return 'UV';
      case 'pvPerUser':
        return '인당 PV';
      default:
        return '';
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: 10,
          marginRight: 100,
        }}
      >
        <Button onClick={() => setShowPV((prev) => !prev)} isActive={showPV}>
          PV
        </Button>
        <Button
          color="primary"
          onClick={() => setShowUV((prev) => !prev)}
          isActive={showUV}
        >
          UV
        </Button>
        <Button
          color="primary"
          onClick={() => setShowPvPerUser((prev) => !prev)}
          isActive={showPvPerUser}
        >
          인당 PV
        </Button>
      </div>
      <ResponsiveContainer width="99%" aspect={4}>
        <ComposedChart
          barGap={0}
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dashboardDate" />
          <YAxis yAxisId="left" />
          {showPvPerUser ? <YAxis yAxisId="right" orientation="right" /> : null}
          <Tooltip formatter={getTooltipName} />
          <Legend formatter={getLegendName} />
          {showPV ? (
            <Bar yAxisId="left" type="monotone" dataKey="pv" fill="#fd8e00" />
          ) : null}
          {showUV ? (
            <Bar yAxisId="left" type="monotone" dataKey="uv" fill="#b5d396" />
          ) : null}
          {showPvPerUser ? (
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="pvPerUser"
              stroke="#00abfb"
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;

const Button = styled.div`
  padding: 6px 12px;
  border: 1px solid ${colors.BORDER_GREY};
  border-radius: 8px;
  cursor: pointer;

  font-size: 14px;
  background-color: ${({ isActive }) =>
    isActive ? colors.FONT : colors.WHITE};
  color: ${({ isActive }) => (isActive ? colors.WHITE : colors.FONT)};

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${colors.GREY_LIGHT};
  }
`;
