import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
// common
import Breadcrumb from '../../common/breadcrumb';
import { toastify } from '../../../utils/common';
// api
import {
  getDeliveryList,
  setInvoiceNumber,
  sendReturnNotification,
} from '../../../api/store/delivery';
// components
import ListItem from './ListItem';
// types
import {
  DeliveryInfo,
  SetInvoiceNumberData,
  SendReturnNotificationData,
} from 'api/store/delivery';

export default function Delivery() {
  const [deliveryList, setDeliveryList] = useState<DeliveryInfo[]>([]);
  const [selectList, setSelectList] = useState<any[]>([]);

  useEffect(() => {
    _getDeliveryList();
  }, []);

  const _getDeliveryList = async () => {
    try {
      const result = await getDeliveryList();
      setDeliveryList(result);
    } catch (error) {
      console.error('_getDeliveryList :: ', error);
    }
  };

  // 리턴 예약 알림톡
  const _sendReturnNotification = async () => {
    try {
      if (selectList.length === 0) {
        toastify('error', '선택된 정보가 없습니다.');
        return false;
      } else if (selectList.find((item) => item.return_guide_dt === '')) {
        toastify('error', '리턴 날짜를 확인해주세요.');
        return false;
      }

      const data: SendReturnNotificationData[] = [];
      let isSplitYn = true;
      selectList.map((item) => {
        // 분할배송 있는지 체크
        if (Boolean(item.split_delivery_yn)) {
          isSplitYn = false;
        }

        const returnDate = moment(item.return_guide_dt).format(
          'YYYY-MM-DD HH:mm',
        );

        const selectInfo = {
          order_id: String(item.orderId),
          delivery_id: String(item.deliveryId),
          return_guide_dt: String(returnDate),
        };
        data.push(selectInfo);
      });

      if (!isSplitYn) {
        return false;
      }

      await sendReturnNotification(data);
      const deliveryResult = await getDeliveryList();
      setDeliveryList(deliveryResult);
      setSelectList([]);
      toastify('success', '리턴 일시가 입력되었습니다.');
    } catch (error) {
      console.error('_sendReturnNotification :: ', error);
    }
  };

  // 송장번호 입력
  const _setInvoiceNumber = async () => {
    try {
      if (selectList.length === 0) {
        toastify('error', '선택된 정보가 없습니다.');
        return false;
      } else if (selectList.find((item) => item.invoiceNumber === '')) {
        toastify('error', '송장번호가 입력되었는지 확인해주세요.');
        return false;
      }

      const data: SetInvoiceNumberData[] = [];

      selectList.map((item) => {
        const selectInfo = {
          order_id: String(item.orderId),
          delivery_id: String(item.deliveryId),
          invoice_number: String(item.invoiceNumber),
        };
        data.push(selectInfo);
      });

      await setInvoiceNumber(data);
      const deliveryResult = await getDeliveryList();
      setDeliveryList(deliveryResult);
      setSelectList([]);
      toastify('success', '송장번호가 입력되었습니다.');
    } catch (error) {
      console.error('_setInvoiceNumber :: ', error);
    }
  };

  // 선택 시 selectList에 추가
  const handleSetSelectList = (selectInfo: DeliveryInfo) => {
    let receiveList = selectList;
    if (!selectList.find((item) => item.deliveryId === selectInfo.deliveryId)) {
      setSelectList((prev) => [...prev, selectInfo]);
    } else {
      const deleteIndex = selectList.findIndex(
        (item) => item.deliveryId === selectInfo.deliveryId,
      );
      receiveList.splice(deleteIndex, 1);
      setSelectList(receiveList);
    }
  };

  // 선택 후 송장번호 입력/변경 후
  const handleSetSelectInput = (selectInfo: DeliveryInfo) => {
    let receiveList = selectList;
    if (selectList.find((item) => item.deliveryId === selectInfo.deliveryId)) {
      const deleteIndex = selectList.findIndex(
        (item) => item.deliveryId === selectInfo.deliveryId,
      );
      receiveList.splice(deleteIndex, 1);
      setSelectList(receiveList);
      setSelectList((prev) => [...prev, selectInfo]);
    }
  };

  // 선택 후 리턴 일시 입력/변경 후
  const handleSetDate = (selectInfo: DeliveryInfo) => {
    let receiveList = selectList;
    if (selectList.find((item) => item.deliveryId === selectInfo.deliveryId)) {
      const deleteIndex = selectList.findIndex(
        (item) => item.deliveryId === selectInfo.deliveryId,
      );
      receiveList.splice(deleteIndex, 1);
      setSelectList(receiveList);
      setSelectList((prev) => [...prev, selectInfo]);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="배송" parent="찾아가는 서점" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="container-fluid">
                  <div
                    className="row"
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      className="row p-l-20"
                      style={{ alignItems: 'center' }}
                    >
                      <h5 className="m-r-20">배송 리스트</h5>
                      <div
                        className="m-r-5"
                        style={{
                          width: 30,
                          height: 10,
                          backgroundColor: '#c8e4fb',
                        }}
                      />
                      배송 시작
                      <div
                        className="m-l-10 m-r-5"
                        style={{
                          width: 30,
                          height: 10,
                          backgroundColor: '#d3ebd3',
                        }}
                      />
                      배송 도착
                      <div
                        className="m-l-10 m-r-5"
                        style={{
                          width: 30,
                          height: 10,
                          backgroundColor: '#ffd6c9',
                        }}
                      />
                      리턴 안내
                      <div
                        className="m-l-10 m-r-5"
                        style={{
                          width: 30,
                          height: 10,
                          backgroundColor: 'rgb(244,81,30, 0.6)',
                        }}
                      />
                      리턴일이 지난 리스트
                    </div>
                    <div className="row f-right btn-showcase">
                      {/* <Button
                        outline
                        color="primary"
                        onClick={_sendReturnNotification}
                      >
                        구매 결정 일시 입력
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover" style={{ minWidth: 1300 }}>
                  <thead>
                    <tr>
                      <th style={{ width: '6%' }} className="text-center">
                        주문ID
                      </th>
                      <th style={{ width: '6%' }}>수신인</th>
                      <th style={{ width: '10%' }}>전화번호</th>
                      <th style={{ width: '10%' }}>주소</th>
                      <th style={{ width: '10%' }}>배송 요청 사항</th>
                      <th style={{ width: '10%' }}>송장번호 입력</th>
                      <th style={{ width: '6%' }}>배송 상태</th>
                      {/* <th style={{ width: '15%' }}>리턴 일시 입력</th> */}
                      <th style={{ width: '4%' }} className="text-center">
                        횟수
                      </th>
                      <th style={{ width: '15%' }}>도서명</th>
                      <th style={{ width: '5%' }} className="text-start">
                        선택
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryList.length > 0 &&
                      deliveryList.map((item) => (
                        <ListItem
                          key={item.deliveryId}
                          deliveryInfo={item}
                          onSetSelectList={handleSetSelectList}
                          onSetSelectInput={handleSetSelectInput}
                          onSetDate={handleSetDate}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
