import React from 'react';
// styles
import { Table } from 'styles/index';
// types
import { DayTraffic } from 'api/dashboard/useTrafficAPI';

interface Props {
  dayTraffics: DayTraffic[];
  pageCode: string;
  handleGetDayTrafficDetail: any;
}
function TrafficList({
  dayTraffics,
  pageCode,
  handleGetDayTrafficDetail,
}: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: '페이지',
        accessor: 'page',
      },
      {
        Header: 'UV',
        accessor: 'uv',
      },
      {
        Header: 'PV',
        accessor: 'pv',
      },
      {
        Header: '인당 PV',
        accessor: 'pvPerUser',
      },
    ],
    [],
  );

  return (
    <Table
      fullScreen
      columns={columns}
      data={dayTraffics}
      onPress={handleGetDayTrafficDetail}
      type="traffic"
      activeValue={pageCode}
      style={{ marginBottom: 20 }}
    />
  );
}

export default TrafficList;
