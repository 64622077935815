import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FORM_HEIGHT, FORM_WIDTH } from './selectFormSize';

const SelectDateForm = ({ date, maxDate, getData, width = FORM_WIDTH }) => {
  return (
    <SelectDate
      selected={date}
      dateFormat="yyyy-MM-dd"
      onChange={(date: any) => {
        getData(new Date(date).getTime());
      }}
      placeholderText="전체기간"
      maxDate={new Date(maxDate)}
      width={width}
    />
  );
};
const SelectDate = styled(DatePicker)`
  width: ${(props) => props.width}px;
  height: ${FORM_HEIGHT}px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;
export default SelectDateForm;
