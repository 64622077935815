import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import DateTimePicker from 'react-datetime-picker';

interface Props {
  title: string;
  date: Date;
  setDate: any;
}

const SelectDateForm: FunctionComponent<Props> = ({ title, date, setDate }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <DateTimePicker onChange={(date) => setDate(date)} value={date} />
    </Container>
  );
};

const Container = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 100px;
`;

const Title = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

export default SelectDateForm;
