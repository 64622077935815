import { apiGet, apiPost } from '../useAxios';

export interface SetBookOrderCompleted {
  orderBook_id: number;
  user_id: number;
  order_id: number;
  bookPreparationStatus?: number;
  invoiceNumber?: string;
}

export interface DeliveryInfo {
  orderId: number;
  userId: number;
  addressId: number;
  ordererName: string;
  orderStatus: number;
  orderStatusName: string;
  orderDate: string;
  deliveryId: number;
  deliveryStatus: number;
  deliveryStatusName: string;
  invoiceNumber: string;
  invoiceStatus: string;
  deliveryStartNoticeYn: number;
  deliveryEndNoticeYn: number;
  deliveryDate: string;
  receiver_name: string;
  receiver_phone: string;
  address: string;
  delivery_request: string;
  return_guide_dt: Date;
  return_talk_cnt: number;
  split_delivery_yn: number;
  bookList: Book[];
}

export interface Book {
  orderId: number;
  orderBookId: number;
  bookId: number;
  bookCondition: number;
  ordinalNo: number;
  bookName: string;
  authorName: string;
  publisherName: string;
  stock: string;
  isbn_13: string;
  price: string;
  copyText: string;
}

export interface SetInvoiceNumberData {
  order_id: string;
  delivery_id: string;
  invoice_number: string;
}

export interface SendReturnNotificationData {
  order_id: string;
  delivery_id: string;
  return_guide_dt: string;
}
export interface SetBookOrderCompletedErrorListItem {
  orderId: number; // 주문ID
  receiverName: string; // 주문자명
}
export interface SetBookOrderCompletedResponse {
  totalCount: number; // 총 발송 시도 건수
  errorCount: number; // 에러 발생 건수
  errorList: SetBookOrderCompletedErrorListItem[];
}

// 책 준비 완료
export const setBookOrderCompleted = async (
  selectList: SetBookOrderCompleted[],
): Promise<SetBookOrderCompletedResponse> => {
  try {
    const data = { deliveryList: selectList };
    const res = await apiPost('admin/store/delivery', data);
    return res?.data?.result;
  } catch (error) {
    throw error;
  }
};

// 배송 리스트
export const getDeliveryList = async (): Promise<DeliveryInfo[]> => {
  try {
    const result = await apiGet('admin/store/delivery');
    return result.data.result.list;
  } catch (error) {
    throw error;
  }
};

// 송장번호 입력
export const setInvoiceNumber = async (
  delivery_list: SetInvoiceNumberData[],
): Promise<any> => {
  try {
    const data = {
      delivery_list: delivery_list,
    };
    const result = await apiPost('admin/store/delivery/invoiceNumber', data);
    return result;
  } catch (error) {
    throw error;
  }
};

// 리턴 예약 알림톡
export const sendReturnNotification = async (
  delivery_list: SendReturnNotificationData[],
): Promise<any> => {
  try {
    const data = {
      delivery_list: delivery_list,
    };
    const result = await apiPost('admin/store/delivery/push/return', data);
    return result;
  } catch (error) {
    throw error;
  }
};
