import React, { useMemo } from 'react';
// styles
import { Table } from 'styles/index';

export default function OrderInfo({ orderInfo }) {
  const columns = useMemo(
    () => [
      {
        Header: '주문ID',
        accessor: 'orderId',
      },
      {
        Header: '주문자명',
        accessor: 'ordererName',
      },
      {
        Header: '주문 상태',
        accessor: 'orderStatus',
      },
      {
        Header: '주문 일시',
        accessor: 'orderDate',
      },
      {
        Header: '주문 최근 변경 일시',
        accessor: 'orderUpdateDate',
      },
      {
        Header: '주문자 연락처',
        accessor: 'userPhone',
      },
      {
        Header: '주문자 마일리지',
        accessor: 'mileage',
      },
      {
        Header: '배송지 이름',
        accessor: 'addressName',
      },
      {
        Header: '배송지 수취인',
        accessor: 'receiverName',
      },
      {
        Header: '배송지 연락처',
        accessor: 'receiverPhone',
      },
      {
        Header: '주소',
        accessor: 'address',
      },
      {
        Header: '배송 요청 사항',
        accessor: 'deliveryRequest',
      },
      {
        Header: '기본 배송지 여부',
        accessor: 'addressDefaultYn',
      },
      {
        Header: '결제 카드 정보',
        accessor: 'cardInfo',
      },
      {
        Header: '기본 결제 카드 여부',
        accessor: 'cardDefaultYn',
      },
    ],
    [],
  );

  if (!orderInfo) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: 700,
          marginBottom: 10,
          paddingLeft: 5,
        }}
      >
        주문 상세 정보
      </div>
      <Table fullScreen columns={columns} data={[orderInfo]} />
    </div>
  );
}
