import React from 'react';
// lib
import { handleCheckBookStatus } from 'lib/tools';
// types
import { StockBook } from 'api/types';

interface Props {
  bookInfo: StockBook;
}

export default function StockUpListitem({ bookInfo }: Props) {
  return (
    <tr order-id={bookInfo.bookId}>
      <td style={{ width: '10%' }}>{bookInfo.bookId}</td>
      <td style={{ width: '30%' }}>{bookInfo.bookName}</td>
      <td style={{ width: '10%' }}>
        {handleCheckBookStatus(bookInfo.bookCondition)}
      </td>
      <td style={{ width: '10%' }}>{bookInfo.cnt}</td>
      <td style={{ width: '20%' }}>{bookInfo.orderIdList}</td>
      <td style={{ width: '20%' }}>{bookInfo.stockInfo}</td>
    </tr>
  );
}
