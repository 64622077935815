import React, { useEffect, useState } from 'react';
import ButtonComponent from 'components/common/ButtonComponent';
import { useHistory } from 'react-router';
import Loader from 'components/common/loader';
import useEditorsPickAPI from 'api/operation/useEditorsPickAPI';
import TextInputForm from '../../../aComponents/TextInputForm';
import BookListForm from '../../../aComponents/BookListForm';
import ModalSearchBookCurationForm from '../../../aComponents/ModalSearchBookCurationForm';
import DatePickerForm from '../../../aComponents/DatePickerForm';
import ImageRectangleForm from '../../../aComponents/ImageRectangleForm';
import TextAreaForm from '../../../aComponents/TextAreaForm';
import styled from 'styled-components';
import { GREY_LIGHT } from 'styles/colors';

const CreateEditorPickPresenter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editorsPickImage, setEditorsPickImage] = useState(null);
  const [editorsPickImagePath, setEditorsPickImagePath] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [publishDate, setPublishDate] = useState(new Date());
  const [addEditorsPickBookModal, setAddEditorsPickBookModal] = useState(false);
  const [editorsPickBookList, setEditorsPickBookList] = useState<any[]>([]);

  const { postEditorsPickAPI } = useEditorsPickAPI();
  const history = useHistory();
  const inputId = 'creatorFileInput';

  const editorsPickImageClickHandle = () => {
    const element = document.getElementById(inputId);
    element?.click();
  };

  const onChangeEditorsPickImage = (fileObj: any) => {
    let filePath = fileObj.target.files[0];
    if (filePath !== undefined) {
      let reader = new FileReader();
      reader.onload = function (event: any) {
        setEditorsPickImage(event.target.result);
        setEditorsPickImagePath(filePath);
      };
      reader.readAsDataURL(filePath);
    }
  };

  const onChangeTitle = (text) => {
    const textValue = text.target.value;
    setTitle(textValue);
  };

  const onChangeContent = (text) => {
    const textValue = text.target.value;
    setContent(textValue);
  };

  const selectEditosPickBookHandle = (book) => {
    const addBookIdList = [...editorsPickBookList, book];
    setAddEditorsPickBookModal(false);
    setEditorsPickBookList(addBookIdList);
  };

  const cancelEditorsPickBookHandle = (bookId) => {
    const cancelBookList = editorsPickBookList.filter(
      (book) => book.bookId !== bookId,
    );
    setEditorsPickBookList(cancelBookList);
  };

  const createEditosPickContentHandle = async () => {
    setIsLoading(true);
    const publishDateTimeStamp = new Date(publishDate).getTime();
    const formData: any = new FormData();
    try {
      const nullCheck = [
        { type: '이미지', result: editorsPickImagePath === null },
        { type: '제목', result: title === '' },
        { type: '콘텐츠', result: content === '' },
        { type: 'Editor`s Pick 책', result: editorsPickBookList.length <= 0 },
      ];

      const nullValues = nullCheck.filter((value) => value.result);

      if (nullValues.length > 0) {
        alert(`${nullValues[0].type}(을)를 작성해주세요.`);
      } else {
        formData.append('file', editorsPickImagePath);
        formData.append('title', title);
        formData.append('content', content);
        formData.append('publishDate', publishDateTimeStamp);
        formData.append(
          'editorsPickBookList',
          JSON.stringify(editorsPickBookList),
        );
        await postEditorsPickAPI(formData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      history.push({ pathname: '/editorsPick' });
    }
  };

  useEffect(() => {
    const element: any = document.getElementById(inputId);
    element.addEventListener('click', editorsPickImageClickHandle);
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <Title>Editor`s Pick 생성</Title>
      </HeaderWrapper>
      <Wrapper>
        <ImageRectangleForm
          title={'썸네일 이미지'}
          image={editorsPickImage}
          onChange={editorsPickImageClickHandle}
          onClick={onChangeEditorsPickImage}
          id={inputId}
        />
        <BorderTop />
        <TextInputForm
          value={title}
          title={'제목'}
          onChange={onChangeTitle}
          type={'text'}
        />
        <BorderTop />
        <TextAreaForm
          title={'콘텐츠'}
          onChange={onChangeContent}
          value={content}
          cols={20}
          row={10}
        />
        <BorderTop />
        <DatePickerForm
          title={'Editor`s Pick 게시일자'}
          date={publishDate}
          setDate={setPublishDate}
        />
        <BorderTop />
        <BookListForm
          title={'Editor`s Pick 책 등록'}
          data={editorsPickBookList}
          onClick={() => setAddEditorsPickBookModal(!addEditorsPickBookModal)}
          cancelBookHandle={cancelEditorsPickBookHandle}
        />
      </Wrapper>
      <div style={{ marginTop: 30 }} />
      <ButtonComponent
        width={150}
        height={50}
        title={'생성'}
        color={'white'}
        fontSize={20}
        backgroundColor={'black'}
        onClick={() => createEditosPickContentHandle()}
        fontWeight={700}
        borderRadius={10}
      />
      <ModalSearchBookCurationForm
        visible={addEditorsPickBookModal}
        bookClickHandle={selectEditosPickBookHandle}
        id={'curationSearch'}
      />

      <Loader isLoading={isLoading} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: 'jua';
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 0px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const BorderTop = styled.div`
  width: 100%;
  border-top: solid 2px ${GREY_LIGHT};
  margin-top: 40px;
  margin-bottom: 20px;
  opacity: 0.2;
`;

export default CreateEditorPickPresenter;
