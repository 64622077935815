import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// apis
import useUserAPI from 'api/operation/useUserAPI';
// styles
import { Flex, Table } from 'styles/index';
import Pagination from '@material-ui/lab/Pagination';
// types
import { Tracking } from 'api/types';

const TRACKING_LENGTH = 50;
export default function UserTracking({ userId }) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'trackingId',
      },
      {
        Header: '세션번호',
        accessor: 'sessionIdx',
      },
      {
        Header: '앱 버전',
        accessor: 'appVersion',
      },
      {
        Header: 'behavior',
        accessor: 'behavior',
      },
      {
        Header: 'action',
        accessor: 'action',
      },
      {
        Header: 'pageType',
        accessor: 'pageType',
      },
      {
        Header: 'pageName',
        accessor: 'pageName',
      },
      {
        Header: 'extra',
        accessor: 'extra',
      },
      {
        Header: '등록일시',
        accessor: 'insertDate',
      },
    ],
    [],
  );

  const { getUserTrackingAPI } = useUserAPI();

  const [page, setPage] = useState(1);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [trackings, setTrackings] = useState<Tracking[]>([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const pageCount = Math.ceil(totalCnt / TRACKING_LENGTH);

  async function handleGetUserTracking(customPage?: number) {
    if (!userId) return;
    try {
      const res = await getUserTrackingAPI({
        userId,
        page: customPage ? customPage : page,
      });
      setTotalCnt(res.totalCnt);
      setTrackings(res.trackingList);
    } catch (error) {
      console.log(error);
    }
  }

  function handleChangePage(page) {
    setPage(page);
    handleGetUserTracking(page);
  }

  useEffect(() => {
    if (userId) {
      //  reset
      setPage(1);
      handleGetUserTracking(1);
      setPaginationLoading(true);
      setTimeout(() => {
        setPaginationLoading(false);
      }, 50);
    }
  }, [userId]);

  if (!userId) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <Table
        fullScreen
        columns={columns}
        data={trackings}
        activeValue=""
        style={{ marginBottom: 20 }}
      />
      <Flex style={{ justifyContent: 'center' }}>
        {paginationLoading ? (
          <div style={{ height: 32 }} />
        ) : (
          <StyledPagination
            defaultPage={1}
            count={pageCount ? pageCount : 1}
            onChange={(_, page) => handleChangePage(page)}
          />
        )}
      </Flex>
    </div>
  );
}

const StyledPagination = styled(Pagination)`
  button {
    outline: none;
  }
`;
