import { toast } from 'react-toastify';

// Date Format
export const dateFormat = (value) => {
  if (!value) return;

  const year = value.getFullYear(); // 년 (4자리)
  const month = value.getMonth() + 1; // 월 (2자리)
  const date = value.getDate(); // 일 (2자리)
  // const hours = value.getHours();
  // const minutes = value.getMinutes();
  // const seconds = value.getSeconds();

  // return `${month}-${date} ${hours}:${minutes}`;
  return `${year}-${month}-${date}`;
};

// 빈 객체 체크
export function isEmpty(param) {
  return Object.keys(param).length !== 0;
}

export const toastify = (type = 'success', comment) => {
  if (type === 'error') {
    toast.error(comment, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === 'success') {
    toast.info(comment, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export function getHyphenWithPhoneNumber(phoneNumber) {
  const stringNumber = String(phoneNumber);
  const result = stringNumber
    .replace(/[^0-9]/g, '')
    .replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      '$1-$2-$3',
    )
    .replace('--', '-');
  return result;
}


export function oneToOneJoinByKey(darr0,darr1,onKey) {
    var rd = {} 

    if (!darr0 || !darr1) return <div></div>
    
    darr0.forEach( (x) => {
      const keyValue = x[onKey];
      Object.assign(rd,{[keyValue]:x});
    });
    
    darr1.forEach( (y) => {
      const keyValue = y[onKey];
      try { 
        Object.assign(rd,{[keyValue]:Object.assign(rd[keyValue],y)});
      }
      catch {
        Object.assign(rd,{[keyValue]:y});
      }
    });

    return Object.entries(rd).map( (x) => x[1] );
  }

export function chunk(array, size) {
  const chunked = []
    
  for (let element of array) {
  const last = chunked[chunked.length - 1]
 
  if (!last || last.length === size) {
  chunked.push([element])
  } else {
  last.push(element)
  }
  }
   
  return chunked
}
    


