import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  date: Date;
  maxDate?: Date;
  text: string | null;
  getData: (date: any) => void;
  type: string;
}

const DatePickComponent: FunctionComponent<Props> = ({
  date,
  maxDate = new Date(),
  text,
  getData,
  type,
}) => {
  return (
    <Container>
      <Text>{text}</Text>
      <SelectDate
        selected={date}
        dateFormat="yyyy-MM-dd"
        onChange={(date: any) => {
          getData(new Date(date).getTime());
        }}
        placeholderText="클릭해주세요."
        maxDate={new Date(maxDate)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  background-color: white;
  padding: 20px 0 20px 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
  margin-top: 20px;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

const SelectDate = styled(DatePicker)`
  width: 200px;
  height: 35px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default DatePickComponent;
