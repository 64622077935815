import styled from 'styled-components';
import { Banner } from 'api/types';
import React, { useEffect, useState } from 'react';
import useBannerAPI from '../../../api/operation/useBannerAPI';
import Loader from 'components/common/loader';
import CustomTabFilterBox from './aComponents/CustomTabFilterBox';
import BannerInfo from './aComponents/BannerInfo';
import { useHistory } from 'react-router';
import { landingTypeUrlFilterList } from './BannerConstant';
import { BLACK_PRIMARY } from 'styles/colors';
import { IoMdAdd } from 'react-icons/io';
import Breadcrumb from 'components/common/breadcrumb';

const BannerPresenter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pickDate, setPickDate] = useState<Date>(new Date());
  const [bannerData, setBannerData] = useState([]);
  const [selectLandingType, setSelectLandingType] = useState<any>(
    landingTypeUrlFilterList[0],
  );
  const [deleteCheck, setDeleteCheck] = useState(false);

  const { getBannerAPI } = useBannerAPI();
  const history = useHistory();

  const getBanner = async () => {
    try {
      setIsLoading(true);
      const result = await getBannerAPI();
      if (result) {
        setBannerData(result);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const moveToCreateBanner = () => {
    history.push({
      pathname: '/create-banner',
    });
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>
      <HeaderContainer>
        <HeaderWrapper>
          <Breadcrumb title="배너 관리" parent="운영" />
        </HeaderWrapper>
        <HeaderTabContainer>
          <HeaderTabWrapper onClick={() => moveToCreateBanner()}>
            <IoMdAdd size={30} color={'white'} />
          </HeaderTabWrapper>
          <div />
          <CustomTabFilterBox
            pickDate={pickDate}
            setPickDate={setPickDate}
            selectLandingType={selectLandingType}
            setSelectLandingType={setSelectLandingType}
            landingTypeData={landingTypeUrlFilterList}
            deleteCheck={deleteCheck}
            setDeleteCheck={setDeleteCheck}
          />
        </HeaderTabContainer>
      </HeaderContainer>
      <Container>
        <BannerContainer>
          {bannerData.length > 0
            ? bannerData.map((banner: Banner) => (
                <BannerInfo
                  key={banner.bannerId}
                  {...banner}
                  selectLandingType={selectLandingType}
                  pickDate={pickDate}
                  deleteCheck={deleteCheck}
                />
              ))
            : null}
        </BannerContainer>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderTabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  div {
    margin-right: 20px;
  }
`;

const HeaderTabWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: ${BLACK_PRIMARY};
  border-radius: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-top: 20px;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px 0 20px 0px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
`;

const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  justify-content: space-between;
  grid-gap: 20px;
`;

export default BannerPresenter;
