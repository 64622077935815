import styled from 'styled-components';
import useDetectionAPI from 'api/machineLearning/useDetectionAPI';
import React, { useEffect, useState } from 'react';
import ImageForm from './aCommon/ImageForm';
import Book from './aComponents/Book';
import Loader from 'components/common/loader';

const MachineLearning = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [resultData, setResultData] = useState<any>(null);

  const imageId = 'machineLearingImage';

  const onChangeImage = () => {
    const element = document.getElementById(imageId);
    element?.click();
  };

  const imageClickHandle = (fileObj: any) => {
    let filePath = fileObj.target.files[0];
    if (filePath !== undefined) {
      let reader = new FileReader();
      reader.onload = function (event: any) {
        setImage(event.target.result);
        setImagePath(filePath);
      };
      reader.readAsDataURL(filePath);
    }
  };

  const { postDetectionAPI } = useDetectionAPI();

  const imageDetectionHandle = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', imagePath);
    try {
      const result = await postDetectionAPI(formData);
      if (result.msg !== undefined) {
        alert(result.msg);
      } else {
        setResultData(result);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (imagePath !== '') {
      imageDetectionHandle();
    }
  }, [imagePath]);

  useEffect(() => {
    const element: any = document.getElementById(imageId);
    element.addEventListener('click', onChangeImage);
  });

  return (
    <div>
      <ImageForm
        id={imageId}
        image={image}
        onChange={onChangeImage}
        onClick={imageClickHandle}
      />

      <BookListContainer>
        {resultData !== null ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: 400,
              }}
            >
              <div style={{ fontSize: 20, width: 200 }}>
                Splited Num: &nbsp;
                <strong>{resultData.splited_book}개</strong>
              </div>
              <div style={{ marginBottom: 50, fontSize: 20, width: 200 }}>
                Analysis Time: <strong>{resultData.time}초</strong>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {resultData?.result.map((result: any, index) =>
                result.find === true ? (
                  <Book key={index} {...result.data} />
                ) : (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: 120,
                      fontWeight: 700,
                      color: 'red',
                    }}
                  >
                    분석못함
                  </div>
                ),
              )}
            </div>
          </div>
        ) : null}
      </BookListContainer>
      <Loader isLoading={isLoading} />
    </div>
  );
};

const BookListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
`;

export default MachineLearning;
