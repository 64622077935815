import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_LIGHT } from 'styles/colors';

interface Props {
  onChange: (text: string) => void;
  title: string;
  value: string;
  row: number;
  cols: number;
}

const TextAreaForm: FunctionComponent<Props> = ({
  onChange,
  title,
  value,
  row = 20,
  cols = 50,
}) => {
  return (
    <FormWrapper>
      <FormText>{title}</FormText>
      <TextareaInput rows={row} cols={cols} onChange={onChange} value={value} />
    </FormWrapper>
  );
};

const FormWrapper = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 100px;
`;

const FormText = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

const TextareaInput = styled.textarea`
  display: flex;
  width: 30vw;
  padding: 15px;
  border: solid 1px ${GREY_LIGHT};
  border-radius: 5px;
  :focus {
    border: solid 1px black;
  }
`;

export default TextAreaForm;
