import React from 'react';
// styles
import { Table } from 'styles/index';
// types
import { DayConversionDetail } from 'api/dashboard/useConversionAPI';

interface Props {
  dayConversionDetails: DayConversionDetail[];
}
function ConversionDetailList({ dayConversionDetails }: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            Header: '날짜',
            accessor: 'dashboardDate',
          },
        ],
      },
      {
        Header: 'UV',
        columns: [
          {
            Header: '대상',
            accessor: 'fromUv',
          },
          {
            Header: '전환',
            accessor: 'toUv',
          },
          {
            Header: '전환율',
            accessor: 'conversionUv',
          },
        ],
      },
      {
        Header: 'PV',
        columns: [
          {
            Header: '대상',
            accessor: 'fromPv',
          },
          {
            Header: '전환',
            accessor: 'toPv',
          },
          {
            Header: '전환율',
            accessor: 'conversionPv',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Table
      fullScreen
      columns={columns}
      data={dayConversionDetails}
      style={{ marginBottom: 20 }}
    />
  );
}

export default ConversionDetailList;
