import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
// lib
import { copyStringToClipboard } from 'lib/tools';
// apis
import { setBookOrderCompleted } from '../../../api/store/delivery';
// components
import PreparationBookListItem from './PreparationBookListItem';
// common
import { toastify } from '../../../utils/common';
// types
import {
  Book,
  BookStatus,
  postSendLateAlimTalk,
  PostSendLateAlimTalkData,
} from '../../../api/store/preparation';
import { SetBookOrderCompleted } from '../../../api/store/delivery';

interface Props {
  bookList: Book[];
  bookStatusList: BookStatus[];
  onGetBookPreparationList: () => Promise<void>;
  setFailedDelivery: any;
}

interface SetSelectInfo {
  orderBook_id: number;
  user_id: number;
  order_id: number;
}

export default function PreparationBookList({
  bookList,
  bookStatusList,
  onGetBookPreparationList,
  setFailedDelivery,
}: Props) {
  const [selectList, setSelectList] = useState<SetBookOrderCompleted[]>([]);
  const [orderList, setOrderList]: any = useState(null);
  const [invoiceNumbers, setInvoiceNumbers]: any = useState(null);
  const [bookListForCopy, setBookListForCopy]: any = useState([]);

  const handleSetSelectList = async (selectInfo: SetBookOrderCompleted) => {
    let receiveList = selectList;
    if (
      !selectList.find((item) => item.orderBook_id === selectInfo.orderBook_id)
    ) {
      setSelectList((prev) => [...prev, selectInfo]);
    } else {
      const deleteIndex = selectList.findIndex(
        (item) => item.orderBook_id === selectInfo.orderBook_id,
      );
      receiveList.splice(deleteIndex, 1);
      setSelectList(receiveList);
    }
  };

  // 복사하기
  const handleCopyToClipboard = async (type: string) => {
    let result = '';
    const sortedList = bookListForCopy.sort(
      (a, b) => a.orderIndex - b.orderIndex,
    );
    for (let i = 0; i < sortedList.length; i++) {
      const l = sortedList[i];
      const text = type === 'order' ? l.copyTextOrder : l.copyTextSend;
      if (i === sortedList.length - 1) {
        result += text;
      } else {
        result += `${text}\n`;
      }
    }
    copyStringToClipboard(result);
    toastify('success', '복사가 완료되었습니다.');
  };

  // 주문 완료
  const _setBookOrderCompleted = async () => {
    try {
      if (selectList.length === 0) {
        toastify('error', '선택한 책이 없습니다. 확인해주세요.');
        return;
      }

      let selectBookList: SetSelectInfo[] = [];
      let isCheckBookStatus = true;
      let hasInvoiceNumber = true;

      selectList.map((selectInfo) => {
        let copiedSelectInfo = selectInfo;

        bookList.map((bookInfo) => {
          if (bookInfo.orderBookId === selectInfo.orderBook_id) {
            if (
              invoiceNumbers &&
              invoiceNumbers.hasOwnProperty(bookInfo.orderId)
            ) {
              copiedSelectInfo = {
                ...selectInfo,
                invoiceNumber: invoiceNumbers[bookInfo.orderId],
              };
            } else {
              hasInvoiceNumber = false;
              return;
            }

            if (bookInfo.bookPreparationStatus >= 120) {
              selectBookList.push(copiedSelectInfo);
            } else {
              isCheckBookStatus = false;
              return;
            }
          }
        });
      });

      if (!hasInvoiceNumber) {
        toastify(
          'error',
          '송장번호가 입력되지 않은 주문이 있습니다. 확인해주세요.',
        );
        return false;
      }

      if (!isCheckBookStatus) {
        toastify(
          'error',
          '확보되지 않은 책이 있습니다. 책 준비 상태를 확인해주세요.',
        );
        return false;
      }

      const res = await setBookOrderCompleted(selectBookList);
      if (res.errorCount > 0) {
        setFailedDelivery({
          isShow: true,
          errorList: res.errorList,
        });
      }
      await onGetBookPreparationList();
      // setSelectList([]);
      toastify('success', '주문 완료되었습니다.');
    } catch (error) {
      console.error('_getBookStatusList :: ', error);
    }
  };

  // // 취소 알람톡 전송
  // const _orderCancelAlimtalk = async () => {
  //   try {
  //     if (selectList.length === 0) {
  //       toastify('error', '선택한 책이 없습니다 확인해주세요.');
  //       return false;
  //     }

  //     let orderBookIdList: number[] = [];

  //     selectList.forEach((item) => {
  //       if (item.bookPreparationStatus) {
  //         if (
  //           item.bookPreparationStatus < 110 ||
  //           item.bookPreparationStatus >= 119
  //         ) {
  //           toastify('error', '선택한 책의 준비 상태를 다시 확인해주세요.');
  //           return false;
  //         }
  //       }
  //       orderBookIdList.push(item.orderBook_id);
  //     });

  //     await orderCancleAlimtalk(orderBookIdList);
  //     toastify('success', '취소 알림톡이 전송되었습니다.');
  //     setSelectList([]);
  //   } catch (error) {
  //     console.error('_orderCancelAlimtalk :: ', error);
  //   }
  // };

  // 베송지연 알림톡 전송
  const handleSendLateAlimTalk = async () => {
    try {
      const deliveryList: PostSendLateAlimTalkData[] = [];
      for (let i = 0; i < selectList.length; i++) {
        deliveryList.push({
          orderId: selectList[i].order_id,
          orderBookId: selectList[i].orderBook_id,
          userId: selectList[i].user_id,
        });
      }
      await postSendLateAlimTalk({
        deliveryList,
      });
      await onGetBookPreparationList();
      toastify('success', '지연 알림톡 전송이 완료되었습니다.');
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (bookList.length > 0) {
      let newObj: any = {};
      let prevOrderId = 0;
      for (let i = 0; i < bookList.length; i++) {
        const book = bookList[i];
        if (prevOrderId === book.orderId) {
          newObj[book.orderId].push(book);
        } else {
          newObj[book.orderId] = [book];
          prevOrderId = book.orderId;
        }
        setOrderList(newObj);
      }
    }
  }, [bookList]);

  useEffect(() => {
    setSelectList([]);
  }, [bookList, bookStatusList]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <div className="container-fluid">
              <div
                className="row"
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h5>책 주문 리스트</h5>
                <div className="row btn-showcase">
                  <Button
                    outline
                    color="secondary"
                    onClick={() => handleCopyToClipboard('order')}
                  >
                    복사(주문)
                  </Button>
                  <Button
                    outline
                    color="secondary"
                    onClick={() => handleCopyToClipboard('send')}
                  >
                    복사(발송)
                  </Button>
                  <Button
                    outline
                    color="primary"
                    onClick={handleSendLateAlimTalk}
                  >
                    지연 알람톡 전송
                  </Button>
                  <Button
                    outline
                    color="primary"
                    onClick={_setBookOrderCompleted}
                  >
                    발송 처리
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive-xl">
            <CustomTable
              className="table table-hover"
              style={{ minWidth: 1300, marginBottom: 0 }}
            >
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>주문ID</th>
                  <th style={{ width: '10%' }}>송장번호</th>
                  <th style={{ width: '10%' }}>송장번호</th>
                  <th style={{ width: '5%' }}>복사</th>
                  <th style={{ width: '18%' }}>책 제목</th>
                  <th style={{ width: '6%' }}>책 상태</th>
                  <th style={{ width: '10%' }}>재고 상태</th>
                  <th style={{ width: '13%' }}>준비 상태</th>
                  <th style={{ width: '5%' }}>선택</th>
                  <th style={{ width: '8%' }}>지연 알림 여부</th>
                  <th style={{ width: '8%' }}>발송 처리 여부</th>
                </tr>
              </thead>
              <tbody>
                {orderList &&
                  Object.keys(orderList).map((order, i) => (
                    <React.Fragment key={order}>
                      {orderList[order].map((bookInfo: any, j: any) => (
                        <PreparationBookListItem
                          key={bookInfo.orderBookId}
                          orderIndex={parseInt(`${i + 1}${j + 1}`)}
                          bookInfo={bookInfo}
                          isFirstItem={j === 0}
                          isLastItem={orderList[order].length - 1 === j}
                          bookListLength={orderList[order].length}
                          bookStatusList={bookStatusList}
                          onGetBookPreparationList={onGetBookPreparationList}
                          onSetSelectList={handleSetSelectList}
                          setInvoiceNumbers={setInvoiceNumbers}
                          setBookListForCopy={setBookListForCopy}
                        />
                      ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  );
}

const CustomTable = styled.table`
  th,
  td {
    padding: 4px 6px 4px 6px;
    text-align: center;
  }
`;
