import React, { useEffect, useState } from 'react';
import ButtonComponent from 'components/common/ButtonComponent';
import useCreatorAPI from 'api/operation/useCreatorAPI';
import { useHistory } from 'react-router';
import Loader from 'components/common/loader';
import TextInputForm from '../../../aComponents/TextInputForm';
import BookListForm from '../../../aComponents/BookListForm';
import ModalSearchBookCurationForm from '../../../aComponents/ModalSearchBookCurationForm';
import DatePickerForm from '../../../aComponents/DatePickerForm';
import ImageRectangleForm from '../../../aComponents/ImageRectangleForm';
import styled from 'styled-components';
import { GREY_LIGHT } from 'styles/colors';

const CreateCreator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [creatorImage, setCreatorImage] = useState(null);
  const [creatorImagePath, setCreatorImagePath] = useState(null);
  const [creatorName, setCreatorName] = useState('');
  const [subscriberNum, setSubscriberNum] = useState('');
  const [link, setLink] = useState('');
  const [creatorPublishDate, setCreatorPublishDate] = useState(new Date());
  const [addCreatorBookModal, setAddCreatorBookModal] = useState(false);
  const [creatorBookList, setCreatorBookList] = useState<any[]>([]);

  const { postCreatorAPI } = useCreatorAPI();
  const history = useHistory();
  const inputId = 'creatorFileInput';

  const creatorImageClickHandle = () => {
    const element = document.getElementById(inputId);
    element?.click();
  };

  const onChangeCreatorImage = (fileObj: any) => {
    let filePath = fileObj.target.files[0];
    if (filePath !== undefined) {
      let reader = new FileReader();
      reader.onload = function (event: any) {
        setCreatorImage(event.target.result);
        setCreatorImagePath(filePath);
      };
      reader.readAsDataURL(filePath);
    }
  };

  const onChangeCreatorName = (text) => {
    const textValue = text.target.value;
    setCreatorName(textValue);
  };

  const onChangeSubscriberNum = (text) => {
    const textValue = text.target.value;
    setSubscriberNum(textValue);
  };

  const onChangeLink = (text) => {
    const textValue = text.target.value;
    setLink(textValue);
  };

  const selectCreatorBookHandle = (book) => {
    const addBookIdList = [...creatorBookList, book];
    setAddCreatorBookModal(false);
    setCreatorBookList(addBookIdList);
  };

  const cancelCreatorBookHandle = (bookId) => {
    const cancelBookList = creatorBookList.filter(
      (book) => book.bookId !== bookId,
    );
    setCreatorBookList(cancelBookList);
  };

  const createBlibraryContentHandle = async () => {
    setIsLoading(true);
    const publishDateTimeStamp = new Date(creatorPublishDate).getTime();
    const formData: any = new FormData();

    try {
      const nullCheck = [
        { type: '이미지', result: creatorImagePath === null },
        { type: '크리에이터 이름', result: creatorName === '' },
        { type: '구독자 수', result: subscriberNum === '' },
        { type: '링크', result: link === '' },
      ];

      const nullValues = nullCheck.filter((value) => value.result);
      if (nullValues.length > 0) {
        alert(`${nullValues[0].type}(을)를 작성해주세요.`);
      } else {
        formData.append('file', creatorImagePath);
        formData.append('creatorName', creatorName);
        formData.append('subscriberNum', subscriberNum);
        formData.append('link', link);
        formData.append('publishDate', publishDateTimeStamp);
        formData.append('creatorBookList', JSON.stringify(creatorBookList));
        await postCreatorAPI(formData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      history.push({ pathname: '/creator' });
    }
  };

  useEffect(() => {
    const element: any = document.getElementById(inputId);
    element.addEventListener('click', creatorImageClickHandle);
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <Title>크리에이터 생성</Title>
      </HeaderWrapper>
      <Wrapper>
        <ImageRectangleForm
          title={'썸네일 이미지'}
          image={creatorImage}
          onChange={creatorImageClickHandle}
          onClick={onChangeCreatorImage}
          id={inputId}
        />
        <BorderTop />
        <TextInputForm
          value={creatorName}
          title={'크리에이터 이름'}
          onChange={onChangeCreatorName}
          type={'text'}
        />
        <BorderTop />
        <TextInputForm
          value={subscriberNum}
          title={'구독자수'}
          onChange={onChangeSubscriberNum}
          type={'text'}
        />
        <BorderTop />
        <TextInputForm
          value={link}
          title={'링크'}
          onChange={onChangeLink}
          type={'text'}
        />
        <BorderTop />
        <DatePickerForm
          title={'큐래이션 게시일자'}
          date={creatorPublishDate}
          setDate={setCreatorPublishDate}
        />
        <BorderTop />
        <BookListForm
          title={'크리에이터 책 등록'}
          data={creatorBookList}
          onClick={() => setAddCreatorBookModal(!addCreatorBookModal)}
          cancelBookHandle={cancelCreatorBookHandle}
        />
      </Wrapper>
      <div style={{ marginTop: 30 }} />
      <ButtonComponent
        width={150}
        height={50}
        title={'생성'}
        color={'white'}
        fontSize={20}
        backgroundColor={'black'}
        onClick={() => createBlibraryContentHandle()}
        fontWeight={700}
        borderRadius={10}
      />
      <ModalSearchBookCurationForm
        visible={addCreatorBookModal}
        bookClickHandle={selectCreatorBookHandle}
        id={'curationSearch'}
      />
      <Loader isLoading={isLoading} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: 'jua';
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 0px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const BorderTop = styled.div`
  width: 100%;
  border-top: solid 2px ${GREY_LIGHT};
  margin-top: 40px;
  margin-bottom: 20px;
  opacity: 0.2;
`;

export default CreateCreator;
