import React from 'react';
// components
import WholesaleOrderListitem from './WholesaleOrderListitem';
// types
import { Book } from '../../../api/store/preparation';

interface Props {
  bookList: Book[];
}

export default function WholesaleOrderList({ bookList }: Props) {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <h5>도매처 책 주문 리스트</h5>
            </div>
          </div>
          <div className="table-responsive-xl">
            <table className="table table-hover" style={{ minWidth: 1300 }}>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>고객명</th>
                  <th style={{ width: '10%' }}>연락처</th>
                  <th style={{ width: '20%' }}>주소</th>
                  <th style={{ width: '10%' }}>출판사</th>
                  <th style={{ width: '20%' }}>책 제목</th>
                  <th style={{ width: '10%' }}>정가</th>
                  <th style={{ width: '20%' }}>ISBN</th>
                </tr>
              </thead>
              <tbody>
                {bookList.length > 0 &&
                  bookList.map(
                    (bookInfo) =>
                      bookInfo.bookPreparationStatus === 100 && (
                        <WholesaleOrderListitem
                          key={bookInfo.orderBookId}
                          bookInfo={bookInfo}
                        />
                      ),
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
