import { GREY_LIGHT } from '../../../../../styles/colors';
import React, { useState } from 'react';
import styled from 'styled-components';
import DatePickerForm from '../../../aComponents/DatePickerForm';
import TextInputForm from '../../../aComponents/TextInputForm';
import SelectMenuForm from '../../../aComponents/SelectMenuForm';
import useBannerAPI from 'api/operation/useBannerAPI';
import { landingTypeUrlList, landingTypeList } from '../../BannerConstant';
import ButtonComponent from 'components/common/ButtonComponent';
import { IoImageOutline } from 'react-icons/io5';
import { useHistory } from 'react-router';

const CreateBanner = () => {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [query, setQuery] = useState('');
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImagePath, setBannerImagePath] = useState(null);
  const [landingType, setLandingType] = useState<any>(null);
  const [landingTypeUrl, setLandingTypeUrl] = useState<any>(null);

  const { postBannerAPI } = useBannerAPI();
  const history = useHistory();

  const onChangeTitle = (text) => {
    const textValue = text.target.value;
    setTitle(textValue);
  };

  const onChangeImage = (fileObj: any) => {
    let filePath = fileObj.target.files[0];
    if (filePath !== undefined) {
      let reader = new FileReader();
      reader.onload = function (event: any) {
        setBannerImage(event.target.result);
        setBannerImagePath(filePath);
      };
      reader.readAsDataURL(filePath);
    }
  };

  const onChangeQuery = (text) => {
    const textValue = text.target.value;
    setQuery(textValue);
  };

  const imageClickHandle = () => {
    const element = document.getElementById('fileInput');
    element?.click();
  };

  const createBannerHandle = async () => {
    const landing_type = landingType?.value;
    const landing_page_url = landingTypeUrl?.value;

    const formData: any = new FormData();
    if (bannerImagePath) {
      formData.append('file', bannerImagePath);
    }
    try {
      await postBannerAPI({
        formData,
        title,
        landing_type,
        landing_page_url,
        query: query,
        post_start_date: new Date(startDate).getTime(),
        post_end_date: new Date(endDate).getTime(),
      });
    } catch (e) {
      console.log(e);
    } finally {
      history.push({ pathname: '/banner' });
    }
  };

  return (
    <Container>
      <BannerImageContainer>
        {bannerImage ? (
          <BannerImage
            id={'inputImage'}
            onClick={() => imageClickHandle()}
            src={bannerImage}
          />
        ) : (
          <NoneBannerImage onClick={() => imageClickHandle()}>
            <IoImageOutline size={60} color={GREY_LIGHT} />
          </NoneBannerImage>
        )}
        <BannerImageInput
          type="file"
          id="fileInput"
          accept=".jpg, .jpeg, .png"
          onChange={onChangeImage}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
      </BannerImageContainer>
      <Wrapper>
        <BorderTop />
        <TextInputForm
          onChange={onChangeTitle}
          title={'제목'}
          value={title}
          type={'text'}
          description={
            '- <Strong>비서재 양식</Strong><br/> 비서재_(번호)_(이름) / ex) 비서재_25_홍길동'
          }
        />
        <BorderTop />
        <TextInputForm
          onChange={onChangeQuery}
          title={'쿼리'}
          value={query}
          type={'text'}
          description={
            '- <Strong>비서재/큐레이션 양식</Strong><br/> {"curationId": (번호)} / ex) {"curationId": 25} <br/> <Strong>- 비서재/큐래이션 외에는 개발자와 논의 후 입력할 것!<Strong>'
          }
        />
        <BorderTop />
        <DatePickerForm
          title={'시작일자'}
          date={startDate}
          setDate={setStartDate}
        />
        <BorderTop />
        <DatePickerForm
          title={'완료일자'}
          date={endDate}
          setDate={setEndDate}
        />
        <BorderTop />
        <SelectMenuForm
          title={'랜딩 타입'}
          data={landingTypeList}
          selectType={landingType}
          setSelectType={setLandingType}
        />
        <BorderTop />
        <SelectMenuForm
          title={'랜딩 타입 Url'}
          data={landingTypeUrlList}
          selectType={landingTypeUrl}
          setSelectType={setLandingTypeUrl}
        />
        <BorderTop />
      </Wrapper>
      <ButtonWrapper>
        <ButtonComponent title={'생성'} onClick={() => createBannerHandle()} />
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
`;

const BannerImageContainer = styled.div`
  position: relative;
`;

const BannerImage = styled.img`
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  margin-top: 50px;
  cursor: pointer;
`;

const BannerImageInput = styled.input`
  position: absolute;
  opacity: 0;
  bottom: 30px;
  left: 0;
  z-index: -1;
`;

const NoneBannerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  margin-top: 50px;
  cursor: pointer;
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  margin-top: 50px;
  padding: 0px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const BorderTop = styled.div`
  width: 100%;
  border-top: solid 2px ${GREY_LIGHT};
  margin-top: 40px;
  margin-bottom: 20px;
  opacity: 0.2;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export default CreateBanner;
