import React, { Fragment } from 'react';
// common
import Breadcrumb from '../../common/breadcrumb';
// components
import StockHistoryList from './StockHistoryList';

export default function StockHistory() {
  return (
    <Fragment>
      <Breadcrumb title="재고 입출고 내역" parent="찾아가는 서점" />
      <div className="container-fluid">
        <StockHistoryList />
      </div>
    </Fragment>
  );
}
