import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
// styles
import Loader from 'components/common/loader';
import { X } from 'react-feather';
import * as colors from 'styles/colors';
// types
import { SetBookOrderCompletedErrorListItem } from 'api/store/delivery';

interface Props {
  isVisible: boolean;
  getBookPreparationList: any;
  failedDelivery: {
    isShow: boolean;
    errorList: SetBookOrderCompletedErrorListItem[];
  };
  close: any;
}

export default function SendFailModal({
  isVisible,
  failedDelivery,
  getBookPreparationList,
  close,
}: Props) {
  const [loading, setLoading] = useState(false);
  async function handleClose() {
    try {
      setLoading(true);
      await getBookPreparationList();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      close();
    }
  }

  const errorList = failedDelivery?.errorList || [];

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        content: {
          width: '50%',
          height: '70%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
        },
      }}
      onRequestClose={handleClose}
    >
      <Loader isLoading={loading} />
      <Container>
        <div
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 15,
            cursor: 'pointer',
          }}
        >
          <X />
        </div>
        <h2>알림톡 발송 실패 내역 - {errorList.length}건</h2>
        <ol>
          {errorList.length > 0
            ? errorList.map((e) => (
                <li key={String(e.orderId)}>
                  주문 번호 {e.orderId}번 - {e.receiverName}님
                </li>
              ))
            : null}
        </ol>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;

  h2 {
    color: ${colors.ORANGE};
    margin-bottom: 24px;
  }
  li {
    font-size: 20px;
    font-weight: 500px;
    margin-bottom: 6px;
  }
`;
