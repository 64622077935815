import styled from 'styled-components';
import React from 'react';

const Book = ({ id, title, author, book_image, publisher }) => {
  return (
    <Container>
      <ImageWrapper>
        <Image src={book_image} />
      </ImageWrapper>
      <Title>{title}</Title>
      <li>{author}</li>
      <li>{publisher}</li>
    </Container>
  );
};

const Container = styled.ul`
  width: 120px;
  margin-right: 30px;
`;

const ImageWrapper = styled.li``;

const Image = styled.img`
  width: 100px;
`;

const Title = styled.li`
  font-weight: 700;
`;

export default Book;
