import React, { useMemo } from 'react';
// styles
import { Table } from 'styles/index';

export default function ReturnHistory({ returnHistory }) {
  const columns = useMemo(
    () => [
      {
        Header: '히스토리ID',
        accessor: 'historyId',
      },
      {
        Header: '리턴ID',
        accessor: 'returnId',
      },
      {
        Header: '주문ID',
        accessor: 'orderId',
      },
      {
        Header: '유저ID',
        accessor: 'userId',
      },
      {
        Header: '픽업 일시',
        accessor: 'pickupDate',
      },
      {
        Header: '리턴 상태 코드',
        accessor: 'returnStatus',
      },
      {
        Header: '리턴 상태명',
        accessor: 'statusName',
      },
      {
        Header: '등록 일시',
        accessor: 'insertDate',
      },
      {
        Header: '최근 수정 일시',
        accessor: 'updateDate',
      },
      {
        Header: '삭제 여부',
        accessor: 'deleteYn',
      },
    ],
    [],
  );

  if (returnHistory.length === 0) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: 700,
          marginBottom: 10,
          paddingLeft: 5,
        }}
      >
        리턴 히스토리
      </div>
      <Table fullScreen columns={columns} data={returnHistory} />
    </div>
  );
}
