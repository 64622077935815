import axios from 'axios';
import useAPI from '../useAPI';
import { API_URL } from '../useAxios';

export default function useDetectionAPI() {
  const { getHandler } = useAPI();

  const postDetectionAPI = async (formData) => {
    try {
      const res = await axios.post(
        `http://13.209.7.113:5001/image_upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    postDetectionAPI,
  };
}
