// apis
import useAPI from 'api/useAPI';

// types
import { User, Tracking } from 'api/types';

export interface GetUsersRes {
  userList: User[];
  totalCnt: number;
}

export interface GetUserTrackingRes {
  trackingList: Tracking[];
  totalCnt: number;
}

export default function useUserAPI() {
  const { getHandler } = useAPI();

  async function getUsersAPI(params: {
    page: number;
    search?: string;
  }): Promise<GetUsersRes> {
    try {
      const res: any = await getHandler('/admin/users', params);
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getUserTrackingAPI(params: {
    userId: number;
    page: number;
  }): Promise<GetUserTrackingRes> {
    try {
      const res: any = await getHandler('/admin/user/tracking', params);

      return res.data?.result;
    } catch (error) {
      throw error;
    }
  }

  return {
    getUsersAPI,
    getUserTrackingAPI,
  };
}
