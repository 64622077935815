import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// api
import useStockAPI from '../../../api/store/useStockAPI';
// components
import StockListItem from './StockListItem';
// styles
import { Flex } from 'styles/index';
import Pagination from '@material-ui/lab/Pagination';
// types
import { Stock as StockType } from '../../../api/types';
import CreateStock from './CreateStock';

export default function Stock() {
  const sortOptions = [
    { name: '위치-이름순', key: 'location,name' },
    { name: '이름-위치순', key: 'name,location' },
  ];

  const stockOptionsMake = () => {
    let array: {}[] = [];
    for (let i = 0; i <= 20; i++) {
      array.push({ name: i, key: i });
    }
    return array;
  };

  const stockOptions = stockOptionsMake() || [{ name: 0, key: 0 }];

  const {
    getBookStockListAPI,
    updateStockQuantityAPI,
    updateStockLocationAPI,
  } = useStockAPI();

  const searchKeyword = useRef('');

  const [sort, setSort] = useState('location,name');
  const [stockList, setStockList] = useState<StockType[]>([]);
  const [locationList, setLocationList] = useState<string[]>([]);

  const [stockNumOption, setStockNumOption] = useState(0);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  console.log(locationList);
  // 리스트 호출
  async function handleGetBookStockList(e?: any) {
    if (e) {
      e.preventDefault();
    }
    try {
      const res = await getBookStockListAPI({
        search: searchKeyword.current || '',
        orderingOption: sort,
        page,
        stockNumOption,
      });
      if (res) {
        setStockList(res.stockList);
        setLocationList(res.locationList);
        setLastPage(res.lastPage);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // 재고수량 필터 옵션
  async function filterBookStockList(stockNum) {
    try {
      setStockNumOption(stockNum);
      const res = await getBookStockListAPI({
        search: searchKeyword.current || '',
        orderingOption: sort,
        page,
        stockNumOption: stockNum,
      });
      if (res) {
        setStockList(res.stockList);
        setLocationList(res.locationList);
        setLastPage(res.lastPage);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // 리스트 정렬
  async function sortBookStockList(newSort: string) {
    try {
      setSort(newSort);
      const res = await getBookStockListAPI({
        search: searchKeyword.current || '',
        orderingOption: newSort,
        page,
        stockNumOption,
      });
      if (res) {
        setStockList(res.stockList);
        setLocationList(res.locationList);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // 재고 수량 변경
  async function handleUpdateStockQuantity(
    stock: StockType,
    newQuantity: number,
  ) {
    try {
      await updateStockQuantityAPI({
        bookId: stock.bookId, // 책ID
        locationCode: stock.locationCode, // 책장 위치
        bookCondition: stock.bookCondition, // 책 상태
        changeQuantity: newQuantity, // 변경 수량
      });
    } catch (error) {
      console.error(error);
    }
  }

  // 재고 수량 변경
  async function handleUpdateStockLocation(
    stock: StockType,
    newLocation: string,
  ) {
    try {
      await updateStockLocationAPI({
        bookId: stock.bookId, // 책ID
        locationCodeBefore: stock.locationCode, // 변경 이전 위치
        locationCodeAfter: newLocation, // 변경 이후 위치
        bookCondition: stock.bookCondition, // 책 상태
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleGetBookStockList();
  }, [page]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <Flex
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h5>재고 관리 리스트</h5>
              <Flex>
                <CreateStock
                  locationList={locationList}
                  getBookListHandle={handleGetBookStockList}
                />

                <form onSubmit={handleGetBookStockList}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="검색"
                    onChange={(e) => {
                      searchKeyword.current = e.target.value;
                    }}
                    style={{ width: 300, height: 40, marginRight: 16 }}
                  />
                </form>
                <select
                  className="form-control digits"
                  name="selectStatus"
                  onChange={(e) => filterBookStockList(e.target.value)}
                  style={{ maxWidth: 200, height: 40 }}
                >
                  {stockOptions.map(({ key, name }: any) => (
                    <option key={key} value={key}>
                      {name}
                    </option>
                  ))}
                </select>
                <div style={{ marginRight: 10 }} />
                <select
                  className="form-control digits"
                  name="selectStatus"
                  onChange={(e) => sortBookStockList(e.target.value)}
                  style={{ maxWidth: 200, height: 40 }}
                >
                  {sortOptions.map((o) => (
                    <option key={o.key} value={o.key}>
                      {o.name}
                    </option>
                  ))}
                </select>
              </Flex>
            </Flex>
          </div>
          <div className="table-responsive">
            <CustomTable className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ width: '15%' }}>
                    위치
                  </th>
                  <th scope="col" style={{ width: '10%' }}>
                    책ID
                  </th>
                  <th scope="col" style={{ width: '10%' }}>
                    상태
                  </th>
                  <th scope="col" style={{ width: '10%' }}>
                    재고량
                  </th>
                  <th scope="col" style={{ width: '30%' }}>
                    책이름
                  </th>
                  <th scope="col" style={{ width: '15%' }}>
                    ISBN
                  </th>
                  <th scope="col" style={{ width: '10%' }}>
                    출고예정
                  </th>
                </tr>
              </thead>
              <tbody>
                {stockList.length > 0 &&
                  stockList.map((item, index) => (
                    <StockListItem
                      key={`${item.bookId}${index}`}
                      stockInfo={item}
                      locationList={locationList}
                      handleUpdateStockQuantity={handleUpdateStockQuantity}
                      handleUpdateStockLocation={handleUpdateStockLocation}
                    />
                  ))}
              </tbody>
            </CustomTable>
            <Flex style={{ justifyContent: 'center', paddingBottom: 20 }}>
              <StyledPagination
                count={lastPage}
                onChange={(_, page) => setPage(page)}
              />
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
}

const CustomTable = styled.table`
  th {
    white-space: nowrap;
  }
`;
const StyledPagination = styled(Pagination)`
  button {
    outline: none;
  }
`;
