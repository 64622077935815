import { toastify } from 'utils/common';
// apis
import useAPI from '../useAPI';

export default function useOrderAPI() {
  const { postHandler } = useAPI();

  async function forcePurchaseAPI(params: {
    orderId: number;
    userId: number;
  }): Promise<{}> {
    try {
      const res: any = await postHandler('/admin/store/purchase', params);
      return res?.data?.result;
    } catch (error) {
      const errorMessage = error?.response?.data?.errorMessage;
      if (errorMessage) {
        toastify('error', errorMessage);
      }
      throw error;
    }
  }

  return {
    forcePurchaseAPI,
  };
}
