import useAPI from '../useAPI';

export default function useFunnelAPI() {
  const { getHandler } = useAPI();

  const getFunnelOptionData = async () => {
    try {
      const res: any = await getHandler('/admin/table-code');
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  //   ?funnelList=Home,Book,Home
  const getFunnelData = async (params) => {
    try {
      const res: any = await getHandler('/admin/dashboard/page-funnel', params);
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  return {
    getFunnelOptionData,
    getFunnelData,
  };
}
