// apis
import useAPI from '../useAPI';
// types
import { Book } from '../types';

export interface SearchBookParams {
  bookName: string;
  size: number; // 조회 개수
  maxScore: string[]; // 마지막 스코어를 첨부하여 다음 데이터 받아오기
}
export interface SearchBookRes {
  totalCount: number;
  maxScore: string[];
  bookList: Book[];
}

export default function useSearchAPI() {
  const { postHandler } = useAPI();

  async function searchBooksAPI(
    params: SearchBookParams,
  ): Promise<SearchBookRes> {
    try {
      const res: any = await postHandler('/search/books', params);
      return res.data.result;
    } catch (error) {
      throw error;
    }
  }

  return {
    searchBooksAPI,
  };
}
