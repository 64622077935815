import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import SelectBookCurationForm from './BookCurationListForm';

interface Props {
  title: string;
  data: any;
  onClick: () => void;
  cancelBookHandle: (id: string) => void;
}

const RegistBookForm: FunctionComponent<Props> = ({
  title,
  data,
  onClick,
  cancelBookHandle,
}) => {
  return (
    <FormWrapper>
      <FormText>{title}</FormText>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ButtonWrapper onClick={() => onClick()}>
          <IoIosAddCircleOutline size={30} color={'white'} />
        </ButtonWrapper>
        <BookListContainer>
          {data.length > 0
            ? data.map((book) => (
                <SelectBookCurationForm
                  key={book.bookId}
                  book={book}
                  cancelBookHandle={cancelBookHandle}
                />
              ))
            : null}
        </BookListContainer>
      </div>
    </FormWrapper>
  );
};

const FormWrapper = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 100px;
`;

const FormText = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 30vw;
  height: 50px;
  background-color: black;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
`;

const BookListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export default RegistBookForm;
