import React from 'react';
// styles
import { Table } from 'styles/index';

interface Props {
  dataList: any[];
}
function ConversionDetailList({ dataList }: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: '전환 대상',
        accessor: 'target',
      },
      {
        Header: 'PV',
        accessor: 'pv',
      },
      {
        Header: 'UV',
        accessor: 'uv',
      },
      {
        Header: 'unique_user_item ',
        accessor: 'unique_user_item',
      },
    ],
    [],
  );

  return (
    <div style={{ marginLeft: 40 }}>
      <Table columns={columns} data={dataList} hasMoreButton />
    </div>
  );
}

export default ConversionDetailList;
