import React from 'react';
// components
import PieChart from './PieChart';
import ConversionList from './ConversionList';
// styles
import * as colors from 'styles/colors';
// types
import { DayConversionAnalysis } from 'api/dashboard/useConversionAPI';

interface Props {
  type: string;
  dayConversion: DayConversionAnalysis;
}
export default function ConversionAnalysisCard({ type, dayConversion }: Props) {
  const UV_DATA = dayConversion.uvList.map((d) => {
    return {
      name: `${d.listType} - ${d.listName}`,
      uv: d.uv,
    };
  });

  const PV_DATA = dayConversion.pvList.map((d) => {
    return {
      name: `${d.listType} - ${d.listName}`,
      pv: d.pv,
    };
  });

  const LIST_DATA = dayConversion.dataList.map((d) => {
    return {
      target: `${d.listType} - ${d.listName}`,
      ...d,
    };
  });

  return (
    <div
      style={{
        flex: 1,
        padding: '32px 24px 32px 24px',
        backgroundColor: '#fff',
        borderRadius: 20,
        marginBottom: 20,
      }}
    >
      <h4
        style={{ marginBottom: 8, color: colors.GREY_DARK }}
      >{`<${type}>`}</h4>
      <div style={{ flex: 1, display: 'flex' }}>
        <PieChart data={UV_DATA} dataKey="uv" title="UV 비율" />
        <PieChart
          data={PV_DATA}
          dataKey="pv"
          title="PV 비율"
          colorType="cool"
        />
        <ConversionList dataList={LIST_DATA} />
      </div>
    </div>
  );
}
