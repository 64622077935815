export function copyStringToClipboard(str: string) {
  // Create new element
  const el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}

export const handleCheckBookStatus = (bookCondition: number) => {
  if (bookCondition === 0) return '새책';
  if (bookCondition === 1) return '중고최상';
  if (bookCondition === 2) return '중고상';
  if (bookCondition === 3) return '중고중';
  return '';
};
