import axios from 'axios';

const mode = localStorage.getItem('mode');

export const API_URL =
  mode === 'test'
    ? 'http://52.78.233.205:5000'
    : 'https://bibly.ryencatchers.co.kr';

export const VERSION = '0.3.3';

// GET API
export const apiGet = (url: string, params?: any): Promise<any> => {
  return axios({
    url: `${API_URL}/${url}`,
    method: 'GET',
    params: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(async function (error) {
      console.error('apiGet :: ', error);
      throw error;
    });
};

// POST API
export const apiPost = (url: string, data: any): Promise<any> => {
  return axios({
    url: `${API_URL}/${url}`,
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(async function (error) {
      console.error('apiGet :: ', error);
      throw error;
    });
};

// PUT API
export const apiPut = (url: string, data: any): Promise<any> => {
  return axios({
    url: `${API_URL}/${url}`,
    method: 'PUT',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(async function (error) {
      console.error('apiPut :: ', error);
      throw error;
    });
};

// PATCH API
export const apiPatch = (url: string, data: any): Promise<any> => {
  return axios({
    url: `${API_URL}/${url}`,
    method: 'PATCH',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(async function (error) {
      console.error('apiPatch :: ', error);
      throw error;
    });
};

// DELETE API
export const apiDelete = (url: string, data: any): Promise<any> => {
  return axios({
    url: `${API_URL}/${url}`,
    method: 'DELETE',
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(async function (error) {
      console.error('apiDelete :: ', error);
      throw error;
    });
};
