import axios from 'axios';
import { API_URL } from './useAxios';

export default function useAPI() {
  async function getHandler(url: string, params?: any, headers?: any) {
    try {
      const res = await axios({
        method: 'get',
        url: `${API_URL}${url}`,
        params,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  async function deleteHandler(url: string, data?: any, headers?: any) {
    try {
      const res = await axios({
        method: 'delete',
        url: `${API_URL}${url}`,
        data: data ? data : {},
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  async function postHandler(url: string, data: any, headers?: any) {
    let header = {
      'Content-Type': 'application/json',
      ...headers,
    };

    try {
      const res = await axios({
        method: 'post',
        url: `${API_URL}${url}`,
        data,
        headers: headers ? headers : header,
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  async function putHandler(url: string, data: any, headers?: any) {
    try {
      const res = await axios({
        method: 'put',
        url: `${API_URL}${url}`,
        data,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  async function patchHandler(url: string, data: any, headers?: any) {
    try {
      const res = await axios({
        method: 'patch',
        url: `${API_URL}${url}`,
        data,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  async function uploadHandler(url: string, formData: any, headers?: any) {
    try {
      const res = await axios({
        method: 'post',
        url: `${API_URL}${url}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...headers,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  return {
    getHandler,
    deleteHandler,
    postHandler,
    putHandler,
    patchHandler,
    uploadHandler,
  };
}
