import { createGlobalStyle } from 'styled-components';
import jua from '../assets/fonts/jua_otf.otf';

const globalStyles = createGlobalStyle`
    a {
        text-decoration:none;
        color: inherit;
    }
    * {
        box-sizing: border-box;
    }
    body {
        color:black;
        background-color: #FEFEFE;
    }
    @font-face {
        font-family: "jua";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(${jua});
    }
`;

export default globalStyles;
