import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_DARK } from 'styles/colors';

interface Props {
  title: string;
  data: string | number;
}

const TrafficComponent: FunctionComponent<Props> = ({ title, data }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <ResultBoxContainer>
        <DataText>{data}</DataText>
      </ResultBoxContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 10.5vw;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  padding-left: 20px;
  color: ${GREY_DARK};
  font-size: 17px;
`;

const ResultBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  margin-top: 10px;
  cursor: pointer;
`;

const DataText = styled.div`
  font-size: 20px;
  padding: 20px 0 20px 0;
`;

export default TrafficComponent;
