import React from 'react';
import SelectMonthForm from '../aCommons/SelectMonthForm';
import SelectWeekForm from '../aCommons/SelectWeekForm';
import SelectDateForm from '../aCommons/SelectDateForm';
import KPIComponent from './KPIComponent';
import styled from 'styled-components';

const SelectDateSection = ({
  dateType,
  date,
  getData,
  maxDate,
  text,
  data,
  type,
}) => {
  const {
    uniqueVisit: { uniqueVisitSum: uv, results: uniqueVisitDailyData },
    newUser: { newUserSum: newUser, results: newUserDailyData },
    newOrder: { newOrderSum: newOrder, results: newOrderDailyData },
  } = data;

  const dataList = [
    { type: 'UV', title: 'UV(人)', count: uv, dailyData: uniqueVisitDailyData },
    {
      type: 'NewUser',
      title: '신규유입 (人)',
      count: newUser,
      dailyData: newUserDailyData,
    },
    {
      type: 'OrderData',
      title: '신규주문 (人)',
      count: newOrder,
      dailyData: newOrderDailyData,
    },
  ];

  return (
    <Container>
      {dateType === '월별' ? (
        <SelectMonthForm
          date={date}
          getData={getData}
          maxDate={maxDate}
          text={text}
          type={type}
        />
      ) : dateType === '주별' ? (
        <SelectWeekForm
          date={date}
          getData={getData}
          maxDate={maxDate}
          text={text}
          type={type}
        />
      ) : (
        <SelectDateForm
          date={date}
          getData={getData}
          maxDate={maxDate}
          text={text}
          type={type}
        />
      )}

      <DataListWrapper>
        {dataList !== null
          ? dataList.map(({ title, count, dailyData }) => (
              <KPIComponent
                title={title}
                data={count}
                dailyData={dailyData === undefined ? [] : dailyData}
              />
            ))
          : null}
      </DataListWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const DataListWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  width: 94%;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
  background-color: white;
  padding: 20px 0 20px 0;
  margin-top: 20px;
  justify-content: space-around;
`;

export default SelectDateSection;
