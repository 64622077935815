import React, { Fragment, useState } from 'react';
// components
import Breadcrumb from '../../common/breadcrumb';
import OrderStatusCodeList from './OrderStatusCodeList';
import Orders from './Orders';
import OrderDetail from './OrderDetail';
// styles
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// types
import { OrderStatusCode, Order as OrderType } from 'api/types';
import SearchKeyword from './SearchKeyword';
import useOrderAPI from 'api/store/useOrderAPI';
import { copyStringToClipboard } from 'lib/tools';
import { toastify } from '../../../utils/common';

export default function Order() {
  const [deleteIncludeYn, setDeleteIncludeYn] = useState(0);
  const [selectedOrderStatusCode, setSelectedOrderStatusCode] =
    useState<OrderStatusCode | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<OrderType | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const { getExportOrderListAPI, getOrdersAPI } = useOrderAPI();

  const phoneNumberOnChange = (e) => {
    const text = e.target.value;
    setSearchKeyword(text);
  };

  const handleCheckDeleteInclude = () => {
    if (Boolean(deleteIncludeYn)) {
      setDeleteIncludeYn(0);
    } else {
      setDeleteIncludeYn(1);
    }
  };

  const getExportOrderListHandle = async () => {
    if (searchKeyword.length > 0) {
      const orderListData: any = await getExportOrderListAPI({
        deleteIncludeYn,
        orderStatusCode: selectedOrderStatusCode?.orderStatus || 0,
        search: searchKeyword,
      });
      let result = '';
      for (let i = 0; i < orderListData.length; i++) {
        const l = orderListData[i];
        if (i === 0) {
          for (var prop in l) {
            if (prop === 'category4') {
              result += `${prop}\n`;
            } else {
              result += `${prop}\t`;
            }
          }
          for (var prop in l) {
            if (prop === 'category4') {
              result += `${l[prop]}\n`;
            } else {
              result += `${l[prop]}\t`;
            }
          }
        } else {
          for (var prop in l) {
            if (prop === 'category4') {
              result += `${l[prop]}\n`;
            } else {
              result += `${l[prop]}\t`;
            }
          }
        }
      }
      copyStringToClipboard(result);
      toastify('success', '복사가 완료되었습니다.');
    } else {
      toastify('error', '키워드를 입력해주세요.');
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="주문 리스트" parent="찾아가는 서점" />
      <div style={{ padding: '0px 15px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(deleteIncludeYn)}
                onChange={handleCheckDeleteInclude}
                name="deleteIncludeYn"
                color="primary"
              />
            }
            label="주문 취소 포함"
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <SearchKeyword
              value={searchKeyword}
              onChange={phoneNumberOnChange}
              getExportOrderListHandle={getExportOrderListHandle}
            />
          </div>
        </div>
        <OrderStatusCodeList
          deleteIncludeYn={deleteIncludeYn}
          selectedOrderStatusCode={selectedOrderStatusCode}
          setSelectedOrderStatusCode={setSelectedOrderStatusCode}
        />
        <Orders
          deleteIncludeYn={deleteIncludeYn}
          selectedOrderStatusCode={selectedOrderStatusCode}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          searchKeyword={searchKeyword}
        />
        <OrderDetail
          selectedOrderStatusCode={selectedOrderStatusCode}
          deleteIncludeYn={deleteIncludeYn}
          selectedOrder={selectedOrder}
        />
      </div>
    </Fragment>
  );
}
