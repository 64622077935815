import styled, { css } from 'styled-components';
import React, { FunctionComponent, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';

interface Props {
  bannerId: number;
  title?: string;
  bannerImage: string;
  bannerType: string;
  delete_yn: number;
  detailImage: string;
  landingPageUrl: string;
  landingType: string;
  postEndDate: string;
  postStartDate: string;
  priority: number;
  query: string;
  selectLandingType: any;
  pickDate: Date;
  deleteCheck: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

const BannerInfo: FunctionComponent<Props> = ({
  bannerId,
  title,
  bannerImage,
  bannerType,
  delete_yn,
  detailImage,
  landingPageUrl,
  landingType,
  postEndDate,
  postStartDate,
  priority,
  query,
  selectLandingType,
  pickDate,
  deleteCheck,
  createdAt,
  updatedAt,
}) => {
  const landingTypeVisible =
    (selectLandingType && selectLandingType.value === '전체') ||
    (selectLandingType && selectLandingType.value === landingPageUrl);

  const year = new Date(postStartDate).getFullYear();
  const month = new Date(postStartDate).getMonth();
  const day = new Date(postStartDate).getDate();
  const startDateTimeStamp = new Date(year, month, day, 0, 0).getTime();
  const endDateTimeStamp = new Date(postEndDate).getTime();
  const pickedTimeStamp = new Date(pickDate).getTime();

  const selectDateVisible =
    pickedTimeStamp >= startDateTimeStamp &&
    pickedTimeStamp <= endDateTimeStamp;

  const deleteVisible = !deleteCheck ? (delete_yn === 0 ? true : false) : true;

  let history = useHistory();

  const bannderClickHandle = () => {
    if (bannerId) {
      history.push({
        pathname: `/banner/${bannerId}`,
      });
    }
  };

  return landingTypeVisible && selectDateVisible && deleteVisible ? (
    <BannerWrapper onClick={() => bannderClickHandle()}>
      <BannerImage src={bannerImage} delete={delete_yn} />
      <Title delete={delete_yn}>{title}</Title>
      <PeriodContainer delete={delete_yn}>
        <PeriodDateText>
          {moment(postStartDate).format('YYYY-MM-DD')}
        </PeriodDateText>
        <PeriodDateText>&nbsp; ~ &nbsp;</PeriodDateText>
        <PeriodDateText>
          {moment(postEndDate).format('YYYY-MM-DD')}
        </PeriodDateText>
      </PeriodContainer>
    </BannerWrapper>
  ) : null;
};

const BannerWrapper = styled.div`
  cursor: pointer;
`;

interface BannerTypeProps {
  delete: number;
}

const BannerImage = styled.img<BannerTypeProps>`
  width: 200px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  ${(props) =>
    props.delete === 1 &&
    css`
      opacity: 0.3;
    `}
`;

interface TitleProps {
  delete: number;
}

const Title = styled.div<TitleProps>`
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  ${(props) =>
    props.delete === 1 &&
    css`
      opacity: 0.3;
    `}
`;

interface PeriodContainerProps {
  delete: number;
}

const PeriodContainer = styled.div<PeriodContainerProps>`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  ${(props) =>
    props.delete === 1 &&
    css`
      opacity: 0.3;
    `}
`;

const PeriodDateText = styled.div`
  font-size: 15px;
`;

export default BannerInfo;
