import styled from 'styled-components';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';

const MonthPickComponent = ({ text, date, maxDate, getData, type }) => {
  const handleAMonthChange = (date) => {
    getData(new Date(date).getTime());
  };

  return (
    <Container>
      <Text>{text}</Text>
      <DatePicker
        locale={ko} // 달력 한글화
        autoComplete="off"
        dateFormat={'yyyy.MM'}
        selected={date}
        showMonthYearPicker={true}
        disabled={false}
        className={`form-control center`}
        openToDate={date}
        value={date}
        maxDate={new Date(maxDate)}
        onChange={(value) => handleAMonthChange(value)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  background-color: white;
  padding: 20px 0 20px 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
  margin-top: 20px;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

export default MonthPickComponent;
