import React from 'react';
// styles
import { Table } from 'styles/index';
// types
import { EmployeeBookOrderStatic } from 'api/types';

interface Props {
  employees: EmployeeBookOrderStatic[];
}
function EmployeeList({ employees }: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: '주문직원명',
        accessor: 'ordererName',
      },
      {
        Header: '주문 권수',
        accessor: 'orderCnt',
      },
      {
        Header: '합산 가격',
        accessor: 'sumPrice',
      },
      {
        Header: '잔액',
        accessor: 'balance',
      },
    ],
    [],
  );

  return (
    <Table
      fullScreen
      columns={columns}
      data={employees}
      style={{ marginBottom: 20 }}
    />
  );
}

export default EmployeeList;
