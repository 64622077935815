import styled from 'styled-components';
import useBlibraryAPI from 'api/operation/useBlibraryAPI';
import React, { useEffect, useState } from 'react';
import { BlibraryInfluencer } from '../../../api/types';
import BlibraryInfluencerInfo from './aComponents/BlibraryInfluencerInfo';
import { IoMdAdd } from 'react-icons/io';
import { BLACK_PRIMARY } from 'styles/colors';
import { useHistory } from 'react-router';
import Breadcrumb from 'components/common/breadcrumb';

const BlibraryPresenter = () => {
  const [blibraryInfluencerList, setBlibraryInfluencerList] = useState([]);
  const { getBlibraryInfluencerAPI, getBlibraryCurationAPI } = useBlibraryAPI();
  const history = useHistory();

  const getBlibratyCuration = async () => {
    await getBlibraryCurationAPI();
  };

  const getBlibraryInfluencer = async () => {
    try {
      const result = await getBlibraryInfluencerAPI();
      setBlibraryInfluencerList(result);
    } catch (e) {
      console.log(e);
    }
  };

  const createInfluencerHandle = () => {
    history.push({ pathname: '/blibrary/create-influencer' });
  };

  useEffect(() => {
    getBlibratyCuration();
    getBlibraryInfluencer();
  }, []);

  return (
    <>
      <HeaderWrapper>
        <>
          <Breadcrumb title="비서재 관리" parent="운영" />
          <HeaderTabContainer onClick={() => createInfluencerHandle()}>
            <IoMdAdd size={30} color={'white'} />
          </HeaderTabContainer>
        </>
      </HeaderWrapper>
      <Container>
        <BlibraryInfluencerContainer>
          {blibraryInfluencerList.length > 0
            ? blibraryInfluencerList.map((influencer: BlibraryInfluencer) => (
                <BlibraryInfluencerInfo
                  key={influencer.authorId}
                  {...influencer}
                />
              ))
            : null}
        </BlibraryInfluencerContainer>
      </Container>
    </>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderTabContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: ${BLACK_PRIMARY};
  border-radius: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-top: 20px;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px 0 20px 0px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
`;

const BlibraryInfluencerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  justify-content: space-between;
  grid-gap: 20px;
`;

export default BlibraryPresenter;
