import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BLACK_PRIMARY } from 'styles/colors';

interface Props {
  title: string | null;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  borderRadius?: number;
  fontSize?: number;
  fontWeight?: number;
  onClick: () => void;
}

const ButtonComponent: FunctionComponent<Props> = ({
  title,
  width = 130,
  height = 45,
  backgroundColor = BLACK_PRIMARY,
  color = 'white',
  borderRadius = 10,
  fontSize = 17,
  fontWeight = 700,
  onClick,
}) => {
  return (
    <Container
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      color={color}
      borderRadius={borderRadius}
      fontSize={fontSize}
      fontWeight={fontWeight}
      onClick={() => onClick()}
    >
      {title}
    </Container>
  );
};

interface ContainerProps {
  width: number;
  height: number;
  backgroundColor: string;
  color: string;
  borderRadius: number;
  fontSize: number;
  fontWeight: number;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.borderRadius}px;
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight}px;
  cursor: pointer;
`;

export default ButtonComponent;
