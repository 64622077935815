import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import SelectDateForm from './commons/SelectDateForm';
import SelectOption from './commons/SelectOption';
import ModalComponent from '../../common/ModalComponent';
import {
  IoCloseSharp,
  IoSettingsSharp,
  IoChevronUpOutline,
} from 'react-icons/io5';
import useFunnelAPI from 'api/dashboard/useFunnelAPI';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import Loader from 'components/common/loader';
import FunnelDataView from './Components/FunnelDataView';
import moment from 'moment';
import { BLACK_PRIMARY } from 'styles/colors';

const OptionContainerForm = ({ children, title }) => {
  return (
    <OptionContainer>
      <OptionTitle>{title}</OptionTitle>
      <OptionWrapper>{children}</OptionWrapper>
    </OptionContainer>
  );
};

const OptionContainer = styled.div`
  width: 500px;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  div {
    margin-right: 10px;
  }
`;

const OptionTitle = styled.h5`
  color: gray;
  opacity: 0.6;
  margin: 10px 0 0 10px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
`;

const FunnelPresenter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [funnelOptions, setFunnelOptions] = useState({
    startDate: null,
    endDate: null,
    marketingPath: { value: '', label: '입력하지 않음' },
    pageType: { value: null, label: null },
    startSignedDate: null,
    endSignedDate: null,
    gender: { value: null, label: '전체' },
    age: { value: null, label: '전체' },
    occupation: { value: null, label: '전체' },
  });

  const [optionVisible, setOptionVisible] = useState(false);

  const [event, setEvent] = useState(null);
  const { getFunnelOptionData, getFunnelData } = useFunnelAPI();

  const basicTableData = [
    {
      type: 'columnType',
      data: { header: '페이지뷰', firstData: '유저', secondData: '이벤트' },
    },
  ];
  const [tableData, setTableData] = useState<any>(basicTableData);

  const [modalVisible, setModalVisible] = useState(false);

  const [optionData, setOptionData] = useState({
    tracking: { pageView: null, userAction: null },
    user: { age: null, gender: null, occupation: null },
  });

  const { user, tracking } = optionData;

  const selectStartDate = (timeStamp) => {
    setFunnelOptions({ ...funnelOptions, startDate: timeStamp });
  };

  const selectEndDate = (timeStamp) => {
    setFunnelOptions({ ...funnelOptions, endDate: timeStamp });
  };

  const selectPageType = async (selectedOption) => {
    setFunnelOptions({ ...funnelOptions, pageType: selectedOption });
    setModalVisible(!modalVisible);

    const {
      startDate,
      endDate,
      pageType,
      startSignedDate,
      endSignedDate,
      gender,
      age,
      occupation,
    } = funnelOptions;

    let combinedEvent;
    if (event) {
      combinedEvent = `${event},${selectedOption.value}`;
    } else {
      combinedEvent = selectedOption.value;
    }

    setEvent(combinedEvent);

    const result = await getFunnelData({
      funnelList: combinedEvent,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      signInStartDate: startSignedDate
        ? moment(startSignedDate).format('YYYY-MM-DD')
        : null,
      signInEndDate: endSignedDate
        ? moment(endSignedDate).format('YYYY-MM-DD')
        : null,
      age: age.value,
      gender: gender.value,
      occupation: occupation.value,
    });
    setTableData([tableData[0], result]);
    window.scrollTo({ top: 100000, behavior: 'smooth' });
  };

  const resetFunnelData = () => {
    setTableData(basicTableData);
    setEvent(null);
  };

  const selectStartSignedDate = (timeStamp) => {
    setFunnelOptions({ ...funnelOptions, startSignedDate: timeStamp });
  };

  const selectMarketingPath = (selectedOption) => {
    setFunnelOptions({ ...funnelOptions, marketingPath: selectedOption });
  };

  const selectEndSignedDate = (timeStamp) => {
    setFunnelOptions({ ...funnelOptions, endSignedDate: timeStamp });
  };

  const selectGender = (selectedOption) => {
    setFunnelOptions({ ...funnelOptions, gender: selectedOption });
  };

  const selectAge = (selectedOption) => {
    setFunnelOptions({ ...funnelOptions, age: selectedOption });
  };

  const selectOccupation = (selectedOption) => {
    setFunnelOptions({ ...funnelOptions, occupation: selectedOption });
  };

  const getFunnelOptionDataHandle = async () => {
    setIsLoading(true);
    const result = await getFunnelOptionData();
    setOptionData(result);
    setIsLoading(false);
  };

  const addFunnelTab = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    getFunnelOptionDataHandle();
  }, []);

  return isLoading ? null : (
    <div style={{ paddingTop: 5, paddingBottom: 30 }}>
      {optionVisible ? (
        <SlideDown className={'my-dropdown-slidedown'}>
          <Container>
            <div
              style={{
                position: 'absolute',
                top: 20,
                left: 20,
                fontWeight: 700,
                color: 'gray',
              }}
            >
              Option
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', marginTop: 70 }}
            >
              <OptionContainerForm title={'산정기간'}>
                <SelectDateForm
                  date={funnelOptions.startDate}
                  maxDate={new Date()}
                  getData={selectStartDate}
                />
                <div>~ &nbsp;</div>
                <SelectDateForm
                  date={funnelOptions.endDate}
                  maxDate={new Date()}
                  getData={selectEndDate}
                />
              </OptionContainerForm>
              <div style={{ marginLeft: 30 }} />
              <OptionContainerForm title={'유입경로'}>
                <SelectOption
                  defaultValue={funnelOptions.gender}
                  data={user.gender}
                  selectType={funnelOptions.gender}
                  onChange={selectGender}
                  width={350}
                />
              </OptionContainerForm>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}
            >
              <OptionContainerForm title={'가입일자'}>
                <SelectDateForm
                  date={funnelOptions.startSignedDate}
                  maxDate={new Date()}
                  getData={selectStartSignedDate}
                />
                <SelectDateForm
                  date={funnelOptions.endSignedDate}
                  maxDate={new Date()}
                  getData={selectEndSignedDate}
                />
              </OptionContainerForm>
              <div style={{ marginLeft: 30 }} />
              <OptionContainerForm title={'유저정보'}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <SelectOption
                    defaultValue={funnelOptions.gender}
                    data={user.age}
                    selectType={funnelOptions.gender}
                    onChange={selectGender}
                    width={150}
                  />
                  <SelectOption
                    defaultValue={funnelOptions.age}
                    data={user.gender}
                    selectType={funnelOptions.age}
                    onChange={selectAge}
                    width={150}
                  />
                  <SelectOption
                    defaultValue={funnelOptions.occupation}
                    data={user.occupation}
                    selectType={funnelOptions.occupation}
                    onChange={selectOccupation}
                    width={150}
                  />
                </div>
              </OptionContainerForm>
            </div>
            <div
              style={{ marginTop: 50, cursor: 'pointer' }}
              onClick={() => setOptionVisible(!optionVisible)}
            >
              <IoChevronUpOutline size={30} />
            </div>
          </Container>
        </SlideDown>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <OptionNullContainer onClick={() => setOptionVisible(!optionVisible)}>
            <IoSettingsSharp size={20} color={'white'} />
          </OptionNullContainer>
        </div>
      )}

      <FunnelDataView
        tableData={tableData}
        clickPlusButtonHandle={addFunnelTab}
        resetFunnelDataHandle={resetFunnelData}
      />
      <ModalComponent
        visible={modalVisible}
        width={500}
        height={300}
        backgroundColor={'white'}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: 100,
            height: 250,
            zIndex: 99,
          }}
        >
          <div style={{ marginBottom: 30, fontWeight: 700, fontSize: 17 }}>
            Tracking 옵션을 선택 해주세요.
          </div>
          <SelectOption
            defaultValue={funnelOptions.pageType}
            data={tracking.pageView}
            selectType={funnelOptions.pageType}
            onChange={selectPageType}
            width={300}
          />
        </div>
        <CloseModal onClick={() => setModalVisible(!modalVisible)}>
          <IoCloseSharp color={'black'} size={30} />
        </CloseModal>
      </ModalComponent>
      <Loader isLoading={isLoading} />
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin-top: 30px;
  padding: 0px 0 30px 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const OptionNullContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  margin-top: 30px;
  margin-right: 10px;
  background-color: ${BLACK_PRIMARY};
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  cursor: pointer;
`;

const CloseModal = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;

export default FunnelPresenter;
