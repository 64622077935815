import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import SelectDatePick from './aComponents/SelectDatePick';
import styled from 'styled-components';
import useKPIDataAPI from 'api/dashboard/useKPIDataAPI';
import ButtonComponent from 'components/common/ButtonComponent';
import Retiontion from './aComponents/Retention';
import Loader from 'components/common/loader';
import ChardData from './aComponents/ChartData';
import TrafficComponent from './aCommons/TrafficComponent';

const TrafficKPIPresenter = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const date = today.getDate();
  const todayTimestamp = new Date(year, month, date).getTime();
  const beforeMonthDate = moment(todayTimestamp).subtract(1, 'months').toDate();

  const [isLoading, setIsLoading] = useState(false);
  const [trafficData, setTrafficData] = useState({
    retention: [
      {
        date: 'Mon, 08 Mar 2021 00:00:00 GMT',
        date_week: '2021-10',
        cnt: 316,
        w0: '301',
        w1: '45',
        w2: '29',
        w3: '27',
        w4: '26',
        w5: '19',
        w6: '0',
      },
      {
        date: 'Mon, 15 Mar 2021 00:00:00 GMT',
        date_week: '2021-11',
        cnt: 320,
        w0: '296',
        w1: '45',
        w2: '22',
        w3: '22',
        w4: '15',
        w5: '0',
        w6: '0',
      },
      {
        date: 'Mon, 22 Mar 2021 00:00:00 GMT',
        date_week: '2021-12',
        cnt: 383,
        w0: '367',
        w1: '54',
        w2: '37',
        w3: '18',
        w4: '2',
        w5: '0',
        w6: '0',
      },
      {
        date: 'Mon, 29 Mar 2021 00:00:00 GMT',
        date_week: '2021-13',
        cnt: 287,
        w0: '280',
        w1: '52',
        w2: '28',
        w3: '5',
        w4: '0',
        w5: '0',
        w6: '0',
      },
      {
        date: 'Mon, 05 Apr 2021 00:00:00 GMT',
        date_week: '2021-14',
        cnt: 113,
        w0: '111',
        w1: '14',
        w2: '0',
        w3: '0',
        w4: '0',
        w5: '0',
        w6: '0',
      },
      {
        date: 'Mon, 12 Apr 2021 00:00:00 GMT',
        date_week: '2021-15',
        cnt: 166,
        w0: '164',
        w1: '3',
        w2: '0',
        w3: '0',
        w4: '0',
        w5: '0',
        w6: '0',
      },
    ],
    orderPerUV: {
      populationSum: 11073,
      sampleSum: 165,
      totalRatio: 0.014901110810078569,
      result: [
        {
          dateColumn: '2021-04-21',
          population: 48,
          sample: 3,
          ratio: '0.063',
        },
        {
          dateColumn: '2021-04-20',
          population: 230,
          sample: 6,
          ratio: '0.026',
        },
        {
          dateColumn: '2021-04-19',
          population: 230,
          sample: 6,
          ratio: '0.026',
        },
        {
          dateColumn: '2021-04-18',
          population: 210,
          sample: 1,
          ratio: '0.005',
        },
        {
          dateColumn: '2021-04-17',
          population: 229,
          sample: 3,
          ratio: '0.013',
        },
        {
          dateColumn: '2021-04-16',
          population: 329,
          sample: 3,
          ratio: '0.009',
        },
        {
          dateColumn: '2021-04-15',
          population: 504,
          sample: 5,
          ratio: '0.010',
        },
        {
          dateColumn: '2021-04-14',
          population: 314,
          sample: 5,
          ratio: '0.016',
        },
        {
          dateColumn: '2021-04-13',
          population: 233,
          sample: 8,
          ratio: '0.034',
        },
        {
          dateColumn: '2021-04-12',
          population: 238,
          sample: 4,
          ratio: '0.017',
        },
        {
          dateColumn: '2021-04-11',
          population: 191,
          sample: 2,
          ratio: '0.010',
        },
        {
          dateColumn: '2021-04-10',
          population: 238,
          sample: 3,
          ratio: '0.013',
        },
        {
          dateColumn: '2021-04-09',
          population: 518,
          sample: 9,
          ratio: '0.017',
        },
        {
          dateColumn: '2021-04-08',
          population: 463,
          sample: 6,
          ratio: '0.013',
        },
        {
          dateColumn: '2021-04-07',
          population: 275,
          sample: 2,
          ratio: '0.007',
        },
        {
          dateColumn: '2021-04-06',
          population: 278,
          sample: 5,
          ratio: '0.018',
        },
        {
          dateColumn: '2021-04-05',
          population: 297,
          sample: 7,
          ratio: '0.024',
        },
        {
          dateColumn: '2021-04-04',
          population: 230,
          sample: 3,
          ratio: '0.013',
        },
        {
          dateColumn: '2021-04-03',
          population: 237,
          sample: 5,
          ratio: '0.021',
        },
        {
          dateColumn: '2021-04-02',
          population: 352,
          sample: 5,
          ratio: '0.014',
        },
        {
          dateColumn: '2021-04-01',
          population: 562,
          sample: 9,
          ratio: '0.016',
        },
        {
          dateColumn: '2021-03-31',
          population: 380,
          sample: 8,
          ratio: '0.021',
        },
        {
          dateColumn: '2021-03-30',
          population: 380,
          sample: 7,
          ratio: '0.018',
        },
        {
          dateColumn: '2021-03-29',
          population: 550,
          sample: 11,
          ratio: '0.020',
        },
        {
          dateColumn: '2021-03-28',
          population: 424,
          sample: 4,
          ratio: '0.009',
        },
        {
          dateColumn: '2021-03-27',
          population: 393,
          sample: 2,
          ratio: '0.005',
        },
        {
          dateColumn: '2021-03-26',
          population: 577,
          sample: 5,
          ratio: '0.009',
        },
        {
          dateColumn: '2021-03-25',
          population: 550,
          sample: 6,
          ratio: '0.011',
        },
        {
          dateColumn: '2021-03-24',
          population: 829,
          sample: 9,
          ratio: '0.011',
        },
        {
          dateColumn: '2021-03-23',
          population: 784,
          sample: 13,
          ratio: '0.017',
        },
      ],
    },
    breakAway: {
      populationSum: 5409,
      sampleSum: 146,
      totalRatio: 0.02699205028655944,
      result: [
        {
          dateColumn: '2021-03-23',
          population: 211,
          sample: 4,
          ratio: '0.019',
        },
        {
          dateColumn: '2021-03-24',
          population: 193,
          sample: 2,
          ratio: '0.010',
        },
        {
          dateColumn: '2021-03-25',
          population: 208,
          sample: 2,
          ratio: '0.010',
        },
        {
          dateColumn: '2021-03-26',
          population: 253,
          sample: 10,
          ratio: '0.040',
        },
        {
          dateColumn: '2021-03-27',
          population: 211,
          sample: 8,
          ratio: '0.038',
        },
        {
          dateColumn: '2021-03-28',
          population: 203,
          sample: 4,
          ratio: '0.020',
        },
        {
          dateColumn: '2021-03-29',
          population: 243,
          sample: 5,
          ratio: '0.021',
        },
        {
          dateColumn: '2021-03-30',
          population: 197,
          sample: 1,
          ratio: '0.005',
        },
        {
          dateColumn: '2021-03-31',
          population: 200,
          sample: 2,
          ratio: '0.010',
        },
        {
          dateColumn: '2021-04-01',
          population: 271,
          sample: 6,
          ratio: '0.022',
        },
        {
          dateColumn: '2021-04-02',
          population: 231,
          sample: 10,
          ratio: '0.043',
        },
        {
          dateColumn: '2021-04-03',
          population: 155,
          sample: 7,
          ratio: '0.045',
        },
        {
          dateColumn: '2021-04-04',
          population: 158,
          sample: 8,
          ratio: '0.051',
        },
        {
          dateColumn: '2021-04-05',
          population: 150,
          sample: 4,
          ratio: '0.027',
        },
        {
          dateColumn: '2021-04-06',
          population: 176,
          sample: 8,
          ratio: '0.045',
        },
        {
          dateColumn: '2021-04-07',
          population: 154,
          sample: 6,
          ratio: '0.039',
        },
        {
          dateColumn: '2021-04-08',
          population: 226,
          sample: 6,
          ratio: '0.027',
        },
        {
          dateColumn: '2021-04-09',
          population: 264,
          sample: 13,
          ratio: '0.049',
        },
        {
          dateColumn: '2021-04-10',
          population: 155,
          sample: 5,
          ratio: '0.032',
        },
        {
          dateColumn: '2021-04-11',
          population: 125,
          sample: 2,
          ratio: '0.016',
        },
        {
          dateColumn: '2021-04-12',
          population: 139,
          sample: 3,
          ratio: '0.022',
        },
        {
          dateColumn: '2021-04-13',
          population: 141,
          sample: 3,
          ratio: '0.021',
        },
        {
          dateColumn: '2021-04-14',
          population: 163,
          sample: 2,
          ratio: '0.012',
        },
        {
          dateColumn: '2021-04-15',
          population: 243,
          sample: 8,
          ratio: '0.033',
        },
        {
          dateColumn: '2021-04-16',
          population: 177,
          sample: 4,
          ratio: '0.023',
        },
        {
          dateColumn: '2021-04-17',
          population: 116,
          sample: 2,
          ratio: '0.017',
        },
        {
          dateColumn: '2021-04-18',
          population: 130,
          sample: 4,
          ratio: '0.031',
        },
        {
          dateColumn: '2021-04-19',
          population: 133,
          sample: 3,
          ratio: '0.023',
        },
        {
          dateColumn: '2021-04-20',
          population: 143,
          sample: 1,
          ratio: '0.007',
        },
        {
          dateColumn: '2021-04-21',
          population: 40,
          sample: 3,
          ratio: '0.075',
        },
        {
          dateColumn: '2021-04-22',
          population: 1,
          sample: null,
          ratio: null,
        },
      ],
    },
    buy: {
      populationSum: 159,
      sampleSum: 138,
      totalRatio: 0.8679245283018868,
      result: [
        {
          dateColumn: '2021-03-22',
          population: 4,
          sample: 4,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-23',
          population: 13,
          sample: 13,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-24',
          population: 9,
          sample: 9,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-25',
          population: 6,
          sample: 6,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-26',
          population: 5,
          sample: 5,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-27',
          population: 2,
          sample: 2,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-28',
          population: 4,
          sample: 4,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-29',
          population: 11,
          sample: 11,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-30',
          population: 7,
          sample: 7,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-31',
          population: 8,
          sample: 7,
          ratio: '0.875',
        },
        {
          dateColumn: '2021-04-01',
          population: 9,
          sample: 9,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-02',
          population: 5,
          sample: 5,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-03',
          population: 5,
          sample: 5,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-04',
          population: 3,
          sample: 3,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-05',
          population: 7,
          sample: 7,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-06',
          population: 5,
          sample: 5,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-07',
          population: 2,
          sample: 2,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-08',
          population: 6,
          sample: 6,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-09',
          population: 9,
          sample: 8,
          ratio: '0.889',
        },
        {
          dateColumn: '2021-04-10',
          population: 3,
          sample: 2,
          ratio: '0.667',
        },
        {
          dateColumn: '2021-04-11',
          population: 2,
          sample: 2,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-12',
          population: 4,
          sample: 3,
          ratio: '0.750',
        },
        {
          dateColumn: '2021-04-13',
          population: 8,
          sample: 6,
          ratio: '0.750',
        },
        {
          dateColumn: '2021-04-14',
          population: 5,
          sample: 2,
          ratio: '0.400',
        },
        {
          dateColumn: '2021-04-15',
          population: 5,
          sample: 1,
          ratio: '0.200',
        },
        {
          dateColumn: '2021-04-16',
          population: 3,
          sample: 1,
          ratio: '0.333',
        },
        {
          dateColumn: '2021-04-17',
          population: 3,
          sample: 1,
          ratio: '0.333',
        },
        {
          dateColumn: '2021-04-19',
          population: 6,
          sample: 2,
          ratio: '0.333',
        },
        {
          dateColumn: '2021-04-21',
          population: 3,
          sample: null,
          ratio: null,
        },
        {
          dateColumn: '2021-04-20',
          population: 6,
          sample: null,
          ratio: null,
        },
        {
          dateColumn: '2021-04-18',
          population: 1,
          sample: null,
          ratio: null,
        },
      ],
    },
    buyAccurate: {
      populationSum: 389,
      sampleSum: 226,
      totalRatio: 0.5809768637532133,
      result: [
        {
          dateColumn: '2021-03-22',
          population: 13,
          sample: 5,
          ratio: '0.385',
        },
        {
          dateColumn: '2021-03-23',
          population: 39,
          sample: 21,
          ratio: '0.538',
        },
        {
          dateColumn: '2021-03-24',
          population: 27,
          sample: 13,
          ratio: '0.481',
        },
        {
          dateColumn: '2021-03-25',
          population: 14,
          sample: 14,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-03-26',
          population: 10,
          sample: 4,
          ratio: '0.400',
        },
        {
          dateColumn: '2021-03-27',
          population: 5,
          sample: 1,
          ratio: '0.200',
        },
        {
          dateColumn: '2021-03-28',
          population: 6,
          sample: 3,
          ratio: '0.500',
        },
        {
          dateColumn: '2021-03-29',
          population: 32,
          sample: 19,
          ratio: '0.594',
        },
        {
          dateColumn: '2021-03-30',
          population: 21,
          sample: 10,
          ratio: '0.476',
        },
        {
          dateColumn: '2021-03-31',
          population: 27,
          sample: 17,
          ratio: '0.630',
        },
        {
          dateColumn: '2021-04-01',
          population: 23,
          sample: 9,
          ratio: '0.391',
        },
        {
          dateColumn: '2021-04-02',
          population: 13,
          sample: 6,
          ratio: '0.462',
        },
        {
          dateColumn: '2021-04-03',
          population: 16,
          sample: 9,
          ratio: '0.563',
        },
        {
          dateColumn: '2021-04-04',
          population: 5,
          sample: 5,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-05',
          population: 17,
          sample: 10,
          ratio: '0.588',
        },
        {
          dateColumn: '2021-04-06',
          population: 10,
          sample: 8,
          ratio: '0.800',
        },
        {
          dateColumn: '2021-04-07',
          population: 4,
          sample: 4,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-08',
          population: 26,
          sample: 18,
          ratio: '0.692',
        },
        {
          dateColumn: '2021-04-09',
          population: 30,
          sample: 15,
          ratio: '0.500',
        },
        {
          dateColumn: '2021-04-10',
          population: 10,
          sample: 6,
          ratio: '0.600',
        },
        {
          dateColumn: '2021-04-11',
          population: 7,
          sample: 3,
          ratio: '0.429',
        },
        {
          dateColumn: '2021-04-12',
          population: 8,
          sample: 3,
          ratio: '0.375',
        },
        {
          dateColumn: '2021-04-13',
          population: 14,
          sample: 11,
          ratio: '0.786',
        },
        {
          dateColumn: '2021-04-14',
          population: 4,
          sample: 4,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-15',
          population: 5,
          sample: null,
          ratio: null,
        },
        {
          dateColumn: '2021-04-16',
          population: 3,
          sample: 3,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-17',
          population: 1,
          sample: 1,
          ratio: '1.000',
        },
        {
          dateColumn: '2021-04-19',
          population: 4,
          sample: 4,
          ratio: '1.000',
        },
      ],
    },
    reorder: [
      {
        firstOrder: 103,
        secondOverOrder: 23,
      },
    ],
  });
  const [startDate, setStartDate] = useState(
    new Date(beforeMonthDate).getTime(),
  );
  const [endDate, setEndDate] = useState(todayTimestamp);

  const { getTrafficKPIData } = useKPIDataAPI();

  const getTrafficKPIDataHandle = async () => {
    try {
      setIsLoading(true);
      const result = await getTrafficKPIData(
        startDate,
        endDate + 60 * 60 * 24 * 1000,
      );
      setTrafficData(result);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTrafficKPIDataHandle();
  }, []);

  const ChartAndTableData = [
    {
      title: '유입 대비 주문전환',
      data: trafficData.orderPerUV,
      populationName: '방문',
      sampleName: '주문',
      ratioName: '비율',
    },
    {
      title: '이탈율',
      data: trafficData.breakAway,
      populationName: '방문자',
      sampleName: '이탈(홈)',
      ratioName: '비율',
    },
    {
      title: '구매율',
      data: trafficData.buy,
      populationName: '주문(건)',
      sampleName: '확정(건)',
      ratioName: '비율',
    },
    {
      title: '적중율',
      data: trafficData.buyAccurate,
      populationName: '주문(도서)',
      sampleName: '확정(도서)',
      ratioName: '비율',
    },
  ];

  return (
    <>
      <DatePickerContainer>
        <SelectDatePick
          date={startDate}
          maxDate={endDate}
          getData={(date) => setStartDate(date)}
        />
        <div>&nbsp;~&nbsp;</div>
        <SelectDatePick
          date={endDate}
          maxDate={todayTimestamp}
          getData={(date) => setEndDate(date)}
        />
        <DateHandleButtonWrapper>
          <ButtonComponent
            title={'확인'}
            onClick={() => getTrafficKPIDataHandle()}
            width={75}
            height={35}
            fontSize={15}
          />
        </DateHandleButtonWrapper>
      </DatePickerContainer>
      <BodyContainer>
        <Retiontion data={trafficData.retention} />
        {ChartAndTableData.map((data, index) => (
          <Fragment key={index}>
            <ChardData
              title={data.title}
              data={data.data}
              populationName={data.populationName}
              sampleName={data.sampleName}
              ratioName={data.ratioName}
            />
          </Fragment>
        ))}
        <TrafficComponent
          title={'재주문율'}
          data={`${(
            (trafficData.reorder[0].secondOverOrder /
              trafficData.reorder[0].firstOrder) *
            100
          ).toFixed(1)}%`}
        />
      </BodyContainer>
      <Loader isLoading={isLoading} />
    </>
  );
};

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30px;
  padding-right: 50px;
`;

const DateHandleButtonWrapper = styled.div`
  margin-left: 20px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
  margin-top: 37px;
`;

export default TrafficKPIPresenter;
