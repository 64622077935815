import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

interface Props {
  className?: any;
  visible?: any;
  backgroundColor?: string;
  width?: number;
  height?: number;
}

const ModalComponent: FunctionComponent<Props> = ({
  className,
  visible,
  children,
  backgroundColor = '#121420',
  width = 500,
  height = 500,
}) => {
  return (
    <>
      <ModalOverlay visible={visible} />
      <ModalWrapper className={className} visible={visible}>
        <ModalInner
          className="modal-inner"
          backgroundColor={backgroundColor}
          width={width}
          height={height}
        >
          {children}
        </ModalInner>
      </ModalWrapper>
    </>
  );
};

ModalComponent.propTypes = {
  visible: PropTypes.bool,
};

interface ModalWrapperProps {
  visible: string;
}

const ModalWrapper = styled.div<ModalWrapperProps>`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  overflow-y: hidden;
  outline: 0;
`;

interface VisibleProps {
  visible: string;
}

const ModalOverlay = styled.div<VisibleProps>`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0.4;
  z-index: 999;
`;

interface ModalInnerProps {
  backgroundColor: string;
  width: number;
  height: number;
}

const ModalInner = styled.div<ModalInnerProps>`
  box-sizing: border-box;
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  backdrop-filter: blur(30px);
  box-shadow: 0 5px 10px 0 rgba(67, 67, 67, 0.16);
  border-radius: 10px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
`;

export default ModalComponent;
