import useAPI from '../useAPI';

export default function useBookAPI() {
  const { getHandler, postHandler } = useAPI();

  const getSearchBookAPI = async (bookName: string) => {
    try {
      const res: any = await postHandler('/search/books', {
        bookName,
        size: 20,
        maxScore: [],
      });
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  const getSearchBookIdAPI = async (bookId, type) => {
    try {
      const res: any = await getHandler('/admin/search/book', {
        bookId,
        type,
      });
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  return {
    getSearchBookAPI,
    getSearchBookIdAPI,
  };
}
