// apis
import useAPI from 'api/useAPI';

export interface DayTraffic {
  pageCode: string;
  pageName: string;
  uv: number;
  pv: number;
  pvPerUser: number;
}

export interface DayTrafficDetail {
  dashboardDate: string; // YYYY-MM-DD
  uv: number;
  pv: number;
  pvPerUser: number;
}

export interface DayMonitorTraffic {
  dashboardDate: string;
  dailyNewUserCount: number;
  dailyOrderCount: number;
  uv: number;
  pv: number;
}

export interface BuyCountByDate {
  dashboardDate: string;
  buyCount: number;
}

export interface RetentionMatrix {
  matrix: Array<number[]>;
}

export interface AttritionCountByDate {
  userID: string;
  visitCount: number;
}

export interface BookReturnTupleCountByDate {
  dashboardDate: string;
  returnYn: number;
  bookCount: number;
}

export interface OrderCompletedTupleCountByDate {
  dashboardDate: string;
  buyYn: number;
  transCount: number;
}

export interface PurchaseListByUser {
  userID: string;
  orderList: string;
  buyCount: number;
}

export default function useTrafficAPI() {
  const { getHandler } = useAPI();

  async function getDayTrafficAPI(params: {
    dashboardDate: string; // YYYY-MM-DD
  }): Promise<DayTraffic[]> {
    try {
      const res: any = await getHandler('/admin/dashboard/traffic/day', params);
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getDayTrafficDetailAPI(params: {
    dashboardDate: string; // YYYY-MM-DD
    item: string;
  }): Promise<DayTrafficDetail[]> {
    try {
      const res: any = await getHandler(
        '/admin/dashboard/traffic/item',
        params,
      );
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getPurchaseListByUserAPI(params: {
    dashboardDateFrom: string;
    dashboardDateTo: string;
  }): Promise<PurchaseListByUser[]> {
    const res: any = await getHandler(
      '/admin/dashboard/traffic/orderListByUser',
      params,
    );
    return res.data?.result;
  }

  async function getOrderCompletedTupleCountByDate(params: {
    dashboardDateFrom: string;
    dashboardDateTo: string;
  }): Promise<OrderCompletedTupleCountByDate[]> {
    const res: any = await getHandler(
      '/admin/dashboard/traffic/orderCompletedTupleCountByDate',
      params,
    );
    return res.data?.result;
  }

  async function getBookReturnTupleCountByDate(params: {
    dashboardDateFrom: string;
    dashboardDateTo: string;
  }): Promise<BookReturnTupleCountByDate[]> {
    const res: any = await getHandler(
      '/admin/dashboard/traffic/bookReturnTupleCountByDate',
      params,
    );
    return res.data?.result;
  }

  async function getAttritionCountByUserAPI(params: {
    dashboardDateFrom: string;
    dashboardDateTo: string;
  }): Promise<AttritionCountByDate[]> {
    const res: any = await getHandler(
      '/admin/dashboard/traffic/attritionCountByDate',
      params,
    );
    return res.data?.result;
  }

  async function getBuyCountByDateAPI(params: {
    dashboardDate: string;
  }): Promise<BuyCountByDate[]> {
    const res: any = await getHandler(
      '/admin/dashboard/traffic/buyCountByDate',
      params,
    );
    return res.data?.result;
  }

  async function getRetentionMatrixAPI(params: {
    dashboardDate: string;
  }): Promise<RetentionMatrix> {
    const res: any = await getHandler(
      '/admin/dashboard/traffic/retentionMatrix',
      params,
    );
    return res.data?.result;
  }

  async function getMonitorTrafficAPI(params: {
    dashboardDateFrom: string;
    dashboardDateTo: string;
  }): Promise<DayMonitorTraffic[]> {
    const res: any = await getHandler(
      '/admin/dashboard/traffic/monitoring',
      params,
    );

    return res.data?.result;
  }

  return {
    getDayTrafficAPI,
    getDayTrafficDetailAPI,
    getPurchaseListByUserAPI,
    getOrderCompletedTupleCountByDate,
    getBookReturnTupleCountByDate,
    getAttritionCountByUserAPI,
    getBuyCountByDateAPI,
    getRetentionMatrixAPI,
    getMonitorTrafficAPI,
  };
}
