import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import MonthPicker from '@9softstudio/react-monthpicker';
import '@9softstudio/react-monthpicker/dist/reactmonthpicker.css';
// api
import useEmployeeAPI from 'api/etc/useEmployeeAPI';
// components
import Breadcrumb from 'components/common/breadcrumb';
import EmployeeList from './EmployeeList';
import OrderList from './OrderList';
// types
import { EmployeeBookOrder, EmployeeBookOrderStatic } from 'api/types';

export default function Index() {
  const [date, setDate]: any = useState(new Date());

  const { getEmployeeBookOrderAPI } = useEmployeeAPI();
  const [employees, setEmployees] = useState<EmployeeBookOrderStatic[]>([]);
  const [orders, setOrders] = useState<EmployeeBookOrder[]>([]);

  async function handleGetEmployeeBookOrder(date) {
    try {
      const newData = await getEmployeeBookOrderAPI({
        yearMonth: moment(date).format('YYYY-MM'),
      });
      const newOrders: any = [];
      for (let i = 0; i < newData.orderListAll.length; i++) {
        const order = newData.orderListAll[i];
        for (let j = 0; j < order.bookList.length; j++) {
          const book = order.bookList[j];

          newOrders.push({
            ...order,
            ...book,
            rowSpan: j === 0,
            rowSpanLength: order.bookList.length,
          });
        }
      }
      setEmployees(newData.orderListStatistic);
      setOrders(newOrders);
    } catch (error) {
      console.log(error);
    }
  }

  function handleDateSelect(month, year) {
    setDate(new Date(`${year}-${month}`));
  }

  useEffect(() => {
    handleGetEmployeeBookOrder(date);
  }, [date]);

  return (
    <Fragment>
      <Breadcrumb title="현황" parent="사내 서점" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 20,
          marginBottom: 24,
        }}
      >
        <div
          style={{
            fontSize: 36,
            fontWeight: 700,
            marginRight: 16,
          }}
        >
          {moment(date).format('YYYY.M')} 사내 서점 현황
        </div>
        <MonthPicker onSelect={handleDateSelect} />
      </div>
      <div>
        <OrderList orders={orders} />
        <EmployeeList employees={employees} />
      </div>
    </Fragment>
  );
}
