import React, { useMemo } from 'react';
// styles
import { Table } from 'styles/index';

export default function UserDetail({ user }) {
  const columns = useMemo(
    () => [
      {
        Header: '닉네임',
        accessor: 'nickname',
      },
      {
        Header: '연락처',
        accessor: 'userPhone',
      },
      {
        Header: '성별',
        accessor: 'gender',
      },
      {
        Header: '나이',
        accessor: 'age',
      },
      {
        Header: '직업',
        accessor: 'occupation',
      },
      {
        Header: '마일리지',
        accessor: 'mileage',
      },
      {
        Header: '연락처가입일',
        accessor: 'joinPhone',
      },
      {
        Header: '카카오가입일',
        accessor: 'joinKakao',
      },
      {
        Header: '애플가입일',
        accessor: 'joinApple',
      },
      {
        Header: '푸시동의여부',
        accessor: 'newMessageAlarm',
      },
      {
        Header: '마케팅동의여부',
        accessor: 'marketingAgreeYn',
      },
    ],
    [],
  );

  if (!user) return null;

  return (
    <div style={{ flex: 1 }}>
      <Table fullScreen columns={columns} data={[user]} />
    </div>
  );
}
