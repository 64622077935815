import React, { useEffect, useState } from 'react';
import useBlibraryAPI from 'api/operation/useBlibraryAPI';
import { useHistory, useLocation } from 'react-router';
import ButtonComponent from 'components/common/ButtonComponent';
import Loader from 'components/common/loader';
import TextForm from '../../../aComponents/TextInputForm';
import BookListForm from '../../../aComponents/BookListForm';
import ImageSmallForm from '../../../aComponents/ImageSmallForm';
import TextAreaForm from '../../../aComponents/TextAreaForm';
import ModalSearchBookForm from '../../../aComponents/ModalSearchBookForm';
import ModalSearchBookCurationForm from '../../../aComponents/ModalSearchBookCurationForm';
import DatePickerForm from '../../../aComponents/DatePickerForm';
import TextStaticForm from '../../../aComponents/TextStaticForm';
import styled from 'styled-components';
import { GREY_LIGHT, GREY_DARK } from 'styles/colors';

const CreateBlibrary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [blibraryUserImage, setBlibraryUserImage] = useState<any>(null);
  const [blibraryUserImagePath, setBlibraryUserImagePath] = useState<any>('');
  const [name, setName] = useState('');
  const [userIntroduce, setUserIntroduce] = useState('');
  const [job, setJob] = useState('');
  const [influencerIntroduce, setInfluencerIntroduce] = useState('');
  const [addAuthorBookModal, setAddAuthorBookModal] = useState(false);
  const [authorName, setAuthorName] = useState('');
  const [authorDescription, setAuthorDescription] = useState('');
  const [authorBookList, setAuthorBookList] = useState<any[]>([]);
  const [curationId, setCurationId] = useState('');
  const [curationTitle, setCurationTitle] = useState('');
  const [curationSubTitle, setCurationSubTitle] = useState('');
  const [curationContents, setCurationContents] = useState('');
  const [curationPublishDate, setCurationPublishDate] = useState(new Date());
  const [addCurationBookModal, setAddCurationBookModal] = useState(false);
  const [curationBookList, setCurationBookList] = useState<any[]>([]);
  const [bookshelfModal, setBookshelfModal] = useState(false);
  const [bookshelfBookList, setBookshelfBookList] = useState<any[]>([]);
  const [blibraryData, setBlibraryData] = useState<any>(null);

  const { getBlibraryAPI, putBlibraryAPI } = useBlibraryAPI();
  const history = useHistory();
  const location = useLocation();
  const influencerId = parseInt(location.pathname.split('influencer/')[1]);

  const getBilbrayData = async () => {
    setIsLoading(true);
    const res = await getBlibraryAPI(influencerId);

    if (res) {
      for (let i = 0; i < res.blibraryBook.length; i++) {
        res.blibraryBook[i].edit = false;
      }
      for (let i = 0; i < res.bookshelfBook.length; i++) {
        res.bookshelfBook[i].edit = false;
      }
      for (let i = 0; i < res.authorBook.length; i++) {
        res.authorBook[i].edit = false;
      }

      setBlibraryData(res);
      setBlibraryUserImage(res.image);
      setName(res.userName);
      setUserIntroduce(res.userIntroduce);
      setJob(res.job);
      setInfluencerIntroduce(res.influencerIntroduce);
      setCurationId(res.curationId);
      setCurationTitle(res.curationTitle);
      setCurationSubTitle(res.curationSubtitle);
      setCurationContents(res.curationContent);
      setAuthorName(res.authorName);
      setAuthorDescription(res.authorDescription);
      setCurationBookList(res.blibraryBook);
      setBookshelfBookList(res.bookshelfBook);
      setAuthorBookList(res.authorBook);
      setCurationPublishDate(new Date(res.curationPublishDate));
    }
    setIsLoading(false);
  };

  const inputId = 'userFileInput';
  const userImageClickHandle = () => {
    const element = document.getElementById(inputId);
    element?.click();
  };

  //유저정보

  const onChangeUserImage = (fileObj: any) => {
    let filePath = fileObj.target.files[0];
    if (filePath !== undefined) {
      let reader = new FileReader();
      reader.onload = function (event: any) {
        setBlibraryUserImage(event.target.result);
        setBlibraryUserImagePath(filePath);
      };
      reader.readAsDataURL(filePath);
    }
  };

  const onChangeName = (text) => {
    const textValue = text.target.value;
    setName(textValue);
  };

  const onChangeUserIntroduce = (text) => {
    const textValue = text.target.value;
    setUserIntroduce(textValue);
  };

  //인플루언서 정보
  const onChangeJob = (text) => {
    const textValue = text.target.value;
    setJob(textValue);
  };

  const onChangeInfluencerIntroduce = (text) => {
    const textValue = text.target.value;
    setInfluencerIntroduce(textValue);
  };

  //책장 책 등록
  const cancelBookshelfBookHandle = (bookId) => {
    const cancelBookList = bookshelfBookList.filter(
      (book) => book.bookId !== bookId,
    );
    const cancelBook = bookshelfBookList.filter(
      (book) => book.bookId === bookId,
    )[0];
    cancelBook.edit = 'minus';
    cancelBookList.push(cancelBook);
    setBookshelfBookList(cancelBookList);
  };

  const selectBookshelfBookHandle = (book) => {
    book.edit = 'add';
    const addBookIdList = [...bookshelfBookList, book];
    setBookshelfModal(false);
    setBookshelfBookList(addBookIdList);
  };

  //저자 정보
  const onChnageAuthorName = (text) => {
    const textValue = text.target.value;
    setAuthorName(textValue);
  };

  const onChangeAuthorDescription = (text) => {
    const textValue = text.target.value;
    setAuthorDescription(textValue);
  };

  const selectAuthorBookHandle = (book) => {
    book.edit = 'add';
    const addBookIdList = [...authorBookList, book];
    setAddAuthorBookModal(false);
    setAuthorBookList(addBookIdList);
  };

  const cancelAuthorBookHandle = (bookId) => {
    const cancelBookList = authorBookList.filter(
      (book) => book.bookId !== bookId,
    );
    const cancelBook = authorBookList.filter(
      (book) => book.bookId === bookId,
    )[0];
    cancelBook.edit = 'minus';
    cancelBookList.push(cancelBook);
    setAuthorBookList(cancelBookList);
  };

  //큐레이션 정보

  const onChangeCurationTitle = (text) => {
    const textValue = text.target.value;
    setCurationTitle(textValue);
  };

  const onChangeCurationSubTitle = (text) => {
    const textValue = text.target.value;
    setCurationSubTitle(textValue);
  };

  const onChangeCurationContents = (text) => {
    const textValue = text.target.value;
    setCurationContents(textValue);
  };

  const selectCurationBookHandle = (book) => {
    book.edit = 'add';
    const addBookIdList = [...curationBookList, book];
    setAddCurationBookModal(false);
    setCurationBookList(addBookIdList);
  };

  const cancelCurationBookHandle = (bookId) => {
    const cancelBookList = curationBookList.filter(
      (book) => book.bookId !== bookId,
    );
    const cancelBook = curationBookList.filter(
      (book) => book.bookId === bookId,
    )[0];
    cancelBook.edit = 'minus';
    cancelBookList.push(cancelBook);
    setCurationBookList(cancelBookList);
  };

  const updateBlibraryContentHandle = async () => {
    const publishDateTimeStamp = new Date(curationPublishDate).getTime();
    const formData: any = new FormData();

    try {
      const changedCheck = [
        {
          type: '유저 이미지',
          result:
            blibraryUserImage?.includes('ap-northeast-2') ||
            blibraryUserImage?.includes('  ') ||
            blibraryUserImage === null,
        },
        { type: '유저 이름', result: blibraryData.userName === name },
        {
          type: '유저 소개',
          result: blibraryData.userIntroduce === userIntroduce,
        },
        { type: '인플루언서 직업', result: blibraryData.job === job },
        {
          type: '인플루언서 소개',
          result: blibraryData.influencerIntroduce === influencerIntroduce,
        },
        {
          type: '큐래이션 제목',
          result: blibraryData.curationTitle === curationTitle,
        },
        {
          type: '큐래이션 부제목',
          result: blibraryData.curationSubtitle === curationSubTitle,
        },
        {
          type: '큐래이션 컨텐츠',
          result: blibraryData.curationContent === curationContents,
        },
        {
          type: '큐래이션 게시일',
          result:
            new Date(blibraryData.curationPublishDate).getTime() ===
            new Date(curationPublishDate).getTime(),
        },
        {
          type: '큐래이션 책',
          result: blibraryData.blibraryBook === curationBookList,
        },
        { type: '저자 이름', result: blibraryData.authorName === authorName },
        {
          type: '저자 설명',
          result: blibraryData.authorDescription === authorDescription,
        },
        { type: '저자 책', result: blibraryData.authorBook == authorBookList },
      ];

      const changedValues = changedCheck
        .filter((value) => !value.result)
        .map((item) => item.type);

      formData.append('changedValues', JSON.stringify(changedValues));
      formData.append('userId', blibraryData.userId);
      formData.append('authorId', blibraryData.authorId);
      formData.append('bookshelfId', blibraryData.bookshelfId);
      formData.append('file', blibraryUserImagePath);
      formData.append('name', name);
      formData.append('userIntroduce', userIntroduce);
      formData.append('job', job);
      formData.append('influencerIntroduce', influencerIntroduce);
      formData.append('curationId', curationId);
      formData.append('curationTitle', curationTitle);
      formData.append('curationSubTitle', curationSubTitle);
      formData.append('curationContents', curationContents);
      formData.append('curationPublishDate', publishDateTimeStamp);
      formData.append('curationBookList', JSON.stringify(curationBookList));
      formData.append('authorName', authorName);
      formData.append('authorDescription', authorDescription);
      formData.append('authorBookList', JSON.stringify(authorBookList));
      formData.append('bookshelfBookList', JSON.stringify(bookshelfBookList));
      await putBlibraryAPI(formData);
    } catch (e) {
      console.log(e);
    } finally {
      history.push({ pathname: '/blibrary' });
    }
  };

  useEffect(() => {
    getBilbrayData();
    const element: any = document.getElementById(inputId);
    element.addEventListener('click', userImageClickHandle);
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <Title>비서재 생성</Title>
      </HeaderWrapper>
      <Wrapper>
        <SubTitle>유저 정보 생성</SubTitle>
        <ImageSmallForm
          title={'유저 이미지'}
          image={blibraryUserImage}
          onChange={userImageClickHandle}
          onClick={onChangeUserImage}
          id={'userFileInput'}
        />
        <BorderTop />
        <TextForm
          value={name}
          title={'이름'}
          onChange={onChangeName}
          type={'text'}
        />
        <BorderTop />
        <TextForm
          value={userIntroduce}
          title={'유저소개'}
          onChange={onChangeUserIntroduce}
          type={'text'}
        />
        <BorderTop />
        <SubTitle>인플루언서 정보 생성</SubTitle>
        <TextForm
          value={job}
          title={'직업'}
          onChange={onChangeJob}
          type={'text'}
        />
        <BorderTop />
        <TextAreaForm
          title={'인플루언서 소개'}
          onChange={onChangeInfluencerIntroduce}
          value={influencerIntroduce}
          cols={20}
          row={10}
        />
        <BorderTop />
        <SubTitle>큐래이션 정보 생성</SubTitle>
        <TextForm
          value={curationTitle}
          title={'큐래이션 제목'}
          onChange={onChangeCurationTitle}
          type={'text'}
        />
        <BorderTop />
        <TextForm
          value={curationSubTitle}
          title={'큐래이션 부제목'}
          onChange={onChangeCurationSubTitle}
          type={'text'}
        />
        <BorderTop />
        <TextAreaForm
          title={'큐래이션 콘텐츠'}
          value={curationContents}
          onChange={onChangeCurationContents}
          cols={20}
          row={10}
        />
        <BorderTop />
        <DatePickerForm
          title={'큐래이션 게시일자'}
          date={curationPublishDate}
          setDate={setCurationPublishDate}
        />
        <BorderTop />
        <BookListForm
          title={'큐래이션 책 등록'}
          data={curationBookList.filter((book) => book.edit !== 'minus')}
          onClick={() => setAddCurationBookModal(!addCurationBookModal)}
          cancelBookHandle={cancelCurationBookHandle}
        />
        <BorderTop />
        <SubTitle>책장 책 등록</SubTitle>
        <BookListForm
          title={'책장 등록'}
          data={bookshelfBookList.filter((book) => book.edit !== 'minus')}
          onClick={() => setBookshelfModal(!bookshelfModal)}
          cancelBookHandle={cancelBookshelfBookHandle}
        />
        <TextStaticForm title={'추천 등록'} text={'<큐래이션 책>과 동일'} />
        <TextStaticForm title={'저서 등록'} text={'<저자 책>과 동일'} />
        <BorderTop />
        <SubTitle>저자 정보 생성</SubTitle>
        <TextForm
          value={authorName}
          title={'저자 이름'}
          onChange={onChnageAuthorName}
          type={'text'}
        />
        <BorderTop />
        <TextAreaForm
          title={'저자 설명'}
          value={authorDescription}
          onChange={onChangeAuthorDescription}
          cols={20}
          row={10}
        />
        <BorderTop />
        <BookListForm
          title={'저자 책 등록'}
          data={authorBookList.filter((book) => book.edit !== 'minus')}
          onClick={() => setAddAuthorBookModal(!addAuthorBookModal)}
          cancelBookHandle={cancelAuthorBookHandle}
        />
        <BorderTop />
      </Wrapper>
      <div style={{ marginTop: 30 }} />
      <ButtonComponent
        width={150}
        height={50}
        title={'수정'}
        color={'white'}
        fontSize={20}
        backgroundColor={'black'}
        onClick={() => updateBlibraryContentHandle()}
        fontWeight={700}
        borderRadius={10}
      />
      <ModalSearchBookCurationForm
        visible={addCurationBookModal}
        bookClickHandle={selectCurationBookHandle}
        id={'curationSearch'}
      />
      <ModalSearchBookForm
        visible={addAuthorBookModal}
        bookClickHandle={selectAuthorBookHandle}
        id={'authorSearch'}
      />
      <ModalSearchBookForm
        visible={bookshelfModal}
        bookClickHandle={selectBookshelfBookHandle}
        id={'bookshelfSearch'}
      />
      <Loader isLoading={isLoading} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: 'jua';
`;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 30px;
  width: 97%;
  margin-top: 30px;
  margin-bottom: 60px;
  color: ${GREY_DARK};
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 0px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const BorderTop = styled.div`
  width: 100%;
  border-top: solid 2px ${GREY_LIGHT};
  margin-top: 40px;
  margin-bottom: 20px;
  opacity: 0.2;
`;

export default CreateBlibrary;
