export function customToLocaleString(value?: number | string) {
  const stringValue = String(value || '0');
  return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getTextWithoutHTMLTag(text: string) {
  const removedEntityContent = text.replace(/&\w+;\s*/g, '');
  const replaceBrTagContent = removedEntityContent.replace(/<br>/g, '\n');
  const removedTagContent = replaceBrTagContent.replace(/(<([^>]+)>)/gi, '');
  return removedTagContent;
}

// 할인가
export const setSalePrice = (
  price: number | string,
  discountRate: number | string,
) => {
  return customToLocaleString(Number(price) * (1 - Number(discountRate)));
};

export const setSalePriceNumber = (
  price: number | string,
  discountRate: number | string,
) => {
  return Number(price) * (1 - Number(discountRate));
};

export const getMileage = (
  price: number | string,
  discountRate: number | string,
) => {
  return Math.ceil(((Number(price) * (1 - Number(discountRate))) / 0.9) * 0.05);
};
