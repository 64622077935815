import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

interface Props {
  data: any;
  title: string;
  selectType: any;
  setSelectType: any;
}

const SelectForm: FunctionComponent<Props> = ({
  title,
  data,
  selectType,
  setSelectType,
}) => {
  const [selectData, setSelectData] = useState<any>(data);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: 'solid 1px #808080',
    }),
  };

  useEffect(() => {
    if (data?.[0]?.value === undefined) {
      let list: any = [];
      for (let i = 0; i < data.length; i++) {
        list.push({ value: data[i], label: data[i] });
      }
      setSelectData(list);
    }
  }, [data]);

  return (
    <FormWrapper>
      <FormText>{title}</FormText>
      <SelectStyle>
        <Select
          value={selectType}
          onChange={(selectedOption) => setSelectType(selectedOption)}
          options={selectData}
          styles={customStyles}
        />
      </SelectStyle>
    </FormWrapper>
  );
};

const FormWrapper = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 100px;
`;

const FormText = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

const SelectStyle = styled.div`
  width: 30vw;
  height: 40px;
  border-radius: 5px;
`;

export default SelectForm;
