import React from 'react';
// common
import { getHyphenWithPhoneNumber } from 'utils/common';
// types
import { Book } from '../../../api/store/preparation';

interface Props {
  bookInfo: Book;
}

export default function WholesaleOrderListitem({ bookInfo }: Props) {
  return (
    <tr order-id={bookInfo.orderId}>
      <td style={{ width: '10%' }}>
        {bookInfo.orderId}
        {` `}
        {bookInfo.receiver_name}
      </td>
      <td style={{ width: '10%' }}>
        {getHyphenWithPhoneNumber(bookInfo.receiver_phone)}
      </td>
      <td style={{ width: '20%' }}>{bookInfo.address}</td>
      <td style={{ width: '10%' }}>{bookInfo.publisherName}</td>
      <td style={{ width: '20%' }}>{bookInfo.bookName}</td>
      <td style={{ width: '10%' }}>{bookInfo.price}</td>
      <td style={{ width: '20%' }}>{bookInfo.isbn_13}</td>
    </tr>
  );
}
