import axios from 'axios';
import useAPI from '../useAPI';
import { API_URL } from '../useAxios';

export default function useBannerAPI() {
  const { getHandler } = useAPI();

  const getBannerAPI = async (id?: number) => {
    try {
      const res: any = await getHandler('/admin/banner', { id });
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  interface putBannerAPIProps {
    formData?: any;
    banner_id: number;
    title?: string;
    banner_image?: string;
    landing_type?: string;
    landing_page_url?: string;
    query?: string | null;
    post_start_date?: number;
    post_end_date?: number;
    priority?: number;
  }

  const putBannerAPI = async ({
    formData,
    banner_id,
    title,
    banner_image,
    landing_type,
    landing_page_url,
    query,
    post_start_date,
    post_end_date,
    priority,
  }: putBannerAPIProps) => {
    try {
      const res = await axios.put(`${API_URL}/admin/banner`, formData, {
        params: {
          banner_id,
          title,
          banner_image,
          landing_type,
          landing_page_url,
          query,
          post_start_date,
          post_end_date,
          priority,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data?.result;
    } catch (e) {
      console.log(e);
    }
  };

  interface postBannerAPIProps {
    formData?: any;
    title?: string;
    landing_type?: string;
    landing_page_url?: string;
    query?: string | null;
    post_start_date?: number;
    post_end_date?: number;
  }

  const postBannerAPI = async ({
    formData,
    title,
    landing_type,
    landing_page_url,
    query,
    post_start_date,
    post_end_date,
  }: postBannerAPIProps) => {
    try {
      const res = await axios.post(`${API_URL}/admin/banner`, formData, {
        params: {
          title,
          landing_type,
          landing_page_url,
          query,
          post_start_date,
          post_end_date,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return res.data?.result;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    getBannerAPI,
    putBannerAPI,
    postBannerAPI,
  };
}
