export interface State {
  user: any;
}

export type Action = {
  type: 'SET_USER';
  payload: { user: any };
};

export const initialState: State = {
  user: null,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

export default reducer;
