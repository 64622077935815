import React from 'react';

interface Props {
  children?: any;
  style?: {};
}
function Flex({ children, style }: Props) {
  return <div style={{ display: 'flex', ...style }}>{children}</div>;
}

export default Flex;
