import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// apis
import useOrderAPI from 'api/store/useOrderAPI';
import usePurchaseAPI from 'api/store/usePurchaseAPI';
// common
import ConfirmModal from 'components/common/modals/ConfirmModal';
// styles
import { Flex, Table } from 'styles/index';
import Pagination from '@material-ui/lab/Pagination';
// types
import { OrderStatusCode, Order as OrderType } from 'api/types';

const ORDER_LENGTH = 20;
interface Props {
  selectedOrderStatusCode: OrderStatusCode | null;
  deleteIncludeYn: number;
  selectedOrder: OrderType | null;
  setSelectedOrder: any;
  searchKeyword: any;
}
export default function Orders({
  selectedOrderStatusCode,
  deleteIncludeYn,
  selectedOrder,
  setSelectedOrder,
  searchKeyword,
}: Props) {
  const columns = useMemo(
    () => [
      {
        Header: '주문ID',
        accessor: 'orderId',
      },
      {
        Header: '유저ID',
        accessor: 'userId',
      },
      {
        Header: '선물유저ID',
        accessor: 'userIdGiftReceiver',
      },
      {
        Header: '요청상태',
        accessor: 'orderStatus',
      },
      {
        Header: '주문자명',
        accessor: 'ordererName',
      },
      {
        Header: '주문자 연락처',
        accessor: 'userPhone',
      },
      {
        Header: '배송지 수취인',
        accessor: 'receiverName',
      },
      {
        Header: '배송지 연락처',
        accessor: 'receiverPhone',
      },
      {
        Header: '주문 일시',
        accessor: 'orderDate',
      },
      {
        Header: '주문으로부터 경과 일시',
        accessor: 'ellapsedTime',
      },
    ],
    [],
  );

  const [page, setPage] = useState(1);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const pageCount = Math.ceil(totalCnt / ORDER_LENGTH);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { getOrdersAPI } = useOrderAPI();
  const { forcePurchaseAPI } = usePurchaseAPI();

  async function handleGetOrders(customPage?: number) {
    try {
      const res = await getOrdersAPI({
        deleteIncludeYn,
        orderStatusCode: selectedOrderStatusCode?.orderStatus || 0,
        page: customPage ? customPage : page,
        search: searchKeyword,
      });
      setOrders(res.orderList);
      setTotalCnt(res.totalCount);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleForcePurchase(order) {
    if (confirm(`${order.ordererName}의 주문을 강제 결제 하시겠어요?`)) {
      try {
        await forcePurchaseAPI({
          userId: order.userId,
          orderId: order.orderId,
        });
        await handleGetOrders(page);
      } catch (error) {
        console.log(error);
      }
    }
  }

  function handleChangePage(page) {
    setPage(page);
    handleGetOrders(page);
  }

  useEffect(() => {
    if (selectedOrderStatusCode) {
      setPage(1);
      handleGetOrders(1);
      setPaginationLoading(true);
      setTimeout(() => {
        setPaginationLoading(false);
      }, 50);
    }
  }, [selectedOrderStatusCode]);

  useEffect(() => {
    setOrders([]);
  }, [deleteIncludeYn]);

  if (!selectedOrderStatusCode) return null;
  if (orders.length === 0) return null;

  return (
    <div style={{ flex: 1, paddingBottom: 20 }}>
      <ConfirmModal
        isVisible={openConfirmModal}
        close={() => setOpenConfirmModal(false)}
      />
      <Table
        fullScreen
        columns={columns}
        data={orders}
        onPress={setSelectedOrder}
        activeValue={selectedOrder?.orderId}
        type="orders"
        style={{ marginBottom: 20 }}
        ellapsedTimePress={handleForcePurchase}
      />
      <Flex style={{ justifyContent: 'center' }}>
        {paginationLoading ? (
          <div style={{ height: 32 }} />
        ) : (
          <StyledPagination
            defaultPage={1}
            count={pageCount ? pageCount : 1}
            onChange={(_, page) => handleChangePage(page)}
          />
        )}
      </Flex>
    </div>
  );
}

const StyledPagination = styled(Pagination)`
  button {
    outline: none;
  }
`;
