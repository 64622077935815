import React, { useEffect, useState } from 'react';
// types
import { Book } from '../../../api/store/preparation';

interface Props {
  bookInfo: Book;
  setSelectedBooks: any;
  isTotalChecked: boolean;
}

export default function AladinOrderListitem({
  bookInfo,
  setSelectedBooks,
  isTotalChecked,
}: Props) {
  const [isChecked, setIsChecked] = useState(false);

  function handleCheck() {
    setSelectedBooks((prev) => {
      const copiedPrev = prev.slice();
      const targetIndex = copiedPrev.findIndex(
        (p) => p.orderBookId === bookInfo.orderBookId,
      );
      if (targetIndex < 0) {
        return [...prev, bookInfo];
      } else {
        copiedPrev.splice(targetIndex, 1);
        return copiedPrev;
      }
    });
    setIsChecked((prev) => !prev);
  }

  useEffect(() => {
    if (isTotalChecked && !isChecked) {
      setIsChecked(true);
    }
    if (!isTotalChecked && isChecked) {
      setIsChecked(false);
    }
  }, [isTotalChecked]);

  return (
    <tr order-id={bookInfo.orderId}>
      <td style={{ width: '10%' }}>
        <input
          className="checkbox_animated"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheck}
          style={{ margin: 0 }}
        />
      </td>
      <td style={{ width: '10%' }}>{bookInfo.orderId}</td>
      <td style={{ width: '20%' }}>{bookInfo.orderBookId}</td>
      <td style={{ width: '15%' }}>{bookInfo.receiver_name}</td>
      <td style={{ width: '30%' }}>{bookInfo.bookName}</td>
      <td style={{ width: '15%' }}>{bookInfo.isbn_13}</td>
    </tr>
  );
}
