import useAPI from '../useAPI';

interface getUVAPIProps {
  day: number;
  week: number;
  month: number;
}

export default function useKPIDataAPI() {
  const { getHandler } = useAPI();

  const getUniqueKPIData = async ({ month, week, date }) => {
    try {
      const res: any = await getHandler('/admin/kpi/unique', {
        month,
        week,
        date,
      });
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  const getTrafficKPIData = async (startDate, endDate) => {
    try {
      const res: any = await getHandler('/admin/kpi/traffic', {
        startDate,
        endDate,
      });
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  return {
    getUniqueKPIData,
    getTrafficKPIData,
  };
}
