import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_DARK } from '../../../../styles/colors';
import { useHistory } from 'react-router';

interface Props {
  id: number;
  name: string;
  creatorImage: string;
  subscriberCount: string;
  link: string;
  deleteYN: number;
}

const CreatorInfo: FunctionComponent<Props> = ({
  id,
  name,
  creatorImage,
  subscriberCount,
  link,
  deleteYN,
}) => {
  const history = useHistory();

  const creatorClickHandle = () => {
    history.push({ pathname: `/creator/${id}` });
  };

  return (
    <CreatorWrapper onClick={() => creatorClickHandle()}>
      <CreatorImage src={creatorImage} />
      <InfoContainer>
        <Title>{name}</Title>
        <SubsciberNum color={GREY_DARK}>{subscriberCount}</SubsciberNum>
      </InfoContainer>
    </CreatorWrapper>
  );
};

const CreatorWrapper = styled.div`
  cursor: pointer;
`;

const CreatorImage = styled.img`
  width: 200px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
`;

const InfoContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
`;

const Title = styled.li`
  font-size: 20px;
  font-weight: 700;
`;

interface JopProps {
  color: string;
}

const SubsciberNum = styled.li<JopProps>`
  font-size: 17px;
  font-weight: 700;
  margin-left: 15px;
  color: ${(props) => props.color};
`;

export default CreatorInfo;
