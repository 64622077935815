import React from 'react';
import styled from 'styled-components';
import { GREY_LIGHT } from 'styles/colors';

const CompareDataComponent = ({
  dateType,
  monthData,
  weekData,
  dateData,
  secondMonthData,
  secondWeekData,
  secondDateData,
}) => {
  const decimalPoint = 1;

  const dateUVCount = dateData.uniqueVisit.uniqueVisitSum;
  const dateSecondUVCount = secondDateData.uniqueVisit.uniqueVisitSum;
  const weekUVCount = weekData.uniqueVisit.uniqueVisitSum;
  const weekSecondUVCount = secondWeekData.uniqueVisit.uniqueVisitSum;
  const monthUVCount = monthData.uniqueVisit.uniqueVisitSum;
  const monthSecondUVCount = secondMonthData.uniqueVisit.uniqueVisitSum;
  const dateNewUserCount = dateData.newUser.newUserSum;
  const dateSecondNewUserCount = secondDateData.newUser.newUserSum;
  const weekNewUserCount = weekData.newUser.newUserSum;
  const weekSecondNewUserCount = secondWeekData.newUser.newUserSum;
  const monthNewUserCount = monthData.newUser.newUserSum;
  const monthSecondNewUserCount = secondMonthData.newUser.newUserSum;
  const dateNewOrderCount = dateData.newOrder.newOrderSum;
  const dateSecondNewOrderCount = secondDateData.newOrder.newOrderSum;
  const weekNewOrderCount = weekData.newOrder.newOrderSum;
  const weekSecondNewOrderCount = secondWeekData.newOrder.newOrderSum;
  const monthNewOrderCount = monthData.newOrder.newOrderSum;
  const monthSecondNewOrderCount = secondMonthData.newOrder.newOrderSum;

  const dateUVGrowthRate: any = (
    ((dateUVCount - dateSecondUVCount) / dateUVCount) *
    100
  ).toFixed(decimalPoint);

  const weekUVGrowthRate: any = (
    ((weekUVCount - weekSecondUVCount) / weekUVCount) *
    100
  ).toFixed(decimalPoint);

  const monthUVGrowthRate: any = (
    ((monthUVCount - monthSecondUVCount) / monthUVCount) *
    100
  ).toFixed(decimalPoint);

  const dateNewUserGrowthRate: any = (
    ((dateNewUserCount - dateSecondNewUserCount) / dateNewUserCount) *
    100
  ).toFixed(decimalPoint);

  const weekNewUserGrowthRate: any = (
    ((weekNewUserCount - weekSecondNewUserCount) / weekNewUserCount) *
    100
  ).toFixed(decimalPoint);

  const monthNewUserGrowthRate: any = (
    ((monthNewUserCount - monthSecondNewUserCount) / monthNewUserCount) *
    100
  ).toFixed(decimalPoint);

  const dateNewOrderGrowthRate: any = (
    ((dateNewOrderCount - dateSecondNewOrderCount) / dateNewOrderCount) *
    100
  ).toFixed(decimalPoint);

  const weekNewOrderGrowthRate: any = (
    ((weekNewOrderCount - weekSecondNewOrderCount) / weekNewOrderCount) *
    100
  ).toFixed(decimalPoint);

  const monthNewOrderGrowthRate: any = (
    ((monthNewOrderCount - monthSecondNewOrderCount) / monthNewOrderCount) *
    100
  ).toFixed(decimalPoint);

  return (
    <Container>
      <div>
        <div style={{ marginBottom: 15, fontWeight: 700, fontSize: 20 }}>
          UV 증감율
        </div>
        <DataContainer
          inDecrease={
            dateType === '일별' &&
            !isNaN(dateUVGrowthRate) &&
            isFinite(dateUVGrowthRate)
              ? dateUVGrowthRate > 0
              : dateType === '주별' &&
                !isNaN(weekUVGrowthRate) &&
                isFinite(weekUVGrowthRate)
              ? weekUVGrowthRate > 0
              : dateType === '월별' &&
                !isNaN(monthUVGrowthRate) &&
                isFinite(monthUVGrowthRate)
              ? monthUVGrowthRate > 0
              : 0
          }
        >
          {dateType === '일별' &&
          !isNaN(dateUVGrowthRate) &&
          isFinite(dateUVGrowthRate)
            ? dateUVGrowthRate
            : dateType === '주별' &&
              !isNaN(weekUVGrowthRate) &&
              isFinite(weekUVGrowthRate)
            ? weekUVGrowthRate
            : dateType === '월별' &&
              !isNaN(monthUVGrowthRate) &&
              isFinite(monthUVGrowthRate)
            ? monthUVGrowthRate
            : null}
          %
        </DataContainer>
      </div>
      <div>
        <div style={{ marginBottom: 15, fontWeight: 700, fontSize: 20 }}>
          신규유입 증감율
        </div>
        <DataContainer>
          {dateType === '일별' &&
          !isNaN(dateNewUserGrowthRate) &&
          isFinite(dateNewUserGrowthRate)
            ? dateNewUserGrowthRate
            : dateType === '주별' &&
              !isNaN(weekNewUserGrowthRate) &&
              isFinite(weekNewUserGrowthRate)
            ? weekNewUserGrowthRate
            : dateType === '월별' &&
              !isNaN(monthNewUserGrowthRate) &&
              isFinite(monthNewUserGrowthRate)
            ? monthNewUserGrowthRate
            : null}
          %
        </DataContainer>
      </div>
      <div>
        <div style={{ marginBottom: 15, fontWeight: 700, fontSize: 20 }}>
          신규주문 증감율
        </div>
        <DataContainer>
          {dateType === '일별' &&
          !isNaN(dateNewOrderGrowthRate) &&
          isFinite(dateNewOrderGrowthRate)
            ? dateNewOrderGrowthRate
            : dateType === '주별' &&
              !isNaN(weekNewOrderGrowthRate) &&
              isFinite(weekNewOrderGrowthRate)
            ? weekNewOrderGrowthRate
            : dateType === '월별' &&
              !isNaN(monthNewOrderGrowthRate) &&
              isFinite(monthNewOrderGrowthRate)
            ? monthNewOrderGrowthRate
            : null}
          %
        </DataContainer>
      </div>
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
  margin-top: 37px;
`;

interface DataContainerProps {
  inDecrease: boolean | number;
}

const DataContainer = styled.li<DataContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23vw;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
  padding: 20px;
  font-size: 30px;
  color: ${(props) =>
    props.inDecrease
      ? '#1220ea'
      : props.inDecrease === 0
      ? GREY_LIGHT
      : '#da0000'};
`;

export default CompareDataComponent;
