import React, { useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import styled from 'styled-components';
// styles
import * as colors from 'styles/colors';
// type
import { DayConversionDetail } from 'api/dashboard/useConversionAPI';

interface Props {
  dayConversionDetails: DayConversionDetail[];
}
const Chart = ({ dayConversionDetails }: Props) => {
  const copiedData = dayConversionDetails.slice();
  const data = copiedData.sort(
    (a, b) =>
      new Date(a.dashboardDate).getTime() - new Date(b.dashboardDate).getTime(),
  );

  const [showFromUV, setShowFromUV] = useState(true);
  const [showToUV, setShowToUV] = useState(true);
  const [showConversionUv, setShowConversionUv] = useState(true);

  function getTooltipName(value, name) {
    switch (name) {
      case 'fromUv':
        return [value, '대상 PV'];
      case 'toUv':
        return [value, '전환 UV'];
      case 'conversionUv':
        return [`${value}%`, 'UV 전환율'];
      default:
        return '';
    }
  }

  function getLegendName(value: string) {
    switch (value) {
      case 'fromUv':
        return '대상 PV';
      case 'toUv':
        return '전환 UV';
      case 'conversionUv':
        return 'UV 전환율';
      default:
        return '';
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: 10,
          marginRight: 100,
        }}
      >
        <Button
          onClick={() => setShowFromUV((prev) => !prev)}
          isActive={showFromUV}
        >
          대상 PV
        </Button>
        <Button
          color="primary"
          onClick={() => setShowToUV((prev) => !prev)}
          isActive={showToUV}
        >
          대상 UV
        </Button>
        <Button
          color="primary"
          onClick={() => setShowConversionUv((prev) => !prev)}
          isActive={showConversionUv}
        >
          UV 전환율
        </Button>
      </div>
      <ResponsiveContainer width="99%" aspect={4}>
        <ComposedChart
          barGap={0}
          data={data}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dashboardDate" />
          <YAxis yAxisId="left" />
          {showConversionUv ? (
            <YAxis yAxisId="right" orientation="right" />
          ) : null}
          <Tooltip formatter={getTooltipName} />
          <Legend formatter={getLegendName} />
          {showFromUV ? (
            <Bar
              yAxisId="left"
              type="monotone"
              dataKey="fromUv"
              fill="#fd8e00"
            />
          ) : null}
          {showToUV ? (
            <Bar yAxisId="left" type="monotone" dataKey="toUv" fill="#83bc44" />
          ) : null}
          {showConversionUv ? (
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="conversionUv"
              stroke="#00abfb"
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;

const Button = styled.div`
  padding: 6px 12px;
  border: 1px solid ${colors.BORDER_GREY};
  border-radius: 8px;
  cursor: pointer;

  font-size: 14px;
  background-color: ${({ isActive }) =>
    isActive ? colors.FONT : colors.WHITE};
  color: ${({ isActive }) => (isActive ? colors.WHITE : colors.FONT)};

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${colors.GREY_LIGHT};
  }
`;
