import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import HeaderSection from './aComponents/HeaderSection';
import moment from 'moment';
import SelectDateSection from './aComponents/SelectDateSection';
import useKPIDataAPI from 'api/dashboard/useKPIDataAPI';
import CompareDataSection from './aComponents/CompareDataSection';

const UniqueKPIPresenter = () => {
  const [dateType, setDateType] = useState('월별');

  const dateTypePlaceholder =
    dateType === '월별'
      ? '월을 선택해주세요'
      : dateType === '주별'
      ? '주를 선택해주세요'
      : dateType === '일별'
      ? '날짜를 선택해 주세요'
      : null;

  const dateTypeClickHandle = (text) => {
    setDateType(text);
  };

  const today = new Date();
  const todayYear = today.getFullYear();
  const todaymonth = today.getMonth();
  const todayDate = today.getDate();
  const newDate = new Date(todayYear, todaymonth, todayDate);

  const initDate = newDate.getTime();
  const maxDate = initDate;
  const [date, setDate] = useState(initDate);

  const initWeek =
    newDate.getTime() - 60 * 60 * 24 * 1000 * (newDate.getDay() - 1);
  const [week, setWeek] = useState(initWeek);
  const maxWeek = initWeek + 60 * 60 * 24 * 1000 * 7;

  const initMonth = new Date(todayYear, todaymonth, 1).getTime();

  const [month, setMonth] = useState(initMonth);
  const maxMonth = new Date(todayYear, todaymonth, 1).getTime();

  const [secondMaxDate, setSecondMaxDate] = useState(
    new Date(moment(date).subtract(1, 'days').toDate()).getTime(),
  );
  const [secondDate, setSecondDate] = useState<any>(
    new Date(moment(date).subtract(1, 'days').toDate()).getTime(),
  );

  const [secondMaxWeek, setSecondMaxWeek] = useState(
    week - 60 * 60 * 24 * 1000 * 7 + 1,
  );
  const [secondWeek, setSecondWeek] = useState(
    week - 60 * 60 * 24 * 1000 * 7 + 1,
  );

  const [secondMaxMonth, setSecondMaxMonth] = useState<any>(
    new Date(moment(month).subtract(1, 'months').toDate()).getTime(),
  );

  const [monthSecond, setMonthSecond] = useState<any>(
    new Date(moment(month).subtract(1, 'months').toDate()).getTime(),
  );

  const defaultData = {
    uniqueVisit: {
      uniqueVisitSum: 0,
      results: [],
    },
    newUser: {
      newUserSum: 0,
      results: [],
    },
    newOrder: {
      newOrderSum: 0,
      results: [],
    },
  };

  const [monthData, setMonthData] = useState(defaultData);
  const [weekData, setWeekData] = useState(defaultData);
  const [dateData, setDateData] = useState(defaultData);
  const [secondMonthData, setSecondMonthData] = useState(defaultData);
  const [secondWeekData, setSecondWeekData] = useState(defaultData);
  const [secondDateData, setSecondDateData] = useState(defaultData);

  const { getUniqueKPIData } = useKPIDataAPI();

  const getDataHandle = async (dateForm, dateType, date) => {
    try {
      switch (dateType) {
        case 'date':
          setDate(date);
          break;
        case 'week':
          setWeek(date);
          break;
        case 'month':
          setMonth(date);
          break;
        case 'secondDate':
          setSecondDate(date);
          break;
        case 'secondWeek':
          setSecondWeek(date);
          break;
        case 'secondMonth':
          setMonthSecond(date);
          break;
      }
      const result = await getUniqueKPIData(dateForm);
      switch (dateType) {
        case 'date':
          setDateData(result);
          break;
        case 'week':
          setWeekData(result);
          break;
        case 'month':
          setMonthData(result);
          break;
        case 'secondDate':
          setSecondDateData(result);
          break;
        case 'secondWeek':
          setSecondWeekData(result);
          break;
        case 'secondMonth':
          setSecondMonthData(result);
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDateDataHandle = async (date, first) => {
    getDataHandle({ month: 0, week: 0, date }, 'date', date);
    if (!first) {
      const secondMax = date - 60 * 60 * 24 * 1000;
      setSecondDate(secondMax);
      setSecondMaxDate(secondMax);
      getSecondDateDataHandle(secondMax);
    }
  };

  const getWeekDataHandle = async (date, first) => {
    getDataHandle({ month: 0, week: date, date: 0 }, 'week', date);
    if (!first) {
      const secondMax = date - 60 * 60 * 24 * 1000 * 7;
      setSecondMaxWeek(secondMax);
      setSecondWeek(secondMax);
      getSecondWeekDataHandle(secondMax);
    }
  };

  const getMonthDataHandle = async (date, first) => {
    getDataHandle({ month: date, week: 0, date: 0 }, 'month', date);
    if (!first) {
      const secondMax = new Date(
        moment(date).subtract(1, 'months').toDate(),
      ).getTime();
      setSecondMaxMonth(secondMax);
      getSecondMonthDataHandle(secondMax);
      setMonthSecond(secondMax);
    }
  };

  const getSecondDateDataHandle = async (date) => {
    getDataHandle({ month: 0, week: 0, date }, 'secondDate', date);
  };

  const getSecondWeekDataHandle = async (date) => {
    getDataHandle({ month: 0, week: date, date: 0 }, 'secondWeek', date);
  };

  const getSecondMonthDataHandle = async (date) => {
    getDataHandle({ month: date, week: 0, date: 0 }, 'secondMonth', date);
  };

  useEffect(() => {
    getMonthDataHandle(month, true);
    getSecondMonthDataHandle(monthSecond);
    getWeekDataHandle(week, true);
    getSecondWeekDataHandle(secondWeek);
    getDateDataHandle(date, true);
    getSecondDateDataHandle(secondDate);
    setSecondMaxMonth(
      new Date(moment(month).subtract(1, 'months').toDate()).getTime(),
    );
  }, []);

  return (
    <Container>
      <HeaderSection dateType={dateType} onClick={dateTypeClickHandle} />
      <Wrapper>
        <SelectDateSection
          dateType={dateType}
          date={dateType === '월별' ? month : dateType === '주별' ? week : date}
          getData={
            dateType === '월별'
              ? getMonthDataHandle
              : dateType === '주별'
              ? getWeekDataHandle
              : getDateDataHandle
          }
          maxDate={
            dateType === '월별'
              ? maxMonth
              : dateType === '주별'
              ? maxWeek
              : maxDate
          }
          text={dateTypePlaceholder}
          data={
            dateType === '월별'
              ? monthData
              : dateType === '주별'
              ? weekData
              : dateData
          }
          type={
            dateType === '월별'
              ? 'month'
              : dateType === '주별'
              ? 'week'
              : 'date'
          }
        />
        <SelectDateSection
          dateType={dateType}
          date={
            dateType === '월별'
              ? monthSecond
              : dateType === '주별'
              ? secondWeek
              : secondDate
          }
          getData={
            dateType === '월별'
              ? getSecondMonthDataHandle
              : dateType === '주별'
              ? getSecondWeekDataHandle
              : getSecondDateDataHandle
          }
          maxDate={
            dateType === '월별'
              ? secondMaxMonth
              : dateType === '주별'
              ? secondMaxWeek
              : secondMaxDate
          }
          text={dateTypePlaceholder}
          data={
            dateType === '월별'
              ? secondMonthData
              : dateType === '주별'
              ? secondWeekData
              : secondDateData
          }
          type={
            dateType === '월별'
              ? 'secondMonth'
              : dateType === '주별'
              ? 'secondWeek'
              : 'secondDate'
          }
        />
      </Wrapper>
      <CompareDataSection
        dateType={dateType}
        monthData={monthData}
        weekData={weekData}
        dateData={dateData}
        secondMonthData={secondMonthData}
        secondWeekData={secondWeekData}
        secondDateData={secondDateData}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
`;

export default UniqueKPIPresenter;
