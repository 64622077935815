import React, { useState } from 'react';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// redux
import useAuthRedux from 'reducers/auth/useAuthRedux';
// common
import { toastify } from 'utils/common';
// hooks
import { useHistory } from 'react-router-dom';
// styles
import logo from '../assets/images/bibly_logo.png';

const Login = () => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();
  const { authDispatch } = useAuthRedux();

  const handleChangeId = (event) => {
    setId(event.currentTarget.value);
  };

  const handleChangePw = (event) => {
    setPassword(event.currentTarget.value);
  };

  const handleClickLogin = () => {
    //TODO 2000 -> 20
    if (id === 'ryencatchers_crew' && password === '123123') {
      authDispatch.setUser({ user: 'crew' });
      window.localStorage.setItem(
        'sessionDueTime',
        String(moment().add('20', 'minute').valueOf()),
      );
      window.localStorage.setItem('sessionGrade', 'crew');
      history.push('/');
      return;
    }

    if (id === 'ryencatchers_maintainer' && password === '123123') {
      authDispatch.setUser({ user: 'maintainer' });
      window.localStorage.setItem(
        'sessionDueTime',
        String(moment().add('20', 'minute').valueOf()),
      );
      window.localStorage.setItem('sessionGrade', 'maintainer');
      history.push('/');
      return;
    }

    toastify('error', '로그인 정보가 올바르지 않습니다.');
    return;
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="authentication-main">
          <div className="row">
            <div className="col-md-12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="text-center">
                        <img
                          src={logo}
                          alt=""
                          style={{
                            width: 60,
                            height: 60,
                            marginBottom: 20,
                            border: '1px solid #eee',
                            borderRadius: 30,
                          }}
                        />
                        <h4>비블리 관리자 앱</h4>
                      </div>
                      <form className="theme-form">
                        <div className="form-group">
                          <label className="col-form-label pt-0">아이디</label>
                          <input
                            className="form-control"
                            type="text"
                            name="id"
                            value={id}
                            onChange={handleChangeId}
                            autoFocus
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">비밀번호</label>
                          <input
                            className="form-control"
                            type="password"
                            onChange={handleChangePw}
                            name="pw"
                            value={password}
                          />
                        </div>
                        <div className="form-group form-row mt-3 mb-0">
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={handleClickLogin}
                          >
                            로그인
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
