import { API_URL } from 'api/useAxios';
import useAPI from '../useAPI';
import axios from 'axios';

export default function useBlibraryAPI() {
  const { getHandler, postHandler } = useAPI();

  const getBlibraryInfluencerAPI = async (id?: string) => {
    try {
      const res: any = await getHandler('/admin/blibrary/influencer');
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  const getBlibraryCurationAPI = async () => {
    try {
      const res: any = await getHandler('/admin/blibrary/curation');
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  const getBlibraryAPI = async (id) => {
    try {
      const res: any = await getHandler('/admin/blibrary', { id });
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  };

  const postBlibraryAPI = async (formData) => {
    try {
      const res = await axios.post(`${API_URL}/admin/blibrary`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data?.result;
    } catch (e) {
      console.log(e);
    }
  };

  const putBlibraryAPI = async (formData) => {
    try {
      const res = await axios.put(`${API_URL}/admin/blibrary`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data?.result;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    getBlibraryInfluencerAPI,
    getBlibraryCurationAPI,
    getBlibraryAPI,
    postBlibraryAPI,
    putBlibraryAPI,
  };
}
