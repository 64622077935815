import React, { useEffect, useMemo, useState } from 'react';
// apis
import useOrderAPI from 'api/store/useOrderAPI';
// styles
import { Table } from 'styles/index';
// types
import { OrderStatusCode } from 'api/types';

interface Props {
  selectedOrderStatusCode: OrderStatusCode | null;
  setSelectedOrderStatusCode: any;
  deleteIncludeYn: number;
}
export default function OrderStatusCodeList({
  selectedOrderStatusCode,
  setSelectedOrderStatusCode,
  deleteIncludeYn,
}: Props) {
  const columns = useMemo(
    () => [
      {
        Header: '주문 상태명',
        accessor: 'orderStatusName',
      },
      {
        Header: '주문 건수',
        accessor: 'orderCount',
      },
      {
        Header: '주문 이후 최대 지연',
        accessor: 'elaapsedInsert',
      },
      {
        Header: '최근 상태 변경 이후 최대 지연',
        accessor: 'elaapsedUpdate',
      },
    ],
    [],
  );

  const [orderStatusCodeList, setOrderStatusCodeList] = useState<
    OrderStatusCode[]
  >([]);

  const { getOrderStatusCodeListAPI } = useOrderAPI();

  async function handleGetOrderStatusCodeList() {
    try {
      const newOrderStatusList = await getOrderStatusCodeListAPI({
        deleteIncludeYn,
      });
      setOrderStatusCodeList(newOrderStatusList);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetOrderStatusCodeList();
  }, [deleteIncludeYn]);

  return (
    <div style={{ flex: 1 }}>
      <Table
        fullScreen
        columns={columns}
        data={orderStatusCodeList}
        onPress={setSelectedOrderStatusCode}
        activeValue={selectedOrderStatusCode?.orderStatus}
        type="orderStatusCode"
        style={{ marginBottom: 20 }}
      />
    </div>
  );
}
