import React, { useMemo } from 'react';
// styles
import { Table } from 'styles/index';

export default function OrderBookInfo({ orderBookInfo }) {
  const columns = useMemo(
    () => [
      {
        Header: '주문책 ID',
        accessor: 'orderBookId',
      },
      {
        Header: '책ID',
        accessor: 'bookId',
      },
      {
        Header: '책 상태',
        accessor: 'bookCondition',
      },
      {
        Header: '책 준비 상태',
        accessor: 'bookPreparationStatus',
      },
      {
        Header: '취소 알림톡 발송 수',
        accessor: 'orderCancelNotification',
      },
      {
        Header: '배송ID',
        accessor: 'deliveryId',
      },
      {
        Header: '리턴 여부',
        accessor: 'returnYn',
      },
      {
        Header: '입고 여부',
        accessor: 'stockInYn',
      },
      {
        Header: '주문 취소 여부',
        accessor: 'deleteYn',
      },
      {
        Header: '책 제목',
        accessor: 'bookName',
      },
      {
        Header: '출판사',
        accessor: 'publisherName',
      },
      {
        Header: 'ISBN',
        accessor: 'isbn13',
      },
    ],
    [],
  );

  if (orderBookInfo.length === 0) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: 700,
          marginBottom: 10,
          paddingLeft: 5,
        }}
      >
        주문 책 정보
      </div>
      <Table fullScreen columns={columns} data={orderBookInfo} />
    </div>
  );
}
