import React from 'react';
// styles
import { Table } from 'styles/index';
// types
import { DayTrafficDetail } from 'api/dashboard/useTrafficAPI';

interface Props {
  dayTrafficDetails: DayTrafficDetail[];
}
function TrafficDetailList({ dayTrafficDetails }: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: '날짜',
        accessor: 'dashboardDate',
      },
      {
        Header: 'UV',
        accessor: 'uv',
      },
      {
        Header: 'PV',
        accessor: 'pv',
      },
      {
        Header: '인당 PV',
        accessor: 'pvPerUser',
      },
    ],
    [],
  );

  return (
    <Table
      fullScreen
      columns={columns}
      data={dayTrafficDetails}
      style={{ marginBottom: 20 }}
    />
  );
}

export default TrafficDetailList;
