import {
  Home,
  Gift,
  Send,
  CornerUpLeft,
  Activity,
  BookOpen,
  Book,
  Archive,
  Clipboard,
  Cpu,
  Users,
  Code,
} from 'react-feather';

export const MENUITEMS = [
  {
    title: '대시보드',
    icon: Home,
    type: 'sub',
    path: '/',
    badgeType: 'primary',
    secure: 'crew',
    children: [
      {
        // init 페이지
        title: '트래픽',
        icon: Activity,
        type: 'link',
        path: '/traffic-board',
      },
      {
        title: '전환율',
        icon: Activity,
        type: 'link',
        path: '/conversion-board',
      },
      {
        title: '전환율(상세)',
        icon: Activity,
        type: 'link',
        path: '/conversion-analysis-board',
      },
      {
        title: 'KPI(전환율)',
        icon: Activity,
        type: 'link',
        path: '/unique-kpi',
      },
      {
        title: 'KPI(트래픽)',
        icon: Activity,
        type: 'link',
        path: '/traffic-kpi',
      },
      {
        title: 'Funnel',
        icon: Activity,
        type: 'link',
        path: '/Funnel',
      },
    ],
  },
  {
    title: '찾아가는 서점',
    icon: BookOpen,
    type: 'sub',
    badgeType: 'primary',
    active: false,
    secure: 'maintainer',
    children: [
      {
        title: '주문 리스트',
        icon: Clipboard,
        type: 'link',
        path: '/order',
      },
      {
        title: '책 준비',
        icon: Gift,
        type: 'link',
        path: '/preparation',
      },
      {
        title: '배송',
        icon: Send,
        type: 'link',
        path: '/delivery',
      },
      {
        title: '리턴',
        icon: CornerUpLeft,
        type: 'link',
        path: '/return',
      },
      {
        title: '리턴 입고',
        icon: Archive,
        type: 'link',
        path: '/return-stock',
      },
      {
        title: '재고 관리',
        icon: Archive,
        type: 'link',
        path: '/stock',
      },
      {
        title: '재고 입출고 내역',
        icon: Archive,
        type: 'link',
        path: '/stock-history',
      },
    ],
  },
  {
    title: '운영',
    icon: Cpu,
    type: 'sub',
    badgeType: 'primary',
    active: false,
    secure: 'crew',
    children: [
      {
        title: '유져 리스트',
        icon: Users,
        type: 'link',
        path: '/users',
      },
      {
        title: '배너 관리',
        icon: Users,
        type: 'link',
        path: '/banner',
      },
      {
        title: '비서재 관리',
        icon: Users,
        type: 'link',
        path: '/blibrary',
      },
      {
        title: '에디터`s Pick',
        icon: Users,
        type: 'link',
        path: '/editorsPick',
      },
      {
        title: '크리에이터 관리',
        icon: Users,
        type: 'link',
        path: '/creator',
      },
    ],
  },
  {
    title: '사내 서점',
    icon: Book,
    type: 'sub',
    badgeType: 'primary',
    active: false,
    secure: 'crew',
    children: [
      {
        title: '현황',
        icon: Clipboard,
        type: 'link',
        path: '/rcbookstore',
      },
      {
        title: '주문',
        icon: Gift,
        type: 'link',
        path: '/rcbookstore/order',
      },
    ],
  },
  {
    title: '머신러닝',
    icon: Code,
    type: 'sub',
    badgeType: 'primary',
    active: false,
    secure: 'crew',
    children: [
      {
        title: '디텍션',
        icon: Clipboard,
        type: 'link',
        path: '/detection',
      },
    ],
  },
];
