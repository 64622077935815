import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import Select from 'react-select';
import { IoMdSquareOutline, IoIosCheckbox } from 'react-icons/io';

interface Props {
  pickDate: Date;
  setPickDate: any;
  selectLandingType: string;
  setSelectLandingType: any;
  landingTypeData: any;
  deleteCheck: boolean;
  setDeleteCheck: any;
}

const CustomTabFilterBox: FunctionComponent<Props> = ({
  pickDate,
  setPickDate,
  selectLandingType,
  setSelectLandingType,
  landingTypeData,
  deleteCheck,
  setDeleteCheck,
}) => {
  return (
    <SlideDown className={'my-dropdown-slidedown'}>
      <SlideDownContainer>
        <DateContainer>
          <DateTitle>일자</DateTitle>
          <SelectDate
            selected={pickDate}
            dateFormat="yyyy-MM-dd"
            onChange={(date) => setPickDate(date)}
            placeholderText="클릭해주세요."
          />
        </DateContainer>
        <LandingTypeContainer>
          <LandingTypeTitle>랜딩페이지 타입</LandingTypeTitle>
          <div style={{ marginLeft: 140 }}>
            <Select
              value={selectLandingType}
              onChange={(selectedOption) =>
                setSelectLandingType(selectedOption)
              }
              options={landingTypeData}
            />
          </div>
        </LandingTypeContainer>
        <DeleteCheckContainer>
          <DeleteCheckTitle>삭제포함</DeleteCheckTitle>
          {deleteCheck ? (
            <CheckBoxWrapper onClick={() => setDeleteCheck(!deleteCheck)}>
              <IoIosCheckbox size={30} />
            </CheckBoxWrapper>
          ) : (
            <CheckBoxWrapper onClick={() => setDeleteCheck(!deleteCheck)}>
              <IoMdSquareOutline size={30} />
            </CheckBoxWrapper>
          )}
        </DeleteCheckContainer>
      </SlideDownContainer>
    </SlideDown>
  );
};

const SlideDownContainer = styled.div`
  width: 550px;
  height: 150px;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  overflow-x: hidden;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 40px;
  margin-left: 20px;
`;

const DateTitle = styled.div`
  margin-right: 10px;
  font-weight: 700;
  width: 130px;
`;

const SelectDate = styled(DatePicker)`
  width: 260px;
  height: 35px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

const LandingTypeContainer = styled.div`
  margin-top: 7px;
  width: 400px;
  align-items: center;
  flex-direction: row;
  position: absolute;
  margin-left: 20px;
`;

const LandingTypeTitle = styled.div`
  position: absolute;
  top: 10px;
  margin-right: 10px;
  font-weight: 700;
  width: 130px;
`;

const DeleteCheckContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 40px;
  margin-left: 20px;
  margin-top: 50px;
`;

const DeleteCheckTitle = styled.div`
  margin-right: 10px;
  font-weight: 700;
  width: 130px;
`;

const CheckBoxWrapper = styled.div`
  cursor: pointer;
`;

export default CustomTabFilterBox;
