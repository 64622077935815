import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import styled from 'styled-components';
// common
import { toastify } from '../../../utils/common';
// lib
import { handleCheckBookStatus } from 'lib/tools';
// api
import { setBookPreparationStatus } from '../../../api/store/preparation';
// types
import { Book, BookStatus } from '../../../api/store/preparation';
import { RED } from '../../../styles/colors';

interface Props {
  bookInfo: Book;
  isFirstItem: boolean;
  isLastItem: boolean;
  bookListLength: number;
  bookStatusList: BookStatus[];
  onGetBookPreparationList: () => Promise<void>;
  onSetSelectList: (selectInfo: any) => void;
  setInvoiceNumbers: any;
  setBookListForCopy: any;
  orderIndex: number;
}

export default function PreparationBookListItem({
  bookInfo,
  bookStatusList,
  isFirstItem,
  isLastItem,
  bookListLength,
  onGetBookPreparationList,
  onSetSelectList,
  setInvoiceNumbers,
  setBookListForCopy,
  orderIndex,
}: Props) {
  const [selectStatus, setSelectStatus] = useState(0);
  const [isCheckPreparation, setIsCheckPreparation] = useState(false);
  const [isCheckCopy, setIsCheckCopy] = useState(false);
  const [stockListForSelect, setStockListForSelect]: any = useState([]);

  const [invoiceNumber, setInvoiceNumber] = useState('');

  const handleBlurInvoiceNumber = () => {
    const invoiceNumberRemovedGap = invoiceNumber.replace(/\s/g, '');
    setInvoiceNumbers((prev) => {
      return {
        ...prev,
        [bookInfo.orderId]: invoiceNumberRemovedGap,
      };
    });
  };

  useEffect(() => {
    setSelectStatus(bookInfo.bookPreparationStatus);
    setIsCheckPreparation(false);
  }, [bookInfo]);

  // 내용 복사 체크박스
  const handleCopyPreparation = () => {
    setBookListForCopy((prev) => {
      const copiedPrev = prev.slice();
      const targetIndex = copiedPrev.findIndex(
        (p) => p.bookId === bookInfo.bookId && p.orderId === bookInfo.orderId,
      );
      if (targetIndex < 0) {
        return [
          ...prev,
          {
            copyTextOrder: bookInfo.copyTextOrder,
            copyTextSend: bookInfo.copyTextSend,
            orderId: bookInfo.orderId,
            bookId: bookInfo.bookId,
            orderIndex,
          },
        ];
      } else {
        copiedPrev.splice(targetIndex, 1);
        return copiedPrev;
      }
    });

    setIsCheckCopy((prev) => !prev);
  };

  // 주문완료 체크박스
  const handleCheckPreparation = () => {
    const data = {
      bookPreparationStatus: bookInfo.bookPreparationStatus,
      orderBook_id: bookInfo.orderBookId,
      user_id: bookInfo.user_id,
      order_id: bookInfo.orderId,
    };

    setIsCheckPreparation(!Boolean(isCheckPreparation));
    onSetSelectList(data);
  };

  // 주문 책 준비 상태 변경
  const _setBookPreparationStatus = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    try {
      const selectStatus = Number(e.target.value);

      if (selectStatus === 120) {
        if (bookInfo.stock) {
          const stockList = bookInfo.stock.split(', ');
          setStockListForSelect(stockList);
        } else {
          toastify('error', '재고지가 없습니다 확인해주세요.');
        }
      } else {
        const data = {
          orderBook_id: bookInfo.orderBookId,
          book_preparation_status: selectStatus,
        };
        await setBookPreparationStatus(data);
        await onGetBookPreparationList();
      }
    } catch (error) {
      console.error('_setBookPreparationStatus ::', error);
    }
  };

  // 주문 책 준비 상태 변경
  const _setBookPreparationStatus120 = async (selectedStock: string) => {
    try {
      const locationCode = selectedStock.split('(')[0];
      const data = {
        orderBook_id: bookInfo.orderBookId,
        book_preparation_status: 120,
        locationCode,
      };

      await setBookPreparationStatus(data);
      await onGetBookPreparationList();
    } catch (error) {
      console.error('_setBookPreparationStatus ::', error);
    }
  };

  function getSelectBackgroundColor() {
    switch (bookInfo.bookPreparationStatus) {
      case 103:
        return '#EDAE49';
      case 104:
        return '#FCD3DE';
      case 105:
        return '#A5D0A8';
      case 106:
        return '#72A1E5';

      default:
        return 'transparent';
    }
  }

  const customStyles = {
    content: {
      maxWidth: '30%',
      maxHeight: '70%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={stockListForSelect.length !== 0}
        style={customStyles}
        onRequestClose={() => {
          setStockListForSelect([]);
        }}
      >
        <div style={{ marginBottom: 10 }}>재고지를 선택해주세요</div>
        {stockListForSelect.length !== 0 &&
          stockListForSelect.map((item) => (
            <Button
              outline
              key={item}
              color="secondary"
              onClick={() => {
                _setBookPreparationStatus120(item);
                setStockListForSelect([]);
              }}
              style={{ marginRight: 10, marginBottom: 10 }}
            >
              {item}
            </Button>
          ))}
      </Modal>
      <tr
        order-id={bookInfo.orderId}
        style={{
          backgroundColor: Boolean(bookInfo.deleteYn)
            ? 'rgb(244,81,30, 0.4)'
            : Boolean(bookInfo.deliveryId)
            ? '#ccc'
            : undefined,
          borderBottom: isLastItem ? '2px solid #222' : undefined,
        }}
      >
        {isFirstItem ? (
          <td
            className="text-center"
            style={{
              width: '5%',
              borderRight: '1px solid #eee',
              borderBottom: '2px solid #222',
              backgroundColor: 'white',
            }}
            rowSpan={bookListLength}
          >
            {bookInfo.orderId}
            <br />
            <strong>{bookInfo?.receiver_name || ''}</strong>
          </td>
        ) : null}
        {isFirstItem ? (
          <td
            style={{
              width: '10%',
              backgroundColor: 'white',
              borderLeft: '1px solid #eee',
              borderRight: '1px solid #eee',
              borderBottom: '2px solid #222',
            }}
            rowSpan={bookListLength}
          >
            <Input
              className="form-control"
              type="text"
              placeholder="송장번호"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              onBlur={handleBlurInvoiceNumber}
            />
          </td>
        ) : null}
        <td style={{ width: '10%' }}>{bookInfo.invoiceNumber}</td>
        <td className="text-center" style={{ width: '5%' }}>
          <Checkbox
            className="checkbox_animated"
            type="checkbox"
            checked={isCheckCopy}
            onChange={handleCopyPreparation}
          />
        </td>
        <td style={{ width: '18%' }}>{bookInfo.bookName}</td>
        <td style={{ width: '6%' }}>
          {handleCheckBookStatus(bookInfo.bookCondition)}
        </td>
        <td style={{ width: '10%' }}>{bookInfo.stock}</td>

        <td style={{ width: '13%' }}>
          <select
            className="form-control digits"
            name="selectStatus"
            value={selectStatus}
            onChange={_setBookPreparationStatus}
            style={{
              backgroundColor: getSelectBackgroundColor(),
            }}
          >
            {bookStatusList.length !== 0 &&
              bookStatusList.map((item) => (
                <option key={item.statusCode} value={item.statusCode}>
                  {item.statusName}
                </option>
              ))}
          </select>
        </td>

        <td className="text-center" style={{ width: '5%' }}>
          <Checkbox
            className="checkbox_animated"
            type="checkbox"
            checked={isCheckPreparation}
            onChange={handleCheckPreparation}
            style={{
              backgroundColor: Boolean(bookInfo.deliveryId)
                ? 'grey'
                : undefined,
            }}
          />
        </td>
        <td
          className={'late-alim-talk'}
          style={{
            width: '8%',
            color: Boolean(bookInfo.lateAlimTalk) ? RED : 'black',
            fontWeight: Boolean(bookInfo.lateAlimTalk) ? 700 : 500,
          }}
        >
          {!Boolean(bookInfo.lateAlimTalk) ? '미전송' : '전송'}
        </td>
        <td
          className={
            Boolean(bookInfo.deliveryId)
              ? ['f-w-900', 'font-primary'].join(' ')
              : undefined
          }
          style={{ width: '8%' }}
        >
          {Boolean(bookInfo.deliveryId) ? '처리' : '미처리'}
        </td>
      </tr>
    </>
  );
}

const Checkbox = styled.input`
  &::after {
    background: ${({ disabled }) => (disabled ? '#ccc' : undefined)};
  }
`;

const Input = styled.input`
  &::placeholder {
    font-size: 15px;
    color: #ccc;
  }
`;
