import React, { useMemo } from 'react';
// styles
import { Table } from 'styles/index';

export default function OrderBookHistory({ orderBookHistory }) {
  const columns = useMemo(
    () => [
      {
        Header: '히스토리ID',
        accessor: 'historyId',
      },
      {
        Header: '주문책ID',
        accessor: 'orderBookId',
      },
      {
        Header: '주문ID',
        accessor: 'userId',
      },
      {
        Header: '유저ID',
        accessor: 'orderId',
      },
      {
        Header: '책ID',
        accessor: 'bookId',
      },
      {
        Header: '책제목',
        accessor: 'bookName',
      },
      {
        Header: '책상태',
        accessor: 'bookCondition',
      },
      {
        Header: '주문 책 순서',
        accessor: 'ordinalNo',
      },
      {
        Header: '주문 책 상태 코드',
        accessor: 'bookPreparationStatus',
      },
      {
        Header: '주문 책 상태명',
        accessor: 'statusName',
      },
      {
        Header: '취소 알림톡 발송수',
        accessor: 'orderCancelNotification',
      },
      {
        Header: '배송ID',
        accessor: 'deliveryId',
      },
      {
        Header: '리턴 여부',
        accessor: 'returnYn',
      },
      {
        Header: '입고 여부',
        accessor: 'stockInYn',
      },
      {
        Header: '등록 일시',
        accessor: 'insertDate',
      },
      {
        Header: '최근 변경 일시',
        accessor: 'updateDate',
      },
      {
        Header: '주문 취소 여부',
        accessor: 'deleteYn',
      },
    ],
    [],
  );

  if (orderBookHistory.length === 0) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: 700,
          marginBottom: 10,
          paddingLeft: 5,
        }}
      >
        책 준비 히스토리
      </div>
      <Table fullScreen columns={columns} data={orderBookHistory} />
    </div>
  );
}
