import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  data: any;
  populationName: string;
  sampleName: string;
  ratioName: string;
}

const TrafficTable: FunctionComponent<Props> = ({
  data,
  populationName,
  sampleName,
  ratioName,
}) => {
  const columns = ['일자', populationName, sampleName, ratioName];

  return (
    <Table>
      <thead>
        <ColumnTableRow>
          {columns.map((column, index) => (
            <ColumnTableHeader key={column} index={index}>
              {column}
            </ColumnTableHeader>
          ))}
        </ColumnTableRow>
      </thead>
      <tbody>
        {data.map(({ dateColumn, population, sample, ratio }, index) => (
          <BodyTableRow key={index}>
            <BodyTableData type={'date'}>{dateColumn}</BodyTableData>
            <BodyTableData>{!population ? 0 : population}</BodyTableData>
            <BodyTableData>{!sample ? 0 : sample}</BodyTableData>
            <BodyTableData>
              {!ratio ? 0 : (parseFloat(ratio) * 100).toFixed(1)}%
            </BodyTableData>
          </BodyTableRow>
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  margin-right: 20px;
  border-collapse: collapse;
`;

const ColumnTableRow = styled.tr`
  display: flex;
  flex-direction: row;
`;

interface ColumnTableHeaderProps {
  index: number;
}
const ColumnTableHeader = styled.th<ColumnTableHeaderProps>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.index === 0 ? 100 : 70)}px;
  border-bottom: 1px solid #444444;
  border-top: 1px solid #444444;
  border-right: 1px solid #444444;
  ${(props) =>
    props.index === 0 &&
    css`
      border-left: 1px solid #444444;
    `}
`;

const BodyTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #444444;
`;

interface BodyTableDataProps {
  type?: string;
}

const BodyTableData = styled.td<BodyTableDataProps>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.type === 'date' ? 100 : 70)}px;
  ${(props) =>
    props.type === 'date' &&
    css`
      border-left: 1px solid #444444;
    `}
  border-right: 1px solid #444444;
`;

export default TrafficTable;
