import React, { useState, useEffect } from 'react';
// api
import { DeliveryInfo } from '../../../api/store/delivery';
// common
import { getHyphenWithPhoneNumber } from 'utils/common';

interface Props {
  deliveryInfo: DeliveryInfo;
  onSetSelectList: (selectInfo: DeliveryInfo) => void;
  onSetSelectInput: (selectInfo: DeliveryInfo) => void;
  onSetDate: (selectInfo: DeliveryInfo) => void;
}

export default function Delivery({
  deliveryInfo,
  onSetSelectList,
  onSetSelectInput,
  onSetDate,
}: Props) {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [isCheckDelivery, setIsCheckDelivery] = useState(false);
  const [returnDate, setReturnDate] = useState<any>(new Date());

  useEffect(() => {
    setReturnDate(
      deliveryInfo.return_guide_dt ? deliveryInfo.return_guide_dt : new Date(),
    );
    setInvoiceNumber(
      Boolean(deliveryInfo.invoiceNumber) ? deliveryInfo.invoiceNumber : '',
    );
    setIsCheckDelivery(false);
  }, [deliveryInfo]);

  const handleCheckDelivery = () => {
    let data = deliveryInfo;
    data = { ...data, invoiceNumber, return_guide_dt: returnDate };
    setIsCheckDelivery(!isCheckDelivery);
    onSetSelectList(data);
  };

  const handleBlurInvoiceNumber = () => {
    let data = deliveryInfo;
    data = { ...data, invoiceNumber };
    onSetSelectInput(data);
  };

  const handleChangeDate = (date: any) => {
    let data = deliveryInfo;
    data = { ...data, return_guide_dt: date };
    setReturnDate(date);
    onSetDate(data);
  };

  const CheckReturnDate = () => {
    if (
      Boolean(deliveryInfo.return_talk_cnt) &&
      deliveryInfo.return_guide_dt !== null &&
      new Date(deliveryInfo.return_guide_dt) < new Date()
    ) {
      return 'rgb(244,81,30, 0.6)';
    } else if (Boolean(deliveryInfo.return_talk_cnt)) {
      return '#ffd6c9';
    } else if (Boolean(deliveryInfo.deliveryEndNoticeYn)) {
      return '#d3ebd3';
    } else if (Boolean(deliveryInfo.deliveryStartNoticeYn)) {
      return '#c8e4fb';
    } else {
      return undefined;
    }
  };

  return (
    <tr
      style={{
        backgroundColor: CheckReturnDate(),
      }}
    >
      <td className="text-center">{deliveryInfo.orderId}</td>
      <td>{deliveryInfo.receiver_name}</td>
      <td>{getHyphenWithPhoneNumber(deliveryInfo.receiver_phone)}</td>
      <td>{deliveryInfo.address}</td>
      <td>{deliveryInfo.delivery_request}</td>
      <td>
        <input
          className="form-control"
          type="text"
          placeholder="송장번호"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          onBlur={handleBlurInvoiceNumber}
        />
      </td>
      <td>{deliveryInfo.invoiceStatus}</td>
      <td className="text-center">{deliveryInfo.return_talk_cnt}</td>

      <td>
        {deliveryInfo &&
          deliveryInfo.bookList &&
          deliveryInfo.bookList.length > 0 &&
          deliveryInfo.bookList.map((item, index) => (
            <p style={{ marginBottom: 0 }} key={item.orderBookId}>
              {index + 1}. {item.bookName}
            </p>
          ))}
      </td>
      <td className="text-start">
        <input
          className="checkbox_animated"
          type="checkbox"
          checked={isCheckDelivery}
          onChange={handleCheckDelivery}
        />
      </td>
    </tr>
  );
}
