import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  image: string | null;
  onChange: () => void;
  onClick: (fileObj: any) => void;
  id: string;
}

const RectangleImageForm: FunctionComponent<Props> = ({
  title,
  image,
  onChange,
  onClick,
  id,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <ImageWrapper>
        {image ? (
          <Image src={image} onClick={() => onChange()} />
        ) : (
          <NoneImage onClick={() => onChange()} />
        )}
        <input
          style={{
            width: 300,
            borderRadius: 75,
            position: 'absolute',
            opacity: 0,
            bottom: 0,
            left: 0,
            zIndex: -1,
          }}
          type="file"
          id={id}
          accept=".jpg, .jpeg, .png"
          onChange={onClick}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
      </ImageWrapper>
    </Container>
  );
};

const Container = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 100px;
`;

const Title = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 30vw;
`;

const Image = styled.img`
  display: flex;
  width: 300px;
  height: 150px;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  cursor: pointer;
`;

const NoneImage = styled.div`
  display: flex;
  width: 300px;
  height: 150px;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  cursor: pointer;
`;

export default RectangleImageForm;
