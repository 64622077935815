import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
// styles
import Loader from 'components/common/loader';
import { X } from 'react-feather';
import * as colors from 'styles/colors';

interface Props {
  isVisible: boolean;
  close: any;
}

export default function ConfirmModal({ isVisible, close }: Props) {
  const [loading, setLoading] = useState(false);
  async function handleClose() {
    close();
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
        },
      }}
      onRequestClose={handleClose}
    >
      <Loader isLoading={loading} />
      <Container>
        <h2>알림톡 발송 실패 내역</h2>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;

  h2 {
    color: ${colors.ORANGE};
    margin-bottom: 24px;
  }
`;
