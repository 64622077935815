import React, { useMemo } from 'react';
// styles
import { Table } from 'styles/index';

export default function OrderHistory({ orderHistory }) {
  const columns = useMemo(
    () => [
      {
        Header: '히스토리ID',
        accessor: 'historyId',
      },
      {
        Header: '주문ID',
        accessor: 'orderId',
      },
      {
        Header: '유저ID',
        accessor: 'userId',
      },
      {
        Header: '배송지ID',
        accessor: 'addressId',
      },
      {
        Header: '결제 카드ID',
        accessor: 'cardId',
      },
      {
        Header: '주문자명',
        accessor: 'ordererName',
      },
      {
        Header: '주문 상태 코드',
        accessor: 'orderStatus',
      },
      {
        Header: '주문 상태명',
        accessor: 'statusName',
      },
      {
        Header: '주문 토큰',
        accessor: 'orderToken',
      },
      {
        Header: '주문 일시',
        accessor: 'insertDate',
      },
      {
        Header: '주문 상태 최근 변경 일시',
        accessor: 'updateDate',
      },
      {
        Header: '주문 취소 여부',
        accessor: 'deleteYn',
      },
      {
        Header: '주문 취소 사유',
        accessor: 'deleteReason',
      },
    ],
    [],
  );

  if (orderHistory.length === 0) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: 700,
          marginBottom: 10,
          paddingLeft: 5,
        }}
      >
        주문 히스토리
      </div>
      <Table fullScreen columns={columns} data={orderHistory} />
    </div>
  );
}
