import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_LIGHT } from 'styles/colors';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  onClick: () => void;
  title: string;
  value: string | number | null;
}

const BookSearchBtnInputForm: FunctionComponent<Props> = ({
  onClick,
  title,
  value,
}) => {
  return (
    <FormWrapper>
      <FormText>{title}</FormText>
      <InputWrapper>
        <TextForm onClick={onClick}>{value}</TextForm>
      </InputWrapper>
    </FormWrapper>
  );
};

const FormWrapper = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-left: 100px;
`;

const FormText = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const TextForm = styled.div`
  display: flex;
  width: 30vw;
  height: 40px;
  align-items: center;
  border: solid 1px ${GREY_LIGHT};
  border-radius: 5px;
  padding-left: 20px;
`;

export default BookSearchBtnInputForm;
