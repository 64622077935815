import { GREY_LIGHT } from '../../../../../styles/colors';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import DatePickerForm from '../../../aComponents/DatePickerForm';
import TextInputForm from '../../../aComponents/TextInputForm';
import SelectMenuForm from '../../../aComponents/SelectMenuForm';
import useBannerAPI from 'api/operation/useBannerAPI';
import { landingTypeUrlList, landingTypeList } from '../../BannerConstant';
import ButtonComponent from 'components/common/ButtonComponent';

const UpdateBanner = () => {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [query, setQuery] = useState('');
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImagePath, setBannerImagePath] = useState(null);
  const [priority, setPriority] = useState(0);
  const [landingType, setLandingType] = useState<any>('');
  const [landingTypeUrl, setLandingTypeUrl] = useState<any>('');

  const { getBannerAPI, putBannerAPI } = useBannerAPI();
  const history = useHistory();
  const location = useLocation();
  const bannerId = parseInt(location.pathname.split('banner/')[1]);

  const getBannerData = async () => {
    const {
      title,
      postStartDate,
      postEndDate,
      bannerImage,
      query,
      priority,
      landingType,
      landingPageUrl,
    } = await getBannerAPI(bannerId);

    setTitle(title);
    setStartDate(new Date(postStartDate));
    setEndDate(new Date(postEndDate));
    setQuery(query);
    setBannerImage(bannerImage);
    setPriority(priority);
    setLandingType({ value: landingType, label: landingType });
    setLandingTypeUrl({ value: landingPageUrl, label: landingPageUrl });
  };

  const onChangeTitle = (text) => {
    const textValue = text.target.value;
    setTitle(textValue);
  };

  const onChangeQuery = (text) => {
    const textValue = text.target.value;
    setQuery(textValue);
  };

  const onChangePriority = (text) => {
    const textValue = text.target.value;
    setPriority(textValue);
  };

  const onChangeImage = (fileObj: any) => {
    let filePath = fileObj.target.files[0];
    if (filePath !== undefined) {
      let reader = new FileReader();
      reader.onload = function (event: any) {
        setBannerImage(event.target.result);
        setBannerImagePath(filePath);
      };
      reader.readAsDataURL(filePath);
    }
  };

  const imageClickHandle = () => {
    const element = document.getElementById('fileInput');
    element?.click();
  };

  const updateBannerHandle = async () => {
    try {
      const landing_type = landingType?.value;
      const landing_page_url = landingTypeUrl?.value;

      const formData: any = new FormData();
      if (bannerImagePath) {
        formData.append('file', bannerImagePath);
      }

      await putBannerAPI({
        formData,
        banner_id: bannerId,
        title,
        landing_type,
        landing_page_url,
        query,
        post_start_date: new Date(startDate).getTime(),
        post_end_date: new Date(endDate).getTime(),
        priority,
      });
    } catch (e) {
      console.log(e);
    } finally {
      history.push({ pathname: '/banner' });
    }
  };

  useEffect(() => {
    getBannerData();
  }, []);

  return (
    <Container>
      <BannerImageContainer style={{ position: 'relative' }}>
        <BannerImage
          id={'inputImage'}
          onClick={() => imageClickHandle()}
          src={bannerImage}
        />
        <BannerImageInput
          type="file"
          id="fileInput"
          accept=".jpg, .jpeg, .png"
          onChange={onChangeImage}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
      </BannerImageContainer>
      <Wrapper>
        <BorderTop />
        <TextInputForm
          onChange={onChangeTitle}
          title={'제목'}
          value={title}
          type={'text'}
        />
        <BorderTop />
        <TextInputForm
          onChange={onChangeQuery}
          title={'쿼리'}
          value={query}
          type={'text'}
        />

        <BorderTop />
        <DatePickerForm
          title={'시작일자'}
          date={startDate}
          setDate={setStartDate}
        />
        <BorderTop />
        <DatePickerForm
          title={'완료일자'}
          date={endDate}
          setDate={setEndDate}
        />
        <BorderTop />
        <SelectMenuForm
          title={'랜딩 타입'}
          data={landingTypeList}
          selectType={landingType}
          setSelectType={setLandingType}
        />
        <BorderTop />
        <SelectMenuForm
          title={'랜딩 타입 Url'}
          data={landingTypeUrlList}
          selectType={landingTypeUrl}
          setSelectType={setLandingTypeUrl}
        />
        <BorderTop />
        <TextInputForm
          onChange={onChangePriority}
          title={'우선순위'}
          value={priority}
          type={'number'}
        />
        <BorderTop />
      </Wrapper>
      <ButtonWrapper>
        <ButtonComponent title={'수정'} onClick={() => updateBannerHandle()} />
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
`;

const BannerImageContainer = styled.div`
  position: relative;
`;

const BannerImage = styled.img`
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  margin-top: 50px;
  cursor: pointer;
`;

const BannerImageInput = styled.input`
  position: absolute;
  opacity: 0;
  bottom: 30px;
  left: 0;
  z-index: -1;
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  margin-top: 50px;
  padding: 0px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;
const BorderTop = styled.div`
  width: 100%;
  border-top: solid 2px ${GREY_LIGHT};
  margin-top: 40px;
  margin-bottom: 20px;
  opacity: 0.2;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export default UpdateBanner;
