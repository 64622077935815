import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { title } from './EditorsPickConstants';
import { IoMdAdd } from 'react-icons/io';
import { BLACK_PRIMARY } from 'styles/colors';
import { useHistory } from 'react-router';
import useEditorsPickAPI from '../../../api/operation/useEditorsPickAPI';
import { EditorsPick } from 'api/types';
import EditorsPickInfo from './aComponents/EditorsPickInfo';
import { Flex } from 'styles/index';
import Pagination from '@material-ui/lab/Pagination';

const EditorsPickPresenter = () => {
  const EDITORS_PICK_LENGTH = 30;
  const widowWidth = window.screen.width;
  const imageWidth = widowWidth / 7;
  const [editorsPickData, setEditorsPickData] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [page, setPage] = useState(1);

  const history = useHistory();

  const { getEditorsPickAPI } = useEditorsPickAPI();

  const createCurationHandle = () => {
    history.push({ pathname: '/create-editorsPick' });
  };

  const getEditorsPickData = async () => {
    try {
      const data = await getEditorsPickAPI({ page, id: null });
      setEditorsPickData(data.result);
      setTotalCnt(data.totalCnt);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getEditorsPickData();
  }, [page]);

  return (
    <>
      <HeaderWrapper>
        <>
          <Title>{title}</Title>
          <HeaderTabContainer onClick={() => createCurationHandle()}>
            <IoMdAdd size={30} color={'white'} />
          </HeaderTabContainer>
        </>
      </HeaderWrapper>
      <Container>
        <EditorsPickContainer width={imageWidth}>
          {editorsPickData.length > 0
            ? editorsPickData.map((editorsPick: EditorsPick) => (
                <EditorsPickInfo
                  key={editorsPick.curationId}
                  {...editorsPick}
                  imageWidth={imageWidth}
                />
              ))
            : null}
        </EditorsPickContainer>
      </Container>
      <Flex style={{ justifyContent: 'center' }}>
        <StyledPagination
          count={Math.ceil(totalCnt / EDITORS_PICK_LENGTH)}
          onChange={(_, page) => setPage(page)}
        />
      </Flex>
    </>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: 'jua';
`;

const HeaderTabContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: ${BLACK_PRIMARY};
  border-radius: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px 0 20px 0px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
`;

interface EditorsPickContainerProps {
  width: number;
}

const EditorsPickContainer = styled.div<EditorsPickContainerProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${(props) => props.width}px);
  justify-content: space-between;
  grid-gap: 20px;
`;

const StyledPagination = styled(Pagination)`
  button {
    outline: none;
  }
`;

export default EditorsPickPresenter;
