import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
// components
import Breadcrumb from 'components/common/breadcrumb';
// common
import SearchBook from 'components/common/modals/SearchBook';
// apis
import useEmployeeAPI from 'api/etc/useEmployeeAPI';
// styles
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from 'reactstrap';
import { Flex } from 'styles/index';
import * as colors from 'styles/colors';
import { X } from 'react-feather';
// types
import { Book } from 'api/types';

const addresses = [
  {
    value: '라캐-논현점',
    label: '라캐-논현점',
  },
  {
    value: '라캐-강남점#9',
    label: '라캐-강남점#9',
  },
  {
    value: '라캐-강남점#10',
    label: '라캐-강남점#10',
  },
];

export default function Order() {
  const [addressName, setAddressName] = useState('라캐-논현점');
  const [businessRelatedYn, setBusinessRelatedYn] = useState(1);
  const [ordererName, setOrdererName] = useState('');
  const [ordererPhone, setOrdererPhone] = useState('');
  const [books, setBooks] = useState<Book[]>([]);

  const [openSearchBookModal, setOpenSearchBookModal] = useState(false);

  const { createEmployeeBookOrderAPI } = useEmployeeAPI();

  function handleRemoveBook(book: Book) {
    const copiedBooks = books.slice();
    const targetIndex = copiedBooks.findIndex((b) => b.bookId === book.bookId);
    if (targetIndex !== -1) {
      copiedBooks.splice(targetIndex, 1);
    }
    setBooks(copiedBooks);
  }

  function handleReset() {
    setBooks([]);
    setOrdererName('');
    setOrdererPhone('');
  }

  async function handleCreateEmployeeBookOrder() {
    try {
      const bookIdList = books.map((b) => b.bookId);
      await createEmployeeBookOrderAPI({
        bookIdList,
        ordererName,
        addressName,
        ordererPhone,
        businessRelatedYn,
      });
      handleReset();
    } catch (error) {
      console.log(error);
    }
  }

  const CustomRadio = withStyles({
    root: {
      color: '#222',
      '&$checked': {
        color: '#222',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  return (
    <Fragment>
      <SearchBook
        isVisible={openSearchBookModal}
        onPress={setBooks}
        selectedBooks={books}
        close={() => setOpenSearchBookModal(false)}
      />
      <Flex style={{ alignItems: 'center' }}>
        <Breadcrumb title="주문" parent="사내 서점" />
        <Button
          disabled={!ordererName || !ordererPhone || books.length === 0}
          color="primary"
          onClick={handleCreateEmployeeBookOrder}
          style={{ marginRight: 15 }}
        >
          책 주문하기
        </Button>
      </Flex>
      <div style={{ flex: 1, padding: 15 }}>
        <FormLabel component="legend">주문직원명</FormLabel>
        <TextField
          id="ordererName"
          variant="outlined"
          onChange={(e) => setOrdererName(e.target.value)}
          fullWidth
          placeholder="홍길동"
          style={{ marginBottom: 20 }}
        />

        <FormLabel component="legend">연락처</FormLabel>
        <TextField
          id="ordererPhone"
          variant="outlined"
          onChange={(e) => setOrdererPhone(e.target.value)}
          placeholder="01012345678"
          fullWidth
          style={{ marginBottom: 20 }}
        />

        <FormLabel component="legend">주문할 책</FormLabel>
        {books.length > 0 ? (
          <SelectBook>
            {books.map((book) => (
              <div key={book?.bookId || ''} style={{ position: 'relative' }}>
                <X
                  width={8}
                  height={8}
                  className="icon"
                  onClick={() => handleRemoveBook(book)}
                />
                <img src={book?.bookImage} />
              </div>
            ))}
            <Plus onClick={() => setOpenSearchBookModal(true)}>+</Plus>
          </SelectBook>
        ) : (
          <SelectBook>
            <Plus onClick={() => setOpenSearchBookModal(true)}>+</Plus>
          </SelectBook>
        )}

        <FormLabel component="legend">지점</FormLabel>
        <TextField
          id="addressName"
          select
          fullWidth
          value={addressName}
          onChange={(e) => setAddressName(e.target.value)}
          variant="outlined"
          style={{ marginBottom: 20 }}
        >
          {addresses.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <FormLabel component="legend">종류</FormLabel>
        <RadioGroup
          aria-label="businessRelatedYn"
          name="businessRelatedYn"
          value={businessRelatedYn}
          onChange={(e) => setBusinessRelatedYn(Number(e.target.value))}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel
            value={1}
            control={<CustomRadio />}
            label="업무도서"
          />
          <FormControlLabel
            value={2}
            control={<CustomRadio />}
            label="비업무도서"
          />
        </RadioGroup>
      </div>
    </Fragment>
  );
}

const SelectBook = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(195, 195, 195);
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  overflow-x: auto;

  img {
    width: 80px;
    height: 116px;
    margin-right: 12px;
    border-radius: 8px;
    border: 1px solid ${colors.IMG_BORDER_GREY};
    background-color: ${colors.IMG_BACKGROUND_GREY};
    overflow: hidden;
  }

  .icon {
    background-color: ${colors.BACKGROUND_GREY};
    border: 1px solid ${colors.BORDER_GREY};
    width: 24px;
    height: 24px;
    position: absolute;
    top: -6px;
    right: 4px;
    border-radius: 20px;
    cursor: pointer;
  }
`;

const Plus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 116px;
  border-radius: 8px;
  border: 1px solid ${colors.BORDER_GREY};
  background-color: ${colors.BACKGROUND_GREY};
  overflow: hidden;
  color: rgba(195, 195, 195);
  font-size: 18px;
  cursor: pointer;
`;
