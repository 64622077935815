import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';
// redux
import { Provider } from 'react-redux';
import store from './store/index';
import App from './components/app';
import useAuthRedux from 'reducers/auth/useAuthRedux';
// components
// auth
import Login from './pages/login';
// dashboard
import HomeBoard from './components/Dashboard/index';
import TrafficBoard from './components/Dashboard/TrafficBoard';
import ConversionBoard from './components/Dashboard/ConversionBoard';
import ConversionAnalysisBoard from './components/Dashboard/ConversionAnalysisBoard';
import UniqueKPI from './components/Dashboard/UniqueKPI';
import TrafficKPI from './components/Dashboard/TrafficKPI';
import Funnel from './components/Dashboard/Funnel';
// store
import Order from './components/Store/Order';
import Preparation from './components/Store/Preparation';
import Delivery from './components/Store/Delivery';
import Return from './components/Store/Return';
import ReturnStock from './components/Store/ReturnStock';
import Stock from './components/Store/Stock';
import StockHistory from './components/Store/StockHistory';
// operation
import Users from './components/Operation/Users';
import Push from './components/Operation/Push';
import Blibrary from './components/Operation/Blibrary';
import Banner from './components/Operation/Banner';
import EditorsPick from './components/Operation/EditorsPick';
import DetailBanner from './components/Operation/Banner/aPages/UpdateBanner';
import CreateBanner from './components/Operation/Banner/aPages/CreateBanner';
import DetailInfuencer from 'components/Operation/Blibrary/aPages/UpdateBlibrary';
import CreateBlibrary from 'components/Operation/Blibrary/aPages/CreateBlibrary';
import Creator from './components/Operation/Creator';
import CreateCreator from './components/Operation/Creator/aPages/CreateCreator';
import UpdateCreator from 'components/Operation/Creator/aPages/UpdateCreator';
// ryencathers book store
import RCBookStoreDashboard from './components/RCBookStore/Dashboard';
import RCBookStoreOrder from './components/RCBookStore/Order';
//machine learning
import Detection from './components/MachineLearning/Detection';
// styles
import Loader from './components/common/loader';
import GlobalStyles from './styles/GlobalStyles';
import CreateEditor from 'components/Operation/EditorsPick/aPages/CreateEditorsPick';
import UpdateEditorsPick from 'components/Operation/EditorsPick/aPages/UpdateEditorsPick';

const PrivateRoute = ({ children, ...rest }) => {
  const {
    state: { user },
  } = useAuthRedux();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        // true ? (
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function Navigator() {
  const [initialize, setInitialize] = useState(true);

  const { authDispatch } = useAuthRedux();

  function init() {
    const currentTime = new Date().getTime();
    const sessionDueTime = window.localStorage.getItem('sessionDueTime');
    const sessionGrade = window.localStorage.getItem('sessionGrade');

    if (currentTime < sessionDueTime) {
      authDispatch.setUser({ user: sessionGrade });
    }

    setTimeout(() => {
      setInitialize(false);
    }, 1000);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {initialize ? (
        <Loader isLoading={initialize} setIsLoading={setInitialize} />
      ) : (
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/login`}>
            <Login />
          </Route>
          <Fragment>
            <App>
              {/* dashboard */}
              <PrivateRoute exact path={'/'}>
                <HomeBoard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/traffic-board`}
              >
                <TrafficBoard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/conversion-board`}
              >
                <ConversionBoard />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/unique-kpi`}>
                <UniqueKPI />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/traffic-kpi`}
              >
                <TrafficKPI />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/Funnel`}>
                <Funnel />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/conversion-analysis-board`}
              >
                <ConversionAnalysisBoard />
              </PrivateRoute>
              {/* store */}
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/order`}>
                <Order />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/preparation`}
              >
                <Preparation />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/delivery`}>
                <Delivery />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/return`}>
                <Return />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/return-stock`}
              >
                <ReturnStock />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stock`}>
                <Stock />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/stock-history`}
              >
                <StockHistory />
              </PrivateRoute>
              {/* operation */}
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/users`}>
                <Users />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/push`}>
                <Push />
              </PrivateRoute>

              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/banner`}>
                <Banner />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/banner/:id`}>
                <DetailBanner />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/create-banner`}
              >
                <CreateBanner />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/blibrary`}>
                <Blibrary />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/blibrary/influencer/:id`}
              >
                <DetailInfuencer />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/blibrary/create-influencer`}
              >
                <CreateBlibrary />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/editorsPick`}
              >
                <EditorsPick />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/editorsPick/:id`}
              >
                <UpdateEditorsPick />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/create-editorsPick`}
              >
                <CreateEditor />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/creator`}>
                <Creator />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/create-creator`}
              >
                <CreateCreator />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/creator/:id`}
              >
                <UpdateCreator />
              </PrivateRoute>
              {/* ryencathers book store */}
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/rcbookstore`}
              >
                <RCBookStoreDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={`${process.env.PUBLIC_URL}/rcbookstore/order`}
              >
                <RCBookStoreOrder />
              </PrivateRoute>
              <PrivateRoute exact path={`${process.env.PUBLIC_URL}/detection`}>
                <Detection />
              </PrivateRoute>
            </App>
          </Fragment>
        </Switch>
      )}
    </>
  );
}

function Root() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <ScrollContext>
            <Navigator />
          </ScrollContext>
        </BrowserRouter>
      </Provider>
      <GlobalStyles />
    </div>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
