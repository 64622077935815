import ModalComponent from 'components/common/ModalComponent';
import React, { FunctionComponent, useState } from 'react';
import TrafficComponent from '../aCommons/TrafficComponent';
import TrafficTable from '../aCommons/TrafficTable';
import styled from 'styled-components';
import { IoCloseSharp } from 'react-icons/io5';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

interface Props {
  title: string;
  data: any;
  populationName: string;
  sampleName: string;
  ratioName: string;
}

const ChardData: FunctionComponent<Props> = ({
  title,
  data: totalData,
  populationName,
  sampleName,
  ratioName,
}) => {
  const unSortedData = totalData.result;
  const copiedData = unSortedData.slice();
  const data = copiedData.sort(
    (a, b) =>
      new Date(a.dateColumn).getTime() - new Date(b.dateColumn).getTime(),
  );
  const totalRatio = totalData.totalRatio;
  const [isVisible, setIsVisible] = useState(false);

  const getTooltipName = (value, name) => {
    switch (name) {
      case 'sample':
        return [value, sampleName];
      case 'population':
        return [value, populationName];
      case 'ratio':
        return [parseFloat(value), ratioName];
      default:
        return '';
    }
  };

  const getLegendName = (value: string) => {
    switch (value) {
      case 'sample':
        return sampleName;
      case 'population':
        return populationName;
      case 'ratio':
        return ratioName;
      default:
        return '';
    }
  };

  return (
    <>
      <Container onClick={() => setIsVisible(!isVisible)}>
        <TrafficComponent
          title={title}
          data={`${(totalRatio * 100).toFixed(1)}%`}
        />
      </Container>
      <ModalComponent
        visible={isVisible}
        width={1200}
        height={650}
        backgroundColor={'white'}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 80,
            paddingRight: 70,
          }}
        >
          <div
            style={{
              marginBottom: 60,
              fontSize: 20,
              fontWeight: 700,
              position: 'absolute',
              left: 80,
              top: 40,
              color: '#6C757D',
            }}
          >
            {title}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 70,
            }}
          >
            <ResponsiveContainer width="100%" aspect={1.8}>
              <ComposedChart
                barGap={0}
                data={data}
                margin={{
                  top: 0,
                  right: 30,
                  left: 20,
                  bottom: 30,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dateColumn" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip formatter={getTooltipName} />
                <Legend formatter={getLegendName} />
                <Bar
                  yAxisId="left"
                  type="monotone"
                  dataKey="sample"
                  fill="#fd8e00"
                />
                <Bar
                  yAxisId="left"
                  type="monotone"
                  dataKey="population"
                  fill="#b5d396"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="ratio"
                  stroke="#00abfb"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <Scrollbars
            style={{
              display: 'flex',
              width: 500,
              height: 450,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  backgroundColor: 'black',
                  width: 5,
                  borderRadius: 7,
                }}
              />
            )}
          >
            <TrafficTable
              data={data}
              populationName={populationName}
              sampleName={sampleName}
              ratioName={ratioName}
            />
          </Scrollbars>
        </div>
        <CloseModal onClick={() => setIsVisible(!isVisible)}>
          <IoCloseSharp color={'black'} size={30} />
        </CloseModal>
      </ModalComponent>
    </>
  );
};

const Container = styled.div``;

const CloseModal = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;

export default ChardData;
