import styled from 'styled-components';
import ModalComponent from 'components/common/ModalComponent';
import React, { Fragment, FunctionComponent, useState } from 'react';
import TrafficComponent from '../../aCommons/TrafficComponent';
import moment from 'moment';
import { IoCloseSharp } from 'react-icons/io5';

interface RetentionPresenterProps {
  data: Array<RetentionTableBodyProps>;
}

interface RetentionTableBodyProps {
  date: string;
  date_week: string;
  cnt: number;
  w0: string;
  w1: string;
  w2: string;
  w3: string;
  w4: string;
  w5: string;
  w6: string;
}

const RetentionTableBody: FunctionComponent<RetentionTableBodyProps> = ({
  date,
  date_week,
  cnt,
  w0,
  w1,
  w2,
  w3,
  w4,
  w5,
  w6,
}) => {
  const startDate = new Date(date);
  const endDate = moment(startDate, 'DD-MM-YYYY').add('days', 6);

  const firstWeek = parseInt(w1);
  const secondWeek = parseInt(w2);
  const thirdWeek = parseInt(w3);
  const fourthWeek = parseInt(w4);
  const fivethWeek = parseInt(w5);

  const zeroWeekValue = ((cnt / cnt) * 100).toFixed(1);
  const firstWeekValue = ((firstWeek / cnt) * 100).toFixed(1);
  const secondWeekValue = ((secondWeek / cnt) * 100).toFixed(1);
  const thirdWeekValue = ((thirdWeek / cnt) * 100).toFixed(1);
  const fourthWeekValue = ((fourthWeek / cnt) * 100).toFixed(1);
  const fivethWeekValue = ((fivethWeek / cnt) * 100).toFixed(1);

  return (
    <TableTr>
      <LeftItem>
        {moment(startDate).format('YYYY-MM-DD')} ~{' '}
        {moment(endDate).format('YYYY-MM-DD')}
      </LeftItem>
      <TableTD value={zeroWeekValue}>
        {zeroWeekValue}%
        <div>
          {cnt}/{cnt}
        </div>
      </TableTD>
      <TableTD value={firstWeekValue}>
        {firstWeekValue}%
        <div>
          {firstWeek}/{cnt}
        </div>
      </TableTD>
      <TableTD value={secondWeekValue}>
        {secondWeekValue}%
        <div>
          {secondWeek}/{cnt}
        </div>
      </TableTD>
      <TableTD value={thirdWeekValue}>
        {thirdWeekValue}%
        <div>
          {thirdWeek}/{cnt}
        </div>
      </TableTD>
      <TableTD value={fourthWeekValue}>
        {fourthWeekValue}%
        <div>
          {fourthWeek}/{cnt}
        </div>
      </TableTD>
      <TableTD value={fivethWeekValue}>
        {fivethWeekValue}%
        <div>
          {fivethWeek}/{cnt}
        </div>
      </TableTD>
    </TableTr>
  );
};

const LeftItem = styled.th`
  display: flex;
  align-items: center;
  width: 200px;
  margin-top: 10px;
`;

interface TableTDProps {
  value: number;
}

const TableTD = styled.td<TableTDProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  background-color: ${(props) =>
    props.value >= 90
      ? '#4240e4'
      : props.value < 90 && props.value >= 50
      ? '#5755e7'
      : props.value < 50 && props.value >= 30
      ? '#817fed'
      : props.value < 30 && props.value >= 20
      ? '#abaaf3'
      : props.value < 20 && props.value >= 10
      ? '#d5d4f9'
      : props.value < 10 && props.value > 0
      ? '#eae9fc'
      : null};
  color: black;
  margin-right: 5px;
  margin-top: 5px;
  div {
    font-size: 12px;
  }
`;

const TableTr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const RetentionPresenter: FunctionComponent<RetentionPresenterProps> = ({
  data,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const tableHeadRowRendering = () => {
    const result: JSX.Element[] = [];
    for (let i = 0; i <= 6; i++) {
      if (i === 0) {
        result.push(<TableHead key={i} width={185} height={50}></TableHead>);
      } else {
        result.push(
          <TableHead key={i} width={80} height={50}>
            {i - 1}주째
          </TableHead>,
        );
      }
    }
    return result;
  };
  const beforeWeekRetention = (
    (parseInt(data[4].w1) / parseInt(data[4].w0)) *
    100
  ).toFixed(1);

  return (
    <>
      <Container onClick={() => setIsVisible(!isVisible)}>
        <TrafficComponent title={'리텐션'} data={`${beforeWeekRetention}%`} />
      </Container>
      <ModalComponent
        visible={isVisible}
        width={800}
        height={550}
        backgroundColor={'white'}
      >
        <TableContainer>
          <Table>
            <Title>Retention</Title>
            <thead>
              <TableHeadRow>{tableHeadRowRendering()}</TableHeadRow>
            </thead>
            <TBody>
              {data.map((item, index) => (
                <Fragment key={index}>
                  <RetentionTableBody {...item} />
                </Fragment>
              ))}
            </TBody>
          </Table>
        </TableContainer>
        <CloseModal onClick={() => setIsVisible(!isVisible)}>
          <IoCloseSharp color={'black'} size={30} />
        </CloseModal>
      </ModalComponent>
    </>
  );
};

const Container = styled.div``;

const Title = styled.caption`
  margin-top: 30px;
  margin-left: 30px;
  font-size: 20px;
  font-weight: 700;
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const Table = styled.table`
  width: 700px;
  display: flex;
  flex-direction: column;
`;

const TBody = styled.tbody`
  display: flex;
  flex-direction: column;
`;

const TableHeadRow = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface TableHeadProps {
  width: number;
  height: number;
}

const TableHead = styled.th<TableHeadProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-left: 4px;
`;

const CloseModal = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;

export default RetentionPresenter;
