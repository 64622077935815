import React from 'react';
// styles
import { Table } from 'styles/index';
// types
import { EmployeeBookOrder } from 'api/types';

interface Props {
  orders: EmployeeBookOrder[];
}
function OrderList({ orders }: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: '기본 정보',
        columns: [
          {
            Header: '주문번호',
            accessor: 'purchaseId',
            enableRowSpan: true,
          },
          {
            Header: '찾서주문번호',
            accessor: 'orderId',
          },
          {
            Header: '주문직원명',
            accessor: 'ordererName',
          },
          {
            Header: '지점명',
            accessor: 'addressName',
          },
          {
            Header: '주문일시',
            accessor: 'insertDate',
          },
          {
            Header: '업무/비업무 구분',
            accessor: 'businessRelatedYn',
          },
        ],
      },
      {
        Header: '책',
        columns: [
          {
            Header: '제목',
            accessor: 'bookName',
          },
          {
            Header: '가격',
            accessor: 'price',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Table
      fullScreen
      columns={columns}
      data={orders}
      style={{ marginBottom: 20 }}
      padding="small"
      rowSpanIds={[
        'purchaseId',
        'orderId',
        'ordererName',
        'addressName',
        'insertDate',
      ]}
    />
  );
}

export default OrderList;
