import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// types
import { State } from './index';

export default function useAuthRedux() {
  const dispatch = useDispatch();
  const state: State = useSelector((store: any) => store.auth);

  const setUser = useCallback(
    (payload: { user: any }) => dispatch({ type: 'SET_USER', payload }),
    [dispatch],
  );

  return {
    state,
    authDispatch: {
      setUser,
    },
  };
}
