import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_LIGHT } from 'styles/colors';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  onChange: (text: string) => void;
  title: string;
  value: string | number;
  type: string;
  description?: string;
}

const TextForm: FunctionComponent<Props> = ({
  onChange,
  title,
  value,
  type,
  description,
  children,
}) => {
  return (
    <FormWrapper>
      <FormText>{title}</FormText>
      <InputWrapper>
        <FormInput
          onChange={onChange}
          value={value}
          type={type}
          maxlength="20"
        />

        <FormDescription>{ReactHtmlParser(description)}</FormDescription>
      </InputWrapper>
    </FormWrapper>
  );
};

const FormWrapper = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 100px;
`;

const FormText = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormDescription = styled.div`
  margin-top: 10px;
`;

const FormInput = styled.input`
  display: flex;
  width: 30vw;
  height: 40px;
  padding: 0 15px 0 15px;
  border: solid 1px ${GREY_LIGHT};
  border-radius: 5px;
  :focus {
    border: solid 2px black;
  }
`;

export default TextForm;
