import { StockBook } from 'api/types';
import { toastify } from 'utils/common';
import { apiGet, apiPut, apiPost } from '../useAxios';

export interface BookStatus {
  statusCode: number;
  statusName: string;
}

export interface Book {
  orderId: number;
  orderBookId: number;
  bookId: number;
  user_id: number;
  bookCondition: number;
  ordinalNo: number;
  bookPreparationYn: number;
  bookPreparationStatus: number;
  bookPreparationStatusName: string;
  deleteYn: number;
  bookName: string;
  authorName: string;
  publisherName: string;
  stock: string;
  deliveryId?: any;
  receiver_name: string;
  receiver_phone: string;
  address: string;
  isbn_13: string;
  price: string;
  orderCancelNotification: number; // 주문 취소 알림 수
  invoiceNumber: string;
  copyTextOrder: string;
  copyTextSend: string;
  lateAlimTalk: number;
}

export interface SetBookPreparationStatusData {
  orderBook_id: number;
  book_preparation_status: number;
}

export interface SrderCancleAlimtalkData {
  orderId: number;
  userId: number;
  productName: string;
  cancelReason: string;
  userPhone: string;
}
export interface GetBookPreparationListRes {
  list: Book[];
  stockBookInfo: StockBook[];
}

export interface PostSendLateAlimTalkData {
  orderId: number;
  orderBookId: number;
  userId: number;
}

// 책 상태 리스트
export const getBookStatusList = async (): Promise<BookStatus[]> => {
  try {
    const result = await apiGet('admin/store/bookPreparation/status');
    return result.data.result.bookPreparationStatusList;
  } catch (error) {
    throw error;
  }
};

// 책 리스트
export const getBookPreparationList =
  async (): Promise<GetBookPreparationListRes> => {
    try {
      const result = await apiGet('admin/store/bookPreparation');
      return result.data.result;
    } catch (error) {
      throw error;
    }
  };

// 책 준비 상태 변경
export const setBookPreparationStatus = async (
  data: SetBookPreparationStatusData,
): Promise<any> => {
  try {
    const result = await apiPut('admin/store/bookPreparation', data);
    return result;
  } catch (error) {
    throw error;
  }
};

// 주문 취소 알림
export const orderCancleAlimtalk = async (
  value: number[],
): Promise<SrderCancleAlimtalkData> => {
  try {
    const data = {
      orderBookIdList: value,
    };
    const result = await apiPost('admin/store/send/orderCancleAlimtalk', data);
    return result;
  } catch (error) {
    throw error;
  }
};

// 알라딘 확보 완료 처리
export const gotAladinBook = async (data: {
  orderBookIdList: number[]; // orderBookId 배열
}): Promise<{}> => {
  try {
    const res = await apiPut('admin/store/bookPreparation/to120', data);
    return res?.data?.result;
  } catch (error) {
    const errorMessage = error?.response?.data?.errorMessage;
    if (errorMessage) {
      toastify('error', errorMessage);
    }
    throw error;
  }
};

// 배송지연 알림톡 전송
export const postSendLateAlimTalk = async (data: {
  deliveryList: PostSendLateAlimTalkData[]; // orderBookId 배열
}): Promise<{}> => {
  try {
    const res = await apiPost('admin/store/send/delay-alimtalk', data);
    return res?.data?.result;
  } catch (error) {
    const errorMessage = error?.response?.data?.errorMessage;
    if (errorMessage) {
      toastify('error', errorMessage);
    }
    throw error;
  }
};
