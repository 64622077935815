import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_LIGHT } from 'styles/colors';
import { Button } from 'reactstrap';

interface Props {
  value: string;
  onChange: (e: any) => void;
  getExportOrderListHandle: any;
}

const SearchPhoneNumber: FunctionComponent<Props> = ({
  value,
  onChange,
  getExportOrderListHandle,
}) => {
  const handleEnter = (e: any) => {
    if (e.keyCode === 13) {
      // handleLogin();
    }
  };

  return (
    <Container>
      <div style={{ fontWeight: 700 }}>검색</div>
      <InputWrapper>
        <FormInput
          onChange={onChange}
          value={value}
          onKeyUp={handleEnter}
          type={'text'}
          maxlength="20"
        />

        <div style={{ marginLeft: 30 }}>
          <Button outline color="secondary" onClick={getExportOrderListHandle}>
            복사(주문)
          </Button>
        </div>
        <div style={{ marginLeft: 10 }} />
      </InputWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  height: 100px;
  padding: 13px;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 20px;
`;

const FormInput = styled.input`
  display: flex;
  width: 200px;
  height: 40px;
  padding: 0 15px 0 15px;
  border: solid 1px ${GREY_LIGHT};
  border-radius: 5px;
  :focus {
    border: solid 2px black;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export default SearchPhoneNumber;
