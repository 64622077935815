export const PRIMARY = '#222';

export const RED = '#f24d0f';
export const BLUE = '#00abfb';
export const ORANGE = '#fd8e00';
export const YELLOW = '#fee600';

export const GREY_LIGHT = '#ccc';
export const GREY = '#999';
export const GREY_DARK = '#666';
export const GREY_WITH_OPACITY = 'rgba(0,0,0,0.05)';

export const BLACK_PRIMARY = '#2f3c4e';

export const WHITE = '#FFF';
export const BLACK = '#000';

export const FONT = '#222';
export const PLACEHOLDER_GREY = '#ccc';

export const BACKGROUND_GREY = '#f5f5f5';
export const IMG_BACKGROUND_GREY = 'rgba(0,0,0,0.1)';
export const IMG_BORDER_GREY = '#ebebeb';

export const BORDER_GREY = '#e0e0e0';
export const DIVIDER_GREY = '#e0e0e0';
