import React, { useState, useEffect } from 'react';
// common
import { getHyphenWithPhoneNumber } from 'utils/common';
// styles
import Red from '@material-ui/core/colors/red';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
// types
import { ReturnInfo } from '../../../api/store/return';

interface Props {
  returnInfo: ReturnInfo;
  onSetSelectList: (selectInfo: ReturnInfo) => void;
  onSetDate: (selectInfo: ReturnInfo) => void;
}

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: Red,
  },
});

export default function ListItem({
  returnInfo,
  onSetSelectList,
  onSetDate,
}: Props) {
  const [isCheckReturn, setIsCheckReturn] = useState(false);
  const [returnDate, setReturnDate] = useState<any>(new Date());

  useEffect(() => {
    setIsCheckReturn(false);
    setReturnDate(
      returnInfo.pickup_date !== null ? returnInfo.pickup_date : new Date(),
    );
  }, [returnInfo]);

  const handleCheckDelivery = () => {
    let data = returnInfo;
    data = { ...data, pickup_date: returnDate };
    setIsCheckReturn(!isCheckReturn);
    onSetSelectList(data);
  };

  const handleChangeDate = (date: any) => {
    let data = returnInfo;
    data = { ...data, pickup_date: date };
    setReturnDate(date);
    onSetDate(data);
  };

  return (
    <tr
      style={{
        backgroundColor:
          returnInfo.returnStatus === 401 ? '#ffd6c9' : undefined,
      }}
    >
      <td className="text-center">{returnInfo.orderId}</td>
      <td>{returnInfo.receiver_name}</td>
      <td>{getHyphenWithPhoneNumber(returnInfo.receiver_phone)}</td>
      <td>{returnInfo.address1}</td>
      <td>{returnInfo.address2}</td>
      <td>
        <ThemeProvider theme={defaultMaterialTheme}>
          <KeyboardDateTimePicker
            value={returnDate}
            onChange={handleChangeDate}
            format="YYYY-MM-DD HH:mm"
            invalidDateMessage="날짜 형식을 맞춰주세요.(예: 2020-11-09)"
            // onBlur={handleChangeDate}
            // minDate={new Date()}
            // minDateMessage="오늘 이전의 날짜를 입력할 수 없습니다."
          />
        </ThemeProvider>
      </td>
      <td>
        <input
          className="checkbox_animated"
          type="checkbox"
          checked={isCheckReturn}
          onChange={handleCheckDelivery}
        />
      </td>
    </tr>
  );
}
