import React, { FunctionComponent } from 'react';
import moment from 'moment';
import styled from 'styled-components';

interface Props {
  title: string;
  date?: Date | string | number;
  text?: string | number;
}

const StaticForm: FunctionComponent<Props> = ({ title, date, text }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {date ? (
        <BodyText>{moment(date).format('YYYY-MM-DD')}</BodyText>
      ) : (
        <BodyText>{text}</BodyText>
      )}
    </Container>
  );
};

const Container = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 100px;
`;

const Title = styled.div`
  width: 15vw;
  font-weight: 700;
  font-size: 20px;
`;

const BodyText = styled.div`
  display: flex;
  width: 30vw;
  height: auto;
  padding: 15px;
  border-radius: 5px;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

export default StaticForm;
