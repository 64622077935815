import React, { useRef, useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import classNames from 'classnames';
import styled from 'styled-components';
import 'moment/locale/ko';
import { GREY_LIGHT } from 'styles/colors';
moment.locale('ko');

const WeekPickComponent = ({
  text,
  date: selectedDate,
  maxDate,
  getData,
  type,
}) => {
  const currentMoment = moment();

  const [selectedWorkWeek, setSelectedWorkWeek] = useState(
    currentMoment.isoWeek(),
  );
  const [selectedYear, setSelectedYear] = useState(currentMoment.year());
  const [focused, setFocused] = useState(false);

  const weekPickerRef = useRef<any>(null);

  const calculateActiveWeek = (date) => {
    const mon = date.clone().startOf('isoweek');
    const tue = mon.clone().add(1, 'd');
    const wed = mon.clone().add(2, 'd');
    const thu = mon.clone().add(3, 'd');
    const fri = mon.clone().add(4, 'd');
    const sat = mon.clone().add(5, 'd');
    const sun = mon.clone().add(6, 'd');
    return [sun, mon, tue, wed, thu, fri, sat];
  };

  const [hoveredDays, setHoveredDays] = useState(
    calculateActiveWeek(currentMoment),
  );

  const date = moment().year(selectedYear).isoWeek(selectedWorkWeek);

  const isDayHighlighted = (date) => {
    let isHighlighted = false;
    hoveredDays.forEach((hoveredDay) => {
      const isDayOfMonthMatch = hoveredDay.date() === date.date();
      const isMonthMatch = hoveredDay.month() === date.month();
      const isYearMatch = hoveredDay.year() === date.year();
      if (isDayOfMonthMatch && isMonthMatch && isYearMatch) {
        isHighlighted = true;
      }
    });
    return isHighlighted;
  };

  const onDateHovered = (date) => {
    setHoveredDays(calculateActiveWeek(date));
  };

  const onDateChange = (date) => {
    setFocused(false);
    const selectedYear = date.year();
    const selectedWorkWeek = date.isoWeek();
    setSelectedWorkWeek(selectedWorkWeek);
    setSelectedYear(selectedYear);
    const selectDate = moment().year(selectedYear).isoWeek(selectedWorkWeek);
    const preDate = new Date(selectDate.format('YYYY.MM.DD'));
    const timestamp = preDate.getTime();
    const subDayNumber = preDate.getDay() - 1;
    const weekTimestamp = timestamp - 60 * 60 * 24 * 1000 * subDayNumber;
    getData(weekTimestamp);
    setOpacity(false);
  };

  const renderCalendarDay = (date) => {
    const dayClasses = classNames(
      'CalendarDay',
      'CalendarDay__default',
      'CalendarDay_1',
      'CalendarDay__default_2',
    );

    let style = {
      width: '39px',
      height: '38px',
    };

    if (date.day) {
      const dayOfMonth = date.day.date();
      const isHighlighted = isDayHighlighted(date.day);

      const momentDate = new Date(date.day).getTime();
      const test = maxDate ? new Date(maxDate).getTime() : 0;
      const result = momentDate < test;

      return (
        <td
          style={{
            width: '39px',
            height: '38px',
            backgroundColor: isHighlighted && result ? '#42a5f5' : 'white',
            color:
              isHighlighted && result
                ? 'white'
                : !result
                ? GREY_LIGHT
                : 'black',
          }}
          className={dayClasses}
          onClick={() => (result ? onDateChange(date.day) : null)}
          onMouseEnter={() => onDateHovered(date.day)}
        >
          {dayOfMonth}
        </td>
      );
    } else {
      return <td style={style} className={dayClasses} />;
    }
  };

  const [opacity, setOpacity] = useState(false);

  const focuseHandle = (focused) => {
    setFocused(focused);
    if (focused) {
      setOpacity(true);
    } else {
      setOpacity(false);
    }
  };

  const startDate = moment(selectedDate).format('YYYY-MM-DD');
  const endDate = moment(selectedDate + 60 * 60 * 24 * 1000 * 7 - 1).format(
    'YYYY-MM-DD',
  );

  return (
    <Container opacity={opacity}>
      <Text>{text}</Text>
      <SelectDate
        id="datepicker-container"
        className="mobile-calendar"
        style={{
          flex: 1,
          width: 1000,
          textAlign: 'center',
        }}
        opacity={opacity}
      >
        <SingleDatePicker
          ref={weekPickerRef}
          focused={focused} // PropTypes.bool
          date={date}
          onFocusChange={({ focused }) => focuseHandle(focused)} // PropTypes.func.isRequired
          id="single_date_picker" // PropTypes.string.isRequired,
          numberOfMonths={2}
          hideKeyboardShortcutsPanel={true}
          isDayBlocked={() => false}
          isOutsideRange={() => false}
          noBorder={true}
          regular={true}
          renderCalendarDay={renderCalendarDay}
        />
      </SelectDate>
      <FakeSelectDate opacity={opacity}>
        {startDate} ~ {endDate}
      </FakeSelectDate>
    </Container>
  );
};

interface ContainerProps {
  opacity: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  background-color: white;
  padding: 20px 0 10px 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
  margin-top: 20px;
  background-color: white;
`;

const Text = styled.div`
  margin-bottom: 10px;
`;

interface SelectDateProps {
  opacity: boolean;
}

const SelectDate = styled.div<SelectDateProps>`
  cursor: pointer;
  opacity: ${(props) => (props.opacity ? 2 : 0)};
  z-index: 2;
`;

interface FakeSelectDateProps {
  opacity: boolean;
}

const FakeSelectDate = styled.div<FakeSelectDateProps>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 35px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  outline: none;
  margin-top: 20px;
  cursor: pointer;
  z-index: ${(props) => (props.opacity ? 3 : 1)};
  background-color: white;
`;

export default WeekPickComponent;
