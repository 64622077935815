import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
// apis
import useSearchAPI, { SearchBookRes } from 'api/search/useSearchAPI';
// lib
import { setSalePrice, customToLocaleString } from 'components/common/tools';
// common
import { toastify } from 'utils/common';
// styles
import { Flex } from 'styles/index';
import * as colors from 'styles/colors';
import { TextField } from '@material-ui/core';
// types
import { Book } from 'api/types';
import { Button } from 'reactstrap';

interface Props {
  isVisible: boolean;
  close: any;
  onPress: any;
  selectedBooks: Book[];
}

const SEARCH_RESULT_LENGTH = 20;

export default function SearchBook({
  isVisible,
  onPress,
  selectedBooks,
  close,
}: Props) {
  const listRef: any = useRef();

  const page = useRef(1);
  const [hasMore, setHasMore] = useState(true);

  const [keyword, setKeyword] = useState('');
  const [keywordInput, setKeywordInput] = useState('');
  const [queryInfo, setQueryInfo] = useState<SearchBookRes | null>(null);
  const [books, setBooks] = useState<Book[]>([]);

  const { searchBooksAPI } = useSearchAPI();

  function handleClose() {
    close();
    setBooks([]);
    setKeyword('');
    setKeywordInput('');
    setQueryInfo(null);
  }

  async function handleSearch(e) {
    e.preventDefault();
    if (!keywordInput) return;
    try {
      const res = await searchBooksAPI({
        bookName: keywordInput,
        size: SEARCH_RESULT_LENGTH,
        maxScore: [],
      });
      setQueryInfo(res);
      setBooks(res.bookList);
      setKeyword(keywordInput);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleLoadMore() {
    if (!keyword) return;
    if (!hasMore) return;
    try {
      let prevScrollTop = 0;
      if (listRef?.current) {
        prevScrollTop = listRef.current.scrollTop;
      }

      page.current += 1;
      const res = await searchBooksAPI({
        bookName: keyword,
        size: SEARCH_RESULT_LENGTH,
        maxScore: queryInfo?.maxScore || [],
      });

      setQueryInfo(res);
      setBooks((prev) => [...prev, ...res.bookList]);

      if (res?.bookList?.length === 0) {
        setHasMore(false);
      }

      if (listRef?.current) {
        listRef?.current.scrollTo(0, prevScrollTop);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleClick(book: Book) {
    if (selectedBooks.findIndex((b) => b?.bookId === book?.bookId) === -1) {
      onPress((prev) => [...prev, book]);
      handleClose();
    } else {
      toastify('error', '이미 추가된 책입니다.');
    }
  }

  useEffect(() => {
    page.current = 1;
    setHasMore(true);
  }, [keyword]);

  const totalCount = queryInfo?.totalCount || 0;
  return (
    <Modal
      contentRef={(node) => {
        listRef.current = node;
      }}
      onScroll={(e) => console.log(e)}
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        content: {
          width: '50%',
          height: '80%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSearch} noValidate autoComplete="off">
        <TextField
          id="keywordInput"
          variant="outlined"
          onChange={(e) => setKeywordInput(e.target.value)}
          fullWidth
          placeholder="검색"
        />
      </form>
      {queryInfo ? (
        <h4 style={{ fontSize: 18, margin: '14px 4px' }}>
          {totalCount.toLocaleString()}개의 책이 검색되었습니다.
        </h4>
      ) : null}
      <div>
        {books.length === 0 ? null : (
          <Flex
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {books.map((book) => (
              <BookCard key={book.bookId} onClick={() => handleClick(book)}>
                <img src={book.bookImage} />
                <Flex style={{ flex: 1 }}>
                  <Flex style={{ flex: 1, flexDirection: 'column' }}>
                    <div className="bookName">{book.bookName}</div>
                    <div className="bookSubName">{book.bookSubName}</div>
                    <div className="authorName">{book.authorName}</div>
                    <div className="publishDate">{book?.publishDate}</div>
                  </Flex>
                  <Flex
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div className="prevPrice">
                      {customToLocaleString(book?.price)}원
                    </div>
                    <div className="price">
                      {book?.price && book?.discountRate
                        ? setSalePrice(book?.price, book?.discountRate)
                        : null}
                      원
                    </div>
                  </Flex>
                </Flex>
              </BookCard>
            ))}
            {totalCount < SEARCH_RESULT_LENGTH || !hasMore ? null : (
              <Button color="primary" onClick={handleLoadMore}>
                더보기
              </Button>
            )}
          </Flex>
        )}
      </div>
    </Modal>
  );
}

const BookCard = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 20px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid ${colors.BORDER_GREY};

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  img {
    width: 80px;
    height: 116px;
    margin-right: 12px;
    border-radius: 8px;
    border: 1px solid ${colors.IMG_BORDER_GREY};
    background-color: ${colors.IMG_BACKGROUND_GREY};
    overflow: hidden;
  }
  .bookName {
    font-size: 20px;
    color: ${colors.FONT};
  }
  .bookSubName {
    font-size: 16px;
    color: ${colors.GREY_DARK};
  }
  .authorName {
    font-size: 16px;
    color: ${colors.FONT};
  }
  .publishDate {
    font-size: 16px;
    color: ${colors.GREY_DARK};
  }
  .prevPrice {
    font-size: 16px;
    color: ${colors.GREY_LIGHT};
    text-decoration: line-through;
  }
  .price {
    font-size: 18px;
    font-weight: 700;
    color: ${colors.FONT};
  }

  &:hover {
    border: 2px solid ${colors.PRIMARY};
    background-color: ${colors.BACKGROUND_GREY};
  }
`;
