import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function Index() {
  const history = useHistory();
  useEffect(() => {
    history.push(`${process.env.PUBLIC_URL}/traffic-board`);
  }, []);

  return null;
}
