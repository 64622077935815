import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// api
import useStockAPI from '../../../api/store/useStockAPI';
// components
import StockHistoryListItem from './StockHistoryListItem';
// styles
import { Flex } from 'styles/index';
import Pagination from '@material-ui/lab/Pagination';
// types
import { StockHistory } from '../../../api/types';

export default function Stock() {
  const { getBookStockHistoryAPI } = useStockAPI();

  const [history, setHistory] = useState<StockHistory[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  // 리스트 호출
  async function handleGetBookStockHistory() {
    try {
      const res = await getBookStockHistoryAPI({ page });
      if (res) {
        setHistory(res.inoutHistory);
        setLastPage(res.lastPage);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleGetBookStockHistory();
  }, [page]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <h5>입출고 내역</h5>
          </div>
          <div className="table-responsive">
            <CustomTable className="table">
              <thead>
                <tr>
                  <th scope="col">책ID</th>
                  <th scope="col">책이름</th>
                  <th scope="col">ISBN</th>
                  <th scope="col">상태</th>
                  <th scope="col">위치</th>
                  <th scope="col">변경수량</th>
                  <th scope="col">입출고 사유</th>
                  <th scope="col">발생일시</th>
                </tr>
              </thead>
              <tbody>
                {history.length > 0 &&
                  history.map((item, index) => (
                    <StockHistoryListItem
                      key={`${item.bookId}${index}`}
                      item={item}
                    />
                  ))}
              </tbody>
            </CustomTable>
            <Flex style={{ justifyContent: 'center', paddingBottom: 20 }}>
              <StyledPagination
                count={lastPage}
                onChange={(_, page) => setPage(page)}
              />
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
}

const CustomTable = styled.table`
  th {
    white-space: nowrap;
  }
`;
const StyledPagination = styled(Pagination)`
  button {
    outline: none;
  }
`;
