import { apiGet, apiPost, apiDelete } from '../useAxios';

export interface StockInfo {
  orderId: number;
  orderBook_id: number;
  userId: number;
  ordererName: string;
  address: string;
  book_name: string;
  userIdGiftReceiver: number;
  isbn: string;
  // book_condition: number;
  // location_code: string;
  // stock_id: number;
  book_id: number;
  stock_in_yn: number;
  ellapsed_time: string;
  stock: Stock[];
}

export interface Stock {
  stock_id: number;
  book_id: number;
  book_condition: number;
  location_code: string;
  stock_quantity: number;
}

export interface Location {
  locationCode: string;
}

export interface SetStockData {
  order_id: string;
  user_id: string;
  location_code: string;
  book_condition: string;
  book_id: string;
  orderBook_id: string;
}

// 리턴 입고 리스트
export const getStockList = async (): Promise<StockInfo[]> => {
  try {
    const result = await apiGet('admin/store/return/stock');
    return result.data.result.list;
  } catch (error) {
    throw error;
  }
};

// 위치 리스트
export const getLocationList = async (): Promise<Location[]> => {
  try {
    const result = await apiGet('admin/store/return/stock/location');
    return result.data.result.list;
  } catch (error) {
    throw error;
  }
};

// 리턴 입고
export const setStock = async (data: SetStockData): Promise<any> => {
  try {
    const result = await apiPost('admin/store/return/stock', data);
    return result;
  } catch (error) {
    throw error;
  }
};

// 리턴 입고
export const cancelStock = async (data: {
  orderBookId: number;
  locationCode: string;
}): Promise<any> => {
  try {
    const result = await apiDelete('admin/store/return/stock', data);
    return result;
  } catch (error) {
    throw error;
  }
};
