import React, { useMemo } from 'react';
// styles
import { Table } from 'styles/index';

export default function AlimtalkHistory({ alimtalkHistory }) {
  const columns = useMemo(
    () => [
      {
        Header: '히스토리ID',
        accessor: 'historyId',
      },
      {
        Header: '주문ID',
        accessor: 'orderId',
      },
      {
        Header: '수신자 번호',
        accessor: 'targetPhone',
      },
      {
        Header: '메시지ID',
        accessor: 'messageId',
      },
      {
        Header: '요청',
        accessor: 'sendingStatus',
      },
      {
        Header: '메시지',
        accessor: 'messageContent',
      },
      {
        Header: '템플릿ID',
        accessor: 'templateId',
      },
      {
        Header: '예약일자',
        accessor: 'reservationDate',
      },
      {
        Header: '예약여부',
        accessor: 'reservedYn',
      },
    ],
    [],
  );

  if (alimtalkHistory.length === 0) return null;

  return (
    <div style={{ flex: 1, marginBottom: 20 }}>
      <div
        style={{
          fontSize: 15,
          fontWeight: 700,
          marginBottom: 10,
          paddingLeft: 5,
        }}
      >
        알림톡 히스토리
      </div>
      <Table fullScreen columns={columns} data={alimtalkHistory} />
    </div>
  );
}
