import styled from 'styled-components';
import React from 'react';
import {
  IoAddOutline,
  IoArrowDownOutline,
  IoReloadOutline,
} from 'react-icons/io5';
import { BLACK_PRIMARY } from 'styles/colors';

const FunnelDataView = ({
  tableData,
  clickPlusButtonHandle,
  resetFunnelDataHandle,
}) => {
  return (
    <>
      <Container>
        <div
          style={{
            position: 'absolute',
            top: 20,
            left: 20,
            fontWeight: 700,
            color: 'gray',
          }}
        >
          Result
        </div>
        <ResetButton onClick={resetFunnelDataHandle}>
          <IoReloadOutline size={20} color={'white'} />
        </ResetButton>
        <FunnelContainer>
          {tableData.map((item, index) =>
            index === 0 ? (
              <FunnelWrapper key={index} marginBottom={30}>
                <FunnelHeaderContainer header={true} fontSize={40}>
                  {item.data.header}
                </FunnelHeaderContainer>
                <FunnelHeaderContainer fontSize={40}>
                  {item.data.firstData}
                </FunnelHeaderContainer>
                <FunnelHeaderContainer fontSize={40}>
                  {item.data.secondData}
                </FunnelHeaderContainer>
              </FunnelWrapper>
            ) : (
              <FunnelWrapper key={index} marginTop={index === 0 ? 0 : 20}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {item.event.pages.map((page, index) => (
                    <>
                      {index === 0 ? null : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 100,
                            color: 'white',
                            marginTop: 30,
                            marginBottom: 30,
                          }}
                        >
                          <FunnelItem fontSize={25}>{index}</FunnelItem>
                        </div>
                      )}
                      <FunnelItem fontSize={25}>{page}</FunnelItem>
                    </>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {item.user.funnels.map((funnel, index) => (
                    <>
                      {index === 0 ? null : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 100,
                            paddingLeft: 30,
                            marginTop: 30,
                            marginBottom: 30,
                          }}
                        >
                          <IoArrowDownOutline size={50} color={'#E2E2E2'} />
                          <ArrowlItem>
                            {item.user.percents[index - 1] * 100}%
                          </ArrowlItem>
                        </div>
                      )}
                      <FunnelItem fontSize={25}>{funnel}</FunnelItem>
                    </>
                  ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {item.event.funnels.map((funnel, index) => (
                    <>
                      {index === 0 ? null : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 100,
                            paddingLeft: 30,
                            marginTop: 30,
                            marginBottom: 30,
                          }}
                        >
                          <IoArrowDownOutline size={50} color={'#E2E2E2'} />
                          <ArrowlItem>
                            {item.event.percents[index - 1] * 100}%
                          </ArrowlItem>
                        </div>
                      )}
                      <FunnelItem fontSize={25}>{funnel}</FunnelItem>
                    </>
                  ))}
                </div>
              </FunnelWrapper>
            ),
          )}
          <PlusButton onClick={clickPlusButtonHandle}>
            <IoAddOutline size={30} color={'white'} />
          </PlusButton>
        </FunnelContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin-top: 30px;
  padding: 0px 0 30px 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
`;

const FunnelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
`;

const ResetButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-color: ${BLACK_PRIMARY};
  border-radius: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

interface FunnelWrapperProps {
  marginTop: number;
  marginBottom?: number;
}

const FunnelWrapper = styled.ul<FunnelWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 60vw;
  font-weight: 700;
  font-size: 30px;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;

const FunnelHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 60px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 10px;
  color: gray;
  font-size: 20px;
`;

interface FunnelItemProps {
  marginLeft: number;
  header: boolean;
  fontSize?: number;
}

const FunnelItem = styled.li<FunnelItemProps>`
  display: flex;
  margin-left: ${(props) => props.marginLeft}px;
  width: 230px;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${(props) => props.fontSize}px;
`;

const ArrowlItem = styled.li<FunnelItemProps>`
  margin-left: ${(props) => props.marginLeft}px;
  width: 30px;
  text-align: center;
  font-size: 20px;
  color: #e2e2e2;
`;

const PlusButton = styled.div`
  display: flex;
  min-width: 60vw;
  height: 60px;
  background-color: ${BLACK_PRIMARY};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 40px;
  border-radius: 10px;
`;

export default FunnelDataView;
