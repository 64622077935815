import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import Breadcrumb from 'components/common/breadcrumb';
import { BLACK_PRIMARY } from 'styles/colors';

interface DateTypeSelectButtonProps {
  text: string;
  pick: boolean;
  onClick: (text: string) => void;
}

const DateTypeSelectButton: FunctionComponent<DateTypeSelectButtonProps> = ({
  text,
  pick,
  onClick,
}) => {
  return (
    <SelectButton
      onClick={() => onClick(text)}
      backColor={pick ? BLACK_PRIMARY : 'white'}
      color={pick ? 'white' : 'blakc'}
    >
      {text}
    </SelectButton>
  );
};

const HeaderSection = ({ dateType, onClick }) => {
  const buttonList = [
    {
      text: '월별',
      pick: dateType === '월별',
      onClick,
    },
    {
      text: '주별',
      pick: dateType === '주별',
      onClick,
    },
    {
      text: '일별',
      pick: dateType === '일별',
      onClick,
    },
  ];

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Breadcrumb title="주요 지표" parent="운영" />
      </HeaderWrapper>
      <HeaderSideContainer>
        {buttonList.map(({ text, pick, onClick }, index) => (
          <DateTypeSelectButton
            key={index}
            text={text}
            pick={pick}
            onClick={onClick}
          />
        ))}
      </HeaderSideContainer>
    </HeaderContainer>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderSideContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface SelectButtonProps {
  backColor: string;
  color: string;
}

const SelectButton = styled.li<SelectButtonProps>`
  display: flex;
  background-color: ${(props) => props.backColor};
  width: 80px;
  height: 40px;
  color: ${(props) => props.color};
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  margin: 20px 20px 0 0;
`;
export default HeaderSection;
