import React from 'react';
// types
import { StockHistory } from 'api/types';

interface Props {
  item: StockHistory;
}

export default function ListItem({ item }: Props) {
  return (
    <tr>
      <td>{item.bookId}</td>
      <td>{item.bookName}</td>
      <td>{item.isbn13}</td>
      <td>{item.bookConditionName}</td>
      <td>{item.locationCode}</td>
      <td>{item.changeQuantity}</td>
      <td>{item.eventType}</td>
      <td>{item.insertDate}</td>
    </tr>
  );
}
