import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
// apis
import useConversionAPI, {
  DayConversionAnalysisRes,
} from 'api/dashboard/useConversionAPI';
// components
import Breadcrumb from 'components/common/breadcrumb';
import ConversionAnalysisCard from './ConversionAnalysisCard';

export default function ConversionBoard() {
  const initDate = new Date(moment().format('YYYY-MM-DD'));
  const [date, setDate]: any = useState(initDate);
  const [
    dayConversions,
    setDayConversions,
  ] = useState<DayConversionAnalysisRes | null>(null);

  const { getDayConversionAnalysisAPI } = useConversionAPI();

  async function handleGetDayConversionAnalysis() {
    try {
      const newDayConversions = await getDayConversionAnalysisAPI({
        dashboardDate: moment(date).format('YYYY-MM-DD'),
      });
      setDayConversions(newDayConversions);
      return newDayConversions;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetDayConversionAnalysis();
  }, [date]);

  const renderConversions = () => {
    if (!dayConversions) return null;
    const result: any = [];
    for (const key in dayConversions) {
      const dayConversion = dayConversions[key];
      const empty =
        dayConversion.pvList.length === 0 && dayConversion.uvList.length === 0;

      const component = empty ? null : (
        <ConversionAnalysisCard
          key={key}
          dayConversion={dayConversion}
          type={key}
        />
      );
      result.push(component);
    }
    return result;
  };

  return (
    <Fragment>
      <Breadcrumb title="전환율 분석" parent="대시보드" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 20,
          marginBottom: 24,
        }}
      >
        <div
          style={{
            fontSize: 36,
            fontWeight: 700,
            marginRight: 16,
          }}
        >
          {moment(date).format('YYYY.M.D')} 전환율 분석
        </div>
        <KeyboardDatePicker
          value={date}
          onChange={setDate}
          format="YYYY-MM-DD"
          invalidDateMessage="날짜 형식을 맞춰주세요.(예: 2020-11-09)"
          maxDate={initDate}
        />
      </div>
      <div style={{ flex: 1, padding: '0px 20px 20px 20px' }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {renderConversions()}
        </div>
      </div>
    </Fragment>
  );
}
