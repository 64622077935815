import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
// common
import Breadcrumb from '../../common/breadcrumb';
import { toastify } from '../../../utils/common';
// api
import {
  getReturnList,
  setPickUp,
  setPickupCompletion,
} from '../../../api/store/return';
// components
import ListItem from './ListItem';
// types
import {
  ReturnInfo,
  SetPickupCompletionData,
  setPickUpData,
} from '../../../api/store/return';

export default function Return({ ...props }) {
  const [returnList, setReturnList] = useState<ReturnInfo[]>([]);
  const [selectList, setSelectList] = useState<any[]>([]);

  useEffect(() => {
    _getReturnList();
  }, []);

  // 리턴 리스트 호출
  const _getReturnList = async () => {
    try {
      const result = await getReturnList();
      setReturnList(result);
    } catch (error) {
      console.error('_getReturnList :: ', error);
    }
  };

  // 픽업 일정 안내
  const _setPickUp = async () => {
    try {
      if (selectList.length === 0) {
        toastify('error', '선택된 정보가 없습니다.');
        return false;
      }

      const data: setPickUpData[] = [];

      selectList.map((item) => {
        const returnDate = moment(item.pickup_date).format('YYYY-MM-DD HH:mm');

        const selectInfo = {
          order_id: String(item.orderId),
          return_id: String(item.return_id),
          pickup_date: String(returnDate),
        };
        data.push(selectInfo);
      });

      await setPickUp(data);
      const returnResult = await getReturnList();
      setReturnList(returnResult);
      setSelectList([]);
      toastify('success', '홈픽 일정 안내가 완료되었습니다.');
    } catch (error) {
      console.error('_setPickUp :: ', error);
    }
  };

  // 픽업 완료
  const _setPickupCompletion = async () => {
    try {
      if (selectList.length === 0) {
        toastify('error', '선택된 정보가 없습니다.');
        return false;
      }

      const data: SetPickupCompletionData[] = [];

      selectList.map((item) => {
        const selectInfo = {
          order_id: String(item.orderId),
          return_id: String(item.return_id),
          pickupDate: moment(item.pickup_date).format('YYYY-MM-DD HH:mm'),
        };
        data.push(selectInfo);
      });

      await setPickupCompletion(data);
      const returnResult = await getReturnList();
      setReturnList(returnResult);
      setSelectList([]);
      toastify('success', '픽업 완료되었습니다.');
    } catch (error) {
      console.error('_setPickUp :: ', error);
    }
  };

  // 선택 시 selectList에 추가
  const handleSetSelectList = (selectInfo: ReturnInfo) => {
    let receiveList = selectList;
    if (!selectList.find((item) => item.return_id === selectInfo.return_id)) {
      setSelectList((prev) => [...prev, selectInfo]);
    } else {
      const deleteIndex = selectList.findIndex(
        (item) => item.return_id === selectInfo.return_id,
      );
      receiveList.splice(deleteIndex, 1);
      setSelectList(receiveList);
    }
  };

  // 선택 후 리턴 일시 입력/변경 후
  const handleSetDate = (selectInfo: ReturnInfo) => {
    let receiveList = selectList;
    if (selectList.find((item) => item.return_id === selectInfo.return_id)) {
      const deleteIndex = selectList.findIndex(
        (item) => item.return_id === selectInfo.return_id,
      );
      receiveList.splice(deleteIndex, 1);
      setSelectList(receiveList);
      setSelectList((prev) => [...prev, selectInfo]);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="리턴" parent="찾아가는 서점" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="container-fluid">
                  <div
                    className="row"
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h5>리턴 리스트</h5>
                    <div className="row btn-showcase">
                      {/* <Button outline color="secondary" onClick={_setPickUp}>
                        홈픽 일정 안내
                      </Button> */}
                      <Button
                        outline
                        color="primary"
                        onClick={_setPickupCompletion}
                      >
                        픽업 일시 등록
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover" style={{ minWidth: 900 }}>
                  <thead>
                    <tr>
                      <th style={{ width: '8%' }} className="text-center">
                        주문ID
                      </th>
                      <th style={{ width: '10%' }}>고객명</th>
                      <th style={{ width: '10%' }}>전화번호</th>
                      <th style={{ width: '22%' }}>주소1</th>
                      <th style={{ width: '15%' }}>주소2</th>
                      <th style={{ width: '20%' }}>픽업 날짜</th>
                      <th style={{ width: '5%' }}>선택</th>
                    </tr>
                  </thead>
                  <tbody>
                    {returnList.length > 0 &&
                      returnList.map((item) => (
                        <ListItem
                          key={item.return_id}
                          returnInfo={item}
                          onSetSelectList={handleSetSelectList}
                          onSetDate={handleSetDate}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
