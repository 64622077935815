import styled from 'styled-components';
import React, { useState } from 'react';
import { IoRemoveCircle } from 'react-icons/io5';
import { GREY_DARK, GREY_LIGHT } from 'styles/colors';
import ModalComponent from 'components/common/ModalComponent';
import ButtonComponent from 'components/common/ButtonComponent';

const RegistBookTextForm = ({ book, cancelBookHandle }) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(book.content);

  const onChange = (text) => {
    const textValue = text.target.value;
    setContent(textValue);
  };

  const cancelBook = () => {
    cancelBookHandle(book.bookId);
  };

  return (
    <BookListWrapper>
      <BookWrapper
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <BookName>{book?.bookName}</BookName>
        <div>&nbsp; / &nbsp; </div>
        <div>{book?.authorName}</div>
      </BookWrapper>
      <CancelButtonWrapper onClick={() => cancelBook()}>
        <IoRemoveCircle size={30} color={GREY_DARK} />
      </CancelButtonWrapper>
      <ModalComponent
        width={1000}
        height={640}
        visible={visible}
        backgroundColor={'white'}
      >
        <div
          style={{
            padding: 15,
          }}
        >
          <div style={{ fontSize: 20 }}>{book?.bookName}</div>
          <div style={{ fontWeight: 700 }}>{book?.authorName}</div>
        </div>
        <TextareaInput value={content} onChange={onChange}>
          {content}
        </TextareaInput>
        <ButtonContainer>
          <ButtonComponent
            title={'취소'}
            onClick={() => {
              setVisible(!visible);
            }}
          />
          <div />
          <ButtonComponent
            title={'수정'}
            onClick={() => {
              book.content = content;
              book.edit = true;
              setVisible(!visible);
            }}
          />
        </ButtonContainer>
      </ModalComponent>
    </BookListWrapper>
  );
};

export default RegistBookTextForm;

const BookListWrapper = styled.li`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const BookWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 27vw;
  height: 40px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  align-items: center;
  padding-left: 20px;
  border-radius: 10px;
`;

const CancelButtonWrapper = styled.div`
  display: flex;
  width: 3vw;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const BookName = styled.div`
  font-weight: 700;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 1000px;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  div {
    margin-right: 20px;
  }
`;

const TextareaInput = styled.textarea`
  display: flex;
  width: 900px;
  height: 400px;
  padding: 15px;
  margin-left: 20px;
  border: solid 1px ${GREY_LIGHT};
  border-radius: 5px;
  :focus {
    border: solid 1px black;
  }
`;
