import React, { Fragment, useEffect } from 'react';

type Props = {
  isLoading: boolean;
  setIsLoading?: (isLoading: boolean) => void;
};

const Loader = ({ isLoading, setIsLoading }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      setIsLoading && setIsLoading(false);
    }, 1000);
  }, [isLoading]);

  return (
    <Fragment>
      <div
        style={{ position: 'absolute' }}
        className={`loader-wrapper ${isLoading ? '' : 'loderhide'}`}
      >
        <div className="loader bg-white">
          <div className="whirly-loader"> </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;
