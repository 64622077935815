import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

interface Props {
  data: any[];
  dataKey: string;
  title?: string;
  colorType?: 'warm' | 'cool';
}
const PieChartComponent = ({
  data,
  dataKey,
  colorType = 'warm',
  title,
}: Props) => {
  const copiedData = data.slice(0, data.length - 1);
  const sortedData = copiedData.sort((a, b) => a[dataKey] - b[dataKey]);
  const DATA = [...sortedData, data[data.length - 1]];

  const COLORS =
    colorType === 'warm'
      ? ['#FDC2AD', '#FA784A', '#D73D06', '#752203', '#FEE7DF']
      : ['#8EC0F7', '#2E8CF0', '#0D5AAC', '#06284D', '#D6E7FC'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={COLORS[index % COLORS.length]}
        style={{ fontSize: 20, fontWeight: 700 }}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderLegend = (props) => {
    const { payload } = props;
    const copiedPayload = payload.slice(0, payload.length - 1);
    const reversedPayload = copiedPayload.reverse();
    const DATA = [...reversedPayload, payload[payload.length - 1]];
    return (
      <ul>
        {DATA.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 10,
              }}
            />
            <div style={{ fontWeight: 700 }}>{entry.value}</div>
            <div>({entry.payload[dataKey]})</div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      {title ? (
        <div
          style={{
            textAlign: 'center',
            fontSize: 24,
            marginBottom: 4,
            marginTop: 8,
          }}
        >
          {title}
        </div>
      ) : null}
      <PieChart width={400} height={500}>
        <Pie
          data={DATA}
          innerRadius={30}
          outerRadius={120}
          dataKey={dataKey}
          label={renderCustomizedLabel}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend
          layout="vertical"
          content={renderLegend}
          formatter={(value, entry) => {
            return '';
          }}
        />
      </PieChart>
    </div>
  );
};

export default PieChartComponent;
