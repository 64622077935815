import { combineReducers } from 'redux';
import Customizer from './customizer.reducer';
import auth from './auth';

const reducers = combineReducers({
  Customizer,
  auth,
});

export default reducers;
