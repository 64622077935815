import React, { Fragment } from 'react';
// import { Home } from 'react-feather';
// import { Link } from 'react-router-dom';

type Props = {
  title: string;
  parent?: string;
  page?: string;
  getMovieListAll?: () => void;
};

const Breadcrumb = (props: Props) => {
  const breadcrumb = props;

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <h3>{breadcrumb.title}</h3>
                <ol className="breadcrumb" style={{ marginLeft: 16 }}>
                  {/* <li className="breadcrumb-item">
                     <a href="javascript:;" onClick={props.getMovieListAll}> 
                    {breadcrumb.page === 'kt' ? (
                      <Home
                        onClick={breadcrumb.getMovieListAll}
                        style={{ cursor: 'pointer', color: '#4466f2' }}
                        size={20}
                      />
                    ) : (
                      <Link to="/">
                        <Home
                          size={20}
                        />
                      </Link>
                    )}
                  </li> */}
                  {/* {breadcrumb.parent && (
                    <li className="breadcrumb-item">{breadcrumb.parent}</li>
                  )} */}
                  {breadcrumb.parent ? (
                    <li className="breadcrumb-item active">
                      / {breadcrumb.parent}
                    </li>
                  ) : null}
                </ol>
              </div>
            </div>
            {/* <!-- Bookmark Start--> */}
            {/* <Bookmark /> */}
            {/* <!-- Bookmark Ends--> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
