import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IoImageOutline } from 'react-icons/io5';

interface Props {
  image: string | null;
  onChange: () => void;
  onClick: (fileObj: any) => void;
  id: string;
}

const ImageForm: FunctionComponent<Props> = ({
  image,
  onChange,
  onClick,
  id,
}) => {
  return (
    <Container>
      <Image src={image !== '' ? image : null} onClick={() => onChange()} />
      <ImageInput
        type="file"
        id={id}
        accept=".jpg, .jpeg, .png"
        onChange={onClick}
        onClick={(event: any) => {
          event.target.value = null;
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
`;

const Image = styled.img`
  display: flex;
  width: 40vw;
  height: 20vw;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: white;
  cursor: pointer;
`;

const ImageInput = styled.input`
  width: 70%;
  border-radius: 75px;
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export default ImageForm;
