import React, { useEffect, useState } from 'react';
// api
import { setStock, cancelStock } from '../../../api/store/returnStock';
// common
import { toastify } from '../../../utils/common';
// styles
import { Button } from 'reactstrap';
// types
import {
  StockInfo,
  Location,
  SetStockData,
} from '../../../api/store/returnStock';

const BOOK_CONDITION_LIST = [
  {
    id: 1,
    code: 1,
    bookCondition: '중고 최상',
  },
  {
    id: 2,
    code: 2,
    bookCondition: '중고 상',
  },
  {
    id: 3,
    code: 3,
    bookCondition: '중고 중',
  },
  {
    id: 4,
    code: 4,
    bookCondition: '중고 수선',
  },
  {
    id: 5,
    code: 5,
    bookCondition: '중고폐기',
  },
];

interface Props {
  stockInfo: StockInfo;
  locationList: Location[];
  onGetStockList: () => Promise<void>;
}

export default function ListItem({
  stockInfo,
  locationList,
  onGetStockList,
}: Props) {
  const [location, setLocation] = useState('');
  const [bookCondition, setBookCondition] = useState(1);

  // 재고 입고
  const _setStock = async () => {
    try {
      if (location === '') {
        toastify('error', '재고 위치를 선택해주세요.');
        return false;
      }
      const data: SetStockData = {
        order_id: String(stockInfo.orderId),
        user_id: String(stockInfo.userId),
        location_code: location,
        book_condition: String(bookCondition),
        book_id: String(stockInfo.book_id),
        orderBook_id: String(stockInfo.orderBook_id),
      };
      await setStock(data);
      toastify('success', '입고가 완료되었습니다.');
      await onGetStockList();
    } catch (error) {
      console.error('_setStock :: ', error);
    }
  };

  const _cancelStock = async () => {
    try {
      await cancelStock({
        orderBookId: stockInfo.orderBook_id,
        locationCode: location,
      });
      toastify('success', '취소가 완료되었습니다.');
      await onGetStockList();
    } catch (error) {
      console.error('_cancelStock :: ', error);
    }
  };

  // 위치 상태 변경
  const _setLocationLocation = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setLocation(e.target.value);
  };

  // 책 상태 변경
  const _setBookCondition = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBookCondition(Number(e.target.value));
  };

  const checkBookCondition = (bookCondition: number) => {
    if (bookCondition === 1) return '중고 최상';
    if (bookCondition === 2) return '중고 상';
    if (bookCondition === 3) return '중고 중';
    if (bookCondition === 4) return '중고 수선';
    if (bookCondition === 5) return '중고 폐기';
  };

  useEffect(() => {
    if (stockInfo.stock.length > 0) {
      setLocation(stockInfo.stock[0].location_code);
    } else {
      setLocation(locationList[0].locationCode);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <tr
      style={{
        backgroundColor: Boolean(stockInfo.stock_in_yn) ? '#ccc' : undefined,
      }}
    >
      <td>{stockInfo.orderId}</td>
      <td>{stockInfo.ordererName}</td>
      <td>{stockInfo.userIdGiftReceiver}</td>
      <td>{stockInfo.address}</td>
      <td>{stockInfo.book_name}</td>
      <td>{stockInfo.isbn}</td>
      <td>
        <select
          className="form-control digits"
          name="selectStatus"
          value={location}
          onChange={_setLocationLocation}
          disabled={Boolean(stockInfo.stock_in_yn) ? true : false}
        >
          {locationList.length !== 0 &&
            locationList.map((item) => (
              <option key={item.locationCode} value={item.locationCode}>
                {item.locationCode}
              </option>
            ))}
        </select>
      </td>
      <td>
        <select
          className="form-control digits"
          value={bookCondition}
          onChange={_setBookCondition}
          disabled={Boolean(stockInfo.stock_in_yn) ? true : false}
        >
          {BOOK_CONDITION_LIST.map((item) => (
            <option key={item.id} value={item.code}>
              {item.bookCondition}
            </option>
          ))}
        </select>
      </td>
      <td>
        <Button
          outline
          color={stockInfo.stock_in_yn ? 'danger' : 'primary'}
          onClick={() => {
            if (stockInfo.stock_in_yn) {
              _cancelStock();
            } else {
              _setStock();
            }
          }}
        >
          {stockInfo.stock_in_yn ? '취소' : '입고'}
        </Button>
      </td>
      <td>
        {stockInfo.stock.length > 0 &&
          stockInfo.stock.map((item) => {
            return (
              <p key={item.stock_id}>{`(${checkBookCondition(
                item.book_condition,
              )} | ${item.location_code} | ${item.stock_quantity})`}</p>
            );
          })}
      </td>
      <td>{stockInfo.ellapsed_time}</td>
    </tr>
  );
}
