import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  date: number;
  maxDate?: number;
  getData: (date: any) => void;
}

const SelectDatePick: FunctionComponent<Props> = ({
  date,
  maxDate = new Date(),
  getData,
}) => {
  return (
    <SelectDate
      selected={date}
      dateFormat="yyyy-MM-dd"
      onChange={(date: any) => {
        getData(new Date(date).getTime());
      }}
      placeholderText="클릭해주세요."
      maxDate={new Date(maxDate)}
    />
  );
};

const SelectDate = styled(DatePicker)`
  width: 200px;
  height: 35px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
`;

export default SelectDatePick;
