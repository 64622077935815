import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
// apis
import useConversionAPI, {
  DayConversion,
  DayConversionDetail,
} from 'api/dashboard/useConversionAPI';
// components
import Breadcrumb from 'components/common/breadcrumb';
import Chart from './Chart';
import ConversionList from './ConversionList';
import ConversionDetailList from './ConversionDetailList';

export default function ConversionBoard() {
  const initDate = new Date(moment().format('YYYY-MM-DD'));
  const [date, setDate]: any = useState(initDate);
  const [detailTitle, setDetailTitle] = useState('');
  const [behavior, setBehavior] = useState('');
  const [dayConversions, setDayConversions] = useState<DayConversion[]>([]);
  const [dayConversionDetails, setDayConversionDetails] = useState<
    DayConversionDetail[]
  >([]);

  const { getDayConversionAPI, getDayConversionDetailAPI } = useConversionAPI();

  async function handleGetDayConversion(date) {
    try {
      const res = await getDayConversionAPI({
        dashboardDate: moment(date).format('YYYY-MM-DD'),
      });
      const newDayConversions: any = [];
      for (let i = 0; i < res.length; i++) {
        const r = res[i];
        newDayConversions.push({
          target: `${r.fromBehavior} - ${r.fromBehaviorName}`,
          sort: `${r.toBehavior} - ${r.toBehaviorName}`,
          ...r,
        });
      }
      setDayConversions(newDayConversions);
      return newDayConversions;
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGetDayConversionDetail(item) {
    try {
      const newDayConversionDetails = await getDayConversionDetailAPI({
        dashboardDate: moment(date).format('YYYY-MM-DD'),
        fromBehavior: item.fromBehavior,
        toBehavior: item.toBehavior,
      });
      setDetailTitle(`${item.target} -> ${item.sort}`);
      setBehavior(`${item.fromBehavior}${item.toBehavior}`);
      setDayConversionDetails(newDayConversionDetails);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGetInitData() {
    try {
      const newDayConversions = await handleGetDayConversion(date);
      if (newDayConversions && newDayConversions.length > 0) {
        handleGetDayConversionDetail(newDayConversions[0]);
      } else {
        setDayConversionDetails([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetInitData();
  }, [date]);

  return (
    <Fragment>
      <Breadcrumb title="전환율 현황" parent="대시보드" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 20,
          marginBottom: 24,
        }}
      >
        <div
          style={{
            fontSize: 36,
            fontWeight: 700,
            marginRight: 16,
          }}
        >
          {moment(date).format('YYYY.M.D')} 전환율 통계
        </div>
        <KeyboardDatePicker
          value={date}
          onChange={setDate}
          format="YYYY-MM-DD"
          invalidDateMessage="날짜 형식을 맞춰주세요.(예: 2020-11-09)"
          maxDate={initDate}
        />
      </div>
      <div style={{ flex: 1, paddingRight: 20 }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Chart dayConversionDetails={dayConversionDetails} />
          <div style={{ display: 'flex', paddingLeft: 40 }}>
            <div style={{ width: '100%' }}>
              <ConversionList
                behavior={behavior}
                dayConversions={dayConversions}
                handleGetDayConversionDetail={handleGetDayConversionDetail}
              />
            </div>
            <div style={{ width: '100%', marginLeft: 20 }}>
              <h4 style={{ padding: 5 }}>{detailTitle}</h4>
              <ConversionDetailList
                dayConversionDetails={dayConversionDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
