import React from 'react';
// components
import StockUpListitem from './StockUpListitem';
// types
import { StockBook } from 'api/types';

interface Props {
  stockBookList: StockBook[];
}

export default function StockUpList({ stockBookList }: Props) {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <h5>재고확보 예정 리스트</h5>
            </div>
          </div>
          <div className="table-responsive-xl">
            <table className="table table-hover" style={{ minWidth: 1300 }}>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>책 ID</th>
                  <th style={{ width: '30%' }}>책 제목</th>
                  <th style={{ width: '10%' }}>책 상태</th>
                  <th style={{ width: '10%' }}>수량</th>
                  <th style={{ width: '20%' }}>주문ID</th>
                  <th style={{ width: '20%' }}>재고정보</th>
                </tr>
              </thead>
              <tbody>
                {stockBookList.length > 0 &&
                  stockBookList.map((bookInfo) => (
                    <StockUpListitem
                      key={bookInfo.bookId}
                      bookInfo={bookInfo}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
