export const title = '배너 관리 ';
export const tabData = [
  { id: 1, name: '사용자 지정' },
  // { id: 2, name: '전체' },
  // { id: 3, name: '비서재' },
  // { id: 4, name: '공지/기타' },
  // { id: 5, name: '삭제내역' },
];

export const landingTypeUrlList = [
  { value: 'Invite', label: 'Invite' },
  { value: 'BLibraryDetail', label: 'BLibraryDetail' },
  { value: 'BCurationDetail', label: 'BCurationDetail' },
  { value: 'Notification', label: 'Notification' },
  { value: 'OtherStore', label: 'OtherStore' },
  { value: 'Book', label: 'Book' },
];

export const landingTypeUrlFilterList = [
  { value: '전체', label: '전체' },
  { value: '', label: 'null' },
  { value: 'Invite', label: 'Invite' },
  { value: 'BLibraryDetail', label: 'BLibraryDetail' },
  { value: 'BCurationDetail', label: 'BCurationDetail' },
  { value: 'Notification', label: 'Notification' },
  { value: 'OtherStore', label: 'OtherStore' },
];

export const landingTypeList = [
  {
    value: 'INTERNAL',
    label: 'internal',
  },
  {
    value: 'EXTERNAL',
    label: 'external',
  },
];
