import React, { Fragment } from 'react';
// redux
import useAuthRedux from 'reducers/auth/useAuthRedux';
// hooks
import { useHistory } from 'react-router-dom';
// styles
// import man from '../../../assets/images/dashboard/user.png';
import {
  User,
  //  Mail, Lock, Settings,
  LogOut,
} from 'react-feather';
import { Flex } from 'styles/index';

const UserMenu = () => {
  const history = useHistory();
  const { authDispatch } = useAuthRedux();

  function handleLogout() {
    window.localStorage.removeItem('sessionDueTime');
    window.localStorage.removeItem('sessionGrade');
    authDispatch.setUser({ user: null });
    history.replace('/login');
  }

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <User style={{ width: 28, height: 28 }} />
          {/* <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={man}
            alt="header-user"
          /> */}
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          {/* <li>
            <a href="#javascript">
              <User />
              Edit Profile
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Mail />
              Inbox
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Lock />
              Lock Screen
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Settings />
              Settings
            </a>
          </li> */}
          <li>
            <a onClick={handleLogout}>
              <Flex style={{ alignItems: 'center' }}>
                <LogOut style={{ marginTop: 0, marginRight: 8 }} /> 로그아웃
              </Flex>
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
