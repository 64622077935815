import { apiGet, apiPost, apiPut } from '../useAxios';

export interface ReturnStatus {
  statusCode: number;
  statusName: string;
}

export interface ReturnInfo {
  orderId: number;
  userId: number;
  addressId: number;
  ordererName: string;
  orderStatus: number;
  orderStatusName: string;
  orderDate: string;
  return_id: number;
  returnStatus: number;
  returnStatusName: string;
  pickup_date: string;
  insert_date: string;
  receiver_name: string;
  receiver_phone: string;
  address: string;
  address1: string;
  address2: string;
  delivery_request: string;
  update_date: string;
}

export interface setPickUpData {
  order_id: string;
  return_id: string;
  pickup_date: string;
}

export interface SetPickupCompletionData {
  order_id: string;
  return_id: string;
  pickupDate: string;
}

// 리턴 상태 리스트
export const getReturnStatusList = async (): Promise<ReturnStatus[]> => {
  try {
    const result = await apiGet('admin/store/return/status');
    return result.data.result.bookPreparationStatusList;
  } catch (error) {
    throw error;
  }
};

// 리턴 리스트
export const getReturnList = async (): Promise<ReturnInfo[]> => {
  try {
    const result = await apiGet('admin/store/return');
    return result.data.result.list;
  } catch (error) {
    throw error;
  }
};

// 픽업 날짜 입력
export const setPickUp = async (return_list: setPickUpData[]): Promise<any> => {
  try {
    const data = {
      return_list: return_list,
    };
    const result = await apiPut('admin/store/return/pickup', data);
    return result.data.result.bookPreparationStatusList;
  } catch (error) {
    throw error;
  }
};

// 픽업 완료
export const setPickupCompletion = async (
  return_list: SetPickupCompletionData[],
): Promise<any> => {
  try {
    const data = {
      return_list: return_list,
    };
    const result = await apiPost('admin/store/return/pickup', data);
    return result;
  } catch (error) {
    throw error;
  }
};
