import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { GREY_DARK } from '../../../../styles/colors';
import { useHistory } from 'react-router';

interface Props {
  curationId: number;
  content: string;
  deleteYN: number;
  imageUrl: string;
  title: string;
  publishDate: string;
  imageWidth: number;
}

const EditorsPickInfo: FunctionComponent<Props> = ({
  curationId,
  title,
  imageUrl,
  content,
  deleteYN,
  publishDate,
  imageWidth,
}) => {
  const history = useHistory();
  const creatorClickHandle = () => {
    history.push({ pathname: `/editorsPick/${curationId}` });
  };

  return (
    // curationId
    <EditorsPickWrapper onClick={() => creatorClickHandle()}>
      <EditorsPickIdWrapper>{curationId}</EditorsPickIdWrapper>
      <EditorsPickImage src={imageUrl} width={imageWidth} />
      <InfoContainer>
        <Title>{title}</Title>
      </InfoContainer>
    </EditorsPickWrapper>
  );
};

const EditorsPickWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

interface EditorsPickImageProps {
  width: number;
}

const EditorsPickImage = styled.img<EditorsPickImageProps>`
  width: ${(props) => props.width}px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
`;

const InfoContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
`;

const Title = styled.li`
  font-size: 20px;
  font-weight: 700;
`;

const EditorsPickIdWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 10px;
  z-index: 1;
  top: -10px;
  left: -10px;
  background-color: #2f3c4e;
  color: white;
  font-weight: 700;
  font-family: 'jua';
`;

export default EditorsPickInfo;
