import React, { Fragment, useState } from 'react';
// components
import AllUsers from './AllUsers';
import UserDetail from './UserDetail';
import UserTracking from './UserTracking';
// styles
import * as colors from 'styles/colors';
import { Flex } from 'styles/index';
import { Button } from 'reactstrap';
// types
import { User } from 'api/types';

type Type = 'detail' | 'tracking' | string;
export default function Users() {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [type, setType] = useState<Type>('detail');

  const types = [
    { key: 'detail', name: '상세 정보', disabled: false },
    { key: 'tracking', name: '트래킹', disabled: false },
    { key: 'order', name: '주문 목록', disabled: true },
    { key: 'buy', name: '결제 내역', disabled: true },
    { key: 'mileage', name: '마일리지 내역', disabled: true },
    { key: 'address', name: '주소지', disabled: true },
    { key: 'library', name: '서재 정보', disabled: true },
    { key: 'bookClip', name: '북클립', disabled: true },
    { key: 'calendar', name: '캘린더', disabled: true },
    { key: 'connectHistory', name: '접속 내역', disabled: true },
    { key: 'loginHistory', name: '로그인 내역', disabled: true },
    { key: 'device', name: '사용 기기', disabled: true },
    { key: 'clickHistory', name: '클릭 내역', disabled: true },
    { key: 'searchHistory', name: '검색 내역', disabled: true },
    { key: 'followHistory', name: '팔로우 내역', disabled: true },
  ];
  return (
    <Fragment>
      <div style={{ flex: 1 }}>
        <AllUsers
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
        <Flex style={{ flexWrap: 'wrap', marginBottom: 10 }}>
          {types.map((t, i) => (
            <Button
              key={t.key}
              color="primary"
              disabled={t.disabled}
              outline={type !== t.key}
              onClick={() => setType(t.key)}
              style={{
                marginRight: types.length - 1 === i ? 0 : 10,
                marginBottom: 10,
                borderColor: t.disabled ? colors.GREY : undefined,
                color: t.disabled ? colors.GREY : undefined,
              }}
            >
              {t.name}
            </Button>
          ))}
        </Flex>
        <div style={{ marginBottom: 20 }}>
          {type === 'detail' ? <UserDetail user={selectedUser} /> : null}
          {type === 'tracking' ? (
            <UserTracking userId={selectedUser?.userId} />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}
