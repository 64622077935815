import React, { useState } from 'react';
// common
import { toastify } from 'utils/common';
// types
import { Stock } from 'api/types';

interface Props {
  stockInfo: Stock;
  locationList: string[];
  handleUpdateStockQuantity: any;
  handleUpdateStockLocation: any;
}

export default function ListItem({
  stockInfo,
  locationList,
  handleUpdateStockQuantity,
  handleUpdateStockLocation,
}: Props) {
  const [currentLocation, setCurrentLocation] = useState(
    stockInfo.locationCode,
  );
  const [stockQuantity, setStockQuantity] = useState(
    stockInfo?.stockQuantity || 0,
  );

  // 위치 상태 변경
  async function handleChangeLocation(e) {
    try {
      setCurrentLocation(e.target.value);
      await handleUpdateStockLocation(stockInfo, e.target.value);
    } catch (error) {
      console.log(error);
    }
  }

  // 재고량 수량 변경
  async function handleChangeStockQuantity(e) {
    const newValue = e.target.value;
    if (/\D/g.test(newValue)) {
      toastify('error', '숫자만 입력해 주세요.');
      return;
    }
    setStockQuantity(newValue);
  }

  async function handleSubmitStockQuantity(e) {
    e.preventDefault();
    try {
      await handleUpdateStockQuantity(stockInfo, stockQuantity);
      toastify('success', '재고 수량이 변경되었습니다.');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <tr>
      <td style={{ width: '15%' }}>
        <select
          className="form-control digits"
          name="selectStatus"
          value={currentLocation}
          onChange={handleChangeLocation}
        >
          {locationList.length !== 0 &&
            locationList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
      </td>
      <td style={{ width: '10%' }}>{stockInfo.bookId}</td>
      <td style={{ width: '10%' }}>{stockInfo.bookConditionName}</td>
      <td style={{ width: '10%' }}>
        <form onSubmit={handleSubmitStockQuantity}>
          <input
            className="form-control"
            type="text"
            value={stockQuantity}
            onChange={handleChangeStockQuantity}
          />
        </form>
      </td>
      <td style={{ width: '30%' }}>{stockInfo.bookName}</td>
      <td style={{ width: '15%' }}>{stockInfo.isbn}</td>
      <td style={{ width: '10%' }}>{stockInfo.orderingQuantity}</td>
    </tr>
  );
}
