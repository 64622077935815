import { toastify } from 'utils/common';
// apis
import useAPI from 'api/useAPI';
// types
import { EmployeeBookOrderStatic, EmployeeBookOrder } from 'api/types';

export interface GetEmployeeBookOrderRes {
  orderListAll: EmployeeBookOrder[];
  orderListStatistic: EmployeeBookOrderStatic[];
}

export default function useEmployeeAPI() {
  const { getHandler, postHandler } = useAPI();

  async function getEmployeeBookOrderAPI(params: {
    yearMonth: string; // 'YYYY-MM'
  }): Promise<GetEmployeeBookOrderRes> {
    try {
      const res: any = await getHandler('/etc/employee-book-order', params);
      return res.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function createEmployeeBookOrderAPI(params: {
    bookIdList: number[]; // 주문 책ID 리스트
    ordererName: string; // 주문 직원 이름
    addressName: string; // 지점 종류 ('라캐-논현점', '라캐-강남점#9', '라캐-강남점#10') 중 하나
    ordererPhone: string; // 배송시 사용될 연락처
    businessRelatedYn: number; // 1-업무도서, 0-비업무도서
  }): Promise<{}> {
    try {
      const res: any = await postHandler('/etc/employee-book-order', params);
      return res.data?.result;
    } catch (error) {
      const errorMessage = error?.response?.data?.errorMessage;
      if (errorMessage) {
        toastify('error', errorMessage);
      }

      throw error;
    }
  }

  return {
    getEmployeeBookOrderAPI,
    createEmployeeBookOrderAPI,
  };
}
