import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { title } from './CreatorPickConstants';
import { IoMdAdd } from 'react-icons/io';
import { BLACK_PRIMARY } from 'styles/colors';
import { useHistory } from 'react-router';
import useCreatorAPI from 'api/operation/useCreatorAPI';
import CreatorInfo from './aComponents/CreatorInfo';

interface CreatorProps {
  id: number;
  name: string;
  creatorImage: string;
  subscriberCount: string;
  link: string;
  deleteYN: number;
}

const CreatorPresenter = () => {
  const [creatorData, setCreatorData] = useState([]);
  const filteredDelCreatorData = creatorData.filter(
    (creator: any) => creator.deleteYN === 0,
  );

  const history = useHistory();
  const { getCreatorAPI } = useCreatorAPI();

  const getCreatorData = async () => {
    const results = await getCreatorAPI(null);

    setCreatorData(results);
  };

  const createCreatorHandle = () => {
    history.push({ pathname: '/create-creator' });
  };

  useEffect(() => {
    getCreatorData();
  }, []);

  return (
    <>
      <HeaderWrapper>
        <>
          <Title>{title}</Title>
          <HeaderTabContainer onClick={() => createCreatorHandle()}>
            <IoMdAdd size={30} color={'white'} />
          </HeaderTabContainer>
        </>
      </HeaderWrapper>
      <Container>
        <CreatorContainer>
          {filteredDelCreatorData.length > 0
            ? filteredDelCreatorData.map((creator: CreatorProps) => (
                <CreatorInfo key={creator.id} {...creator} />
              ))
            : null}
        </CreatorContainer>
      </Container>
    </>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: 'jua';
`;

const HeaderTabContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: ${BLACK_PRIMARY};
  border-radius: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px 0 20px 0px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
`;

const CreatorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  justify-content: space-between;
  grid-gap: 20px;
`;

export default CreatorPresenter;
