import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import Footer from './common/footer';
// import RightSidebar from './common/right-sidebar';
// import ThemeCustomizer from './common/theme-customizer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  children: React.ReactNode;
};

export default function AppLayout({ children }: Props) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div>
        <div className="page-wrapper">
          <div className="page-body-wrapper">
            <Header />
            <Sidebar />
            {/* <RightSidebar /> */}
            <div className="page-body">{children}</div>
            <Footer />
            {/* <ThemeCustomizer /> */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </MuiPickersUtilsProvider>
  );
}
