import React from 'react';
// styles
import { Table } from 'styles/index';
// types
import { DayConversion } from 'api/dashboard/useConversionAPI';

interface Props {
  dayConversions: DayConversion[];
  handleGetDayConversionDetail: any;
  behavior: string;
}
function ConversionList({
  dayConversions,
  handleGetDayConversionDetail,
  behavior,
}: Props) {
  const columns = React.useMemo(
    () => [
      {
        Header: '대상',
        columns: [
          {
            Header: '종류',
            accessor: 'target',
          },
          {
            Header: 'UV',
            accessor: 'fromUv',
          },
          {
            Header: 'PV',
            accessor: 'fromPv',
          },
        ],
      },
      {
        Header: '전환',
        columns: [
          {
            Header: '종류',
            accessor: 'sort',
          },
          {
            Header: 'UV',
            accessor: 'toUv',
          },
          {
            Header: 'PV',
            accessor: 'toPv',
          },
        ],
      },
      {
        Header: '전환율',
        columns: [
          {
            Header: 'UV',
            accessor: 'conversionUv',
          },
          {
            Header: 'PV',
            accessor: 'conversionPv',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Table
      fullScreen
      columns={columns}
      data={dayConversions}
      onPress={handleGetDayConversionDetail}
      type="conversion"
      activeValue={behavior}
      style={{ marginBottom: 20 }}
    />
  );
}

export default ConversionList;
