import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// hooks
import { useTable } from 'react-table';
// styles
import * as colors from 'styles/colors';
import { Button } from 'reactstrap';

interface Props {
  data: any[];
  columns: any[];
  onPress?: any;
  type?: string;
  activeValue?: any;
  hasMoreButton?: boolean;
  fullScreen?: boolean;
  rowSpanIds?: string[];
  padding?: 'big' | 'normal' | 'small';
  ellapsedTimePress?: any;
  style?: {};
}
function Table({
  data,
  onPress,
  activeValue,
  columns,
  type,
  hasMoreButton,
  fullScreen,
  rowSpanIds,
  padding = 'normal',
  ellapsedTimePress,
  style,
}: Props) {
  const [isOpen, setIsOpen] = useState(!hasMoreButton);
  const [tableData, setTableData]: any = useState([]);
  const isOnPress = typeof onPress === 'function';

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: hasMoreButton ? tableData : data,
  });

  function getActiveStatus(row) {
    switch (type) {
      case 'traffic':
        return activeValue === `${row.original?.pageCode}`;
      case 'conversion':
        return (
          activeValue ===
          `${row.original?.fromBehavior}${row.original?.toBehavior}`
        );
      case 'allUsers':
        return activeValue === row.original?.userId;
      case 'orderStatusCode':
        return activeValue === row.original?.orderStatus;
      case 'orders':
        return activeValue === row.original?.orderId;

      default:
        return false;
    }
  }

  function addSpecial(id) {
    if (id === 'conversionUv' || id === 'conversionPv') {
      return '%';
    }
    if (id === 'price' || id === 'sumPrice' || id === 'balance') {
      return '원';
    }
    return '';
  }

  function customComponent(id, origin) {
    if (id === 'sendingStatus') {
      return origin.sendingStatus ? '성공' : '실패';
    }
    if (id === 'reservedYn') {
      return origin.reservedYn ? '예약' : '전송';
    }
    if (id === 'ellapsedTime' && ellapsedTimePress && origin.purchaseYn) {
      return (
        <div>
          <div>{origin.ellapsedTime}</div>
          <Button
            color="danger"
            onClick={() => ellapsedTimePress(origin)}
            style={{ padding: '2px 8px' }}
          >
            강제 결제
          </Button>
        </div>
      );
    }
    return '';
  }

  useEffect(() => {
    if (hasMoreButton) {
      if (isOpen) {
        setTableData(data);
      } else {
        setTableData(data.slice(0, 7));
      }
    }
  }, [hasMoreButton, isOpen, data]);

  return (
    <Container style={style} fullScreen={fullScreen} padding={padding}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <HeaderTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </HeaderTr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <ContentTr
                {...row.getRowProps()}
                style={{
                  backgroundColor: getActiveStatus(row)
                    ? ' rgba(0, 0, 0, 0.1)'
                    : undefined,
                }}
                isOnPress={isOnPress}
                onClick={(e) => {
                  onPress && onPress(row.original);
                  e.stopPropagation();
                }}
              >
                {row.cells.map((cell) => {
                  const id = cell.column.id;
                  const v = cell.value;
                  const origin = cell.row.original;

                  if (
                    rowSpanIds &&
                    rowSpanIds.length > 0 &&
                    rowSpanIds.includes(id)
                  ) {
                    if (Boolean(origin?.rowSpan)) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          rowSpan={origin?.rowSpanLength}
                        >
                          {customComponent(id, origin) || (
                            <>
                              {typeof v === 'number' ? v.toLocaleString() : v}
                              {addSpecial(id)}
                            </>
                          )}
                        </td>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <td {...cell.getCellProps()}>
                        {customComponent(id, origin) || (
                          <>
                            {typeof v === 'number' ? v.toLocaleString() : v}
                            {addSpecial(id)}
                          </>
                        )}
                      </td>
                    );
                  }
                })}
              </ContentTr>
            );
          })}
        </tbody>
      </table>
      {data.length <= 7 || !hasMoreButton ? null : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Button color="primary" onClick={() => setIsOpen((prev) => !prev)}>
            {isOpen ? '접기' : '더보기'}
          </Button>
        </div>
      )}
    </Container>
  );
}

export default Table;

const Container = styled.div`
  display: ${({ fullScreen }) => (fullScreen ? 'block' : 'inline-block')};
  border-radius: 12px;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: ${({ fullScreen }) => (fullScreen ? '100%' : undefined)};

  table {
    width: ${({ fullScreen }) => (fullScreen ? '100%' : undefined)};
    border-spacing: 0;

    th {
      margin: 0;
      padding: 10px 20px 10px 20px;
      text-align: center;
      border-right: 1px solid ${colors.BORDER_GREY};

      :last-child {
        border-right: 0;
      }
    }

    td {
      margin: 0;
      padding: ${({ padding }) => {
        if (padding === 'big') return '16px 20px 16px 20px';
        if (padding === 'normal') return '12px 20px 12px 20px';
        if (padding === 'small') return '8px 20px 8px 20px';
      }};
      text-align: center;
      border-right: 1px solid ${colors.BORDER_GREY};

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const HeaderTr = styled.tr`
  background-color: ${colors.WHITE};
  border-bottom: 1px solid ${colors.BORDER_GREY};
  font-size: 16px;

  cursor: ${({ isOnPress }) => {
    return isOnPress && 'pointer';
  }};

  :last-child {
    th {
      border-bottom: 5px double ${colors.BORDER_GREY};
    }
    td {
      border-bottom: 0;
    }
  }

  :hover {
    background-color: ${({ isOnPress }) => {
      return isOnPress && colors.GREY_WITH_OPACITY;
    }};
  }
`;

const ContentTr = styled.tr`
  background-color: ${colors.WHITE};
  border-bottom: 1px solid ${colors.BORDER_GREY};

  cursor: ${({ isOnPress }) => {
    return isOnPress && 'pointer';
  }};

  :last-child {
    border-bottom: 0px;
  }

  :hover {
    background-color: ${({ isOnPress }) => {
      return isOnPress && colors.GREY_WITH_OPACITY;
    }};
  }
`;
