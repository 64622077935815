import React, { useState } from 'react';
// common
import { toastify } from '../../../utils/common';
// api
import { Book, gotAladinBook } from '../../../api/store/preparation';
// components
import AladinOrderListitem from './AladinOrderListitem';
// styles
import { Button } from 'reactstrap';

interface Props {
  bookList: Book[];
  getBookPreparationList: any;
}

export default function AladinOrderList({
  bookList,
  getBookPreparationList,
}: Props) {
  const customBookList = bookList
    .filter((b) => b.bookPreparationStatus === 104)
    .sort((a, b) => (a.bookName > b.bookName ? 1 : -1));
  const [isTotalChecked, setIsTotalChecked] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState<Book[]>([]);

  function toggleTotalCheck() {
    if (isTotalChecked) {
      setSelectedBooks([]);
    } else {
      setSelectedBooks(customBookList);
    }
    setIsTotalChecked((prev) => !prev);
  }

  async function handleSubmit() {
    if (selectedBooks.length === 0) {
      toastify('error', '선택한 책이 없습니다. 확인해주세요.');
      return;
    }
    const orderBookIdList = selectedBooks.map((book) => book.orderBookId);
    await gotAladinBook({ orderBookIdList });
    setSelectedBooks([]);
    await getBookPreparationList();
    try {
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <div
              className="row"
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h5>알라딘 주문 예정 리스트</h5>
              <div className="row btn-showcase">
                <Button outline color="secondary" onClick={handleSubmit}>
                  주문완료
                </Button>
              </div>
            </div>
          </div>
          <div className="table-responsive-xl">
            <table className="table table-hover" style={{ minWidth: 1300 }}>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="checkbox_animated"
                        type="checkbox"
                        checked={isTotalChecked}
                        onChange={toggleTotalCheck}
                        style={{ marginTop: -4 }}
                      />
                      <div>전체 선택</div>
                    </div>
                  </th>
                  <th style={{ width: '10%' }}>주문번호</th>
                  <th style={{ width: '20%' }}>주문 책 번호</th>
                  <th style={{ width: '15%' }}>주문자명</th>
                  <th style={{ width: '30%' }}>책 제목</th>
                  <th style={{ width: '15%' }}>ISBN</th>
                </tr>
              </thead>
              <tbody>
                {customBookList.length > 0
                  ? customBookList.map((bookInfo) => (
                      <AladinOrderListitem
                        key={bookInfo.orderBookId}
                        setSelectedBooks={setSelectedBooks}
                        bookInfo={bookInfo}
                        isTotalChecked={isTotalChecked}
                      />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
