import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { FORM_HEIGHT, FORM_WIDTH } from './selectFormSize';

interface Props {
  data: any;
  selectType: any;
  onChange: any;
  width?: number;
  defaultValue: any;
}

const SelectOption: FunctionComponent<Props> = ({
  data,
  selectType,
  onChange,
  width = FORM_WIDTH,
  defaultValue,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: 'solid 1px #808080',
      width,
    }),
  };

  return (
    <SelectStyle width={width}>
      <Select
        defaultValue={defaultValue}
        value={selectType}
        onChange={onChange}
        options={data}
        styles={customStyles}
      />
    </SelectStyle>
  );
};

const SelectStyle = styled.div`
  width: ${(props) => props.width}px;
  height: ${FORM_HEIGHT}px;
  border-radius: 5px;
`;

export default SelectOption;
