import ModalComponent from 'components/common/ModalComponent';
import React, { FunctionComponent } from 'react';
import TrafficTable from '../aCommons/TrafficTable';
import styled, { css } from 'styled-components';
import { IoCloseSharp } from 'react-icons/io5';
import { Scrollbars } from 'react-custom-scrollbars';

interface Props {
  title: string;
  totalCount: number;
  dailyData: any;
  isVisible: boolean;
  modalHandle: () => void;
}

const ChardData: FunctionComponent<Props> = ({
  title,
  totalCount,
  dailyData,
  isVisible,
  modalHandle,
}) => {
  const columns = ['일자', 'count'];

  const data = dailyData.sort(
    (a, b) =>
      new Date(a.registDate).getTime() - new Date(b.registDate).getTime(),
  );

  return (
    <>
      <ModalComponent
        visible={isVisible}
        width={500}
        height={900}
        backgroundColor={'white'}
      >
        <Table>
          <thead>
            <ColumnTableRow>
              {columns.map((column, index) => (
                <ColumnTableHeader key={column} index={index}>
                  {column}
                </ColumnTableHeader>
              ))}
            </ColumnTableRow>
          </thead>
          <tbody>
            {data.map(({ registDate, count }, index) => (
              <BodyTableRow key={index}>
                <BodyTableData type={'date'}>{registDate}</BodyTableData>
                <BodyTableData>{!count ? 0 : count}</BodyTableData>
              </BodyTableRow>
            ))}
          </tbody>
        </Table>
        <CloseModal onClick={() => modalHandle()}>
          <IoCloseSharp color={'black'} size={30} />
        </CloseModal>
      </ModalComponent>
    </>
  );
};

const CloseModal = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;

const Table = styled.table`
  display: flex;
  width: 500px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
  border-collapse: collapse;
  padding: 50px 0 50px 0;
`;

const ColumnTableRow = styled.tr`
  display: flex;
  flex-direction: row;
`;

interface ColumnTableHeaderProps {
  index: number;
}
const ColumnTableHeader = styled.th<ColumnTableHeaderProps>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.index === 0 ? 100 : 70)}px;
  border-bottom: 1px solid #444444;
  border-top: 1px solid #444444;
  border-right: 1px solid #444444;
  ${(props) =>
    props.index === 0 &&
    css`
      border-left: 1px solid #444444;
    `}
`;

const BodyTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #444444;
`;

interface BodyTableDataProps {
  type?: string;
}

const BodyTableData = styled.td<BodyTableDataProps>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.type === 'date' ? 100 : 70)}px;
  ${(props) =>
    props.type === 'date' &&
    css`
      border-left: 1px solid #444444;
    `}
  border-right: 1px solid #444444;
`;

export default ChardData;
