// apis
import useAPI from '../useAPI';
// types
import { Stock, StockHistory } from '../types';

export interface GetBookStockListRes {
  locationList: string[]; // 위치 리스트
  stockList: Stock[]; // 재고 책 리스트
  lastPage: number;
}
export interface GetBookStockHistoryListRes {
  inoutHistory: StockHistory[];
  lastPage: number;
}

export default function useStockAPI() {
  const { getHandler, putHandler, postHandler } = useAPI();

  async function getBookStockListAPI(params: {
    search: string;
    orderingOption: string; // 'location,name' or 'name,location' 둘중 하나의 값
    page: number;
    stockNumOption: number;
  }): Promise<GetBookStockListRes> {
    try {
      const res: any = await getHandler('/admin/store/book-stock', params);
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function updateStockQuantityAPI(params: {
    bookId: number; // 책ID
    locationCode: string; // 책장 위치
    changeQuantity: number; // 변경 수량
    bookCondition: number; // 책 상태
  }): Promise<{}> {
    try {
      const res: any = await putHandler(
        '/admin/store/book-stock/quantity',
        params,
      );
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function updateStockLocationAPI(params: {
    bookId: number; // 책ID
    locationCodeBefore: string; // 변경 이전 위치
    locationCodeAfter: string; // 변경 이후 위치
    bookCondition: number; // 책 상태
  }): Promise<{}> {
    try {
      const res: any = await putHandler(
        '/admin/store/book-stock/location',
        params,
      );
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function getBookStockHistoryAPI(params: {
    page: number;
  }): Promise<GetBookStockHistoryListRes> {
    try {
      const res: any = await getHandler(
        '/admin/store/book-stock/history',
        params,
      );
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  async function createBookStockAPI(params: {
    bookId: number | null; // 책ID
    bookCondition: number; // 책 상태
    locationCode: string; // 위치
    stockQuantity: number; //책 상태
  }): Promise<{}> {
    try {
      const res: any = await postHandler(
        '/admin/store/create/book-stock',
        params,
      );
      return res?.data?.result;
    } catch (error) {
      throw error;
    }
  }

  return {
    getBookStockListAPI,
    updateStockQuantityAPI,
    updateStockLocationAPI,
    getBookStockHistoryAPI,
    createBookStockAPI,
  };
}
