import React, { Fragment } from 'react';
// common
import Breadcrumb from '../../common/breadcrumb';
// components
import StockList from './StockList';

export default function Stock() {
  return (
    <Fragment>
      <Breadcrumb title="재고 관리" parent="찾아가는 서점" />
      <div className="container-fluid">
        <StockList />
      </div>
    </Fragment>
  );
}
