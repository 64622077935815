import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Breadcrumb from 'components/common/breadcrumb';
// apis
import useUserAPI from 'api/operation/useUserAPI';
// styles
import { Flex, Table } from 'styles/index';
import Pagination from '@material-ui/lab/Pagination';
// types
import { User } from 'api/types';

const USER_LENGTH = 10;
interface Props {
  selectedUser: User | null;
  setSelectedUser: any;
}
export default function AllUsers({ selectedUser, setSelectedUser }: Props) {
  const columns = useMemo(
    () => [
      {
        Header: '기본 정보',
        columns: [
          {
            Header: 'ID',
            accessor: 'userId',
          },
          {
            Header: '주문자명',
            accessor: 'ordererName',
          },
          {
            Header: '가입일',
            accessor: 'joinDate',
          },
          {
            Header: '최근접속일',
            accessor: 'lastConnectionDate',
          },
          {
            Header: '접속횟수',
            accessor: 'connectionCount',
          },
          {
            Header: '탈퇴일',
            accessor: 'withdrawDate',
          },
        ],
      },

      {
        Header: '등록수',
        columns: [
          {
            Header: '서재',
            accessor: 'bookshelfCount',
          },
          {
            Header: '카트',
            accessor: 'cartCount',
          },
          {
            Header: '주문',
            accessor: 'orderCount',
          },
          {
            Header: '주문취소제외',
            accessor: 'orderCountNotCanceled',
          },
        ],
      },
    ],
    [],
  );

  const { getUsersAPI } = useUserAPI();

  const [page, setPage] = useState(1);
  const [users, setUsers] = useState<User[]>([]);
  const [totalCnt, setTotalCnt] = useState(0);

  const [nickName, setNickName] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    handleGetUsers();
  }

  async function handleGetUsers() {
    try {
      const params = nickName ? { page, search: nickName } : { page };
      const res = await getUsersAPI(params);
      setTotalCnt(res.totalCnt);
      setUsers(res.userList);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetUsers();
  }, [page]);

  return (
    <>
      <Flex style={{ alignItems: 'center' }}>
        <Breadcrumb title="유져 리스트" parent="운영" />
        <form onSubmit={handleSubmit}>
          <input
            className="form-control"
            type="text"
            placeholder="닉네임으로 검색"
            onChange={(e) => setNickName(e.target.value)}
            style={{ width: 400, height: 40 }}
          />
        </form>
      </Flex>
      <div style={{ flex: 1, marginBottom: 40 }}>
        <Table
          fullScreen
          columns={columns}
          data={users}
          onPress={setSelectedUser}
          activeValue={selectedUser?.userId}
          type="allUsers"
          style={{ marginBottom: 20 }}
        />
        <Flex style={{ justifyContent: 'center' }}>
          <StyledPagination
            count={Math.ceil(totalCnt / USER_LENGTH)}
            onChange={(_, page) => setPage(page)}
          />
        </Flex>
      </div>
    </>
  );
}

const StyledPagination = styled(Pagination)`
  button {
    outline: none;
  }
`;
